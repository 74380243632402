// redux/apis/endpoints/addIncomingInvoice.js
export const addIncomingInvoiceEndpoint = (builder) => ({
    addIncomingInvoice: builder.mutation({
        query: (invoice) => ({
            url: 'api/incomingInvoices/add-incomingInvoice',
            method: 'POST',
            body: invoice,
        }),
        invalidatesTags: [{ type: 'IncomingInvoices', id: 'LIST' }],
    }),
});