import React, { Fragment, useEffect, useState } from 'react';
import { LogIn, MoreHorizontal, User } from 'react-feather';
import { Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import { H6, Image, LI, UL } from '../../AbstractElements';
import UserImg from '../../assets/images/avtar/emoji/9.png';
import MaxMiniSize from './MaxMiniSize';
import Message from './Message';
import MoonLight from './MoonLight';
import Notification from './Notification';
import Language from './Langauge'; 

import {useDispatch, useSelector} from "react-redux";
import {signoutSuccess} from "../../redux/user/userSlice";

const HeaderContain = () => {
const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => state.user);
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('');
  useEffect(() => {
    setProfile(localStorage.getItem('profileURL') || UserImg);
    setName(localStorage.getItem('Name'));

  }, []);
  const [open, setOpen] = useState(false);
  const onAdd = () => {
    setOpen(!open);
  };

  const logout = () => {
    dispatch(signoutSuccess());
    localStorage.clear();
}
  return (
    <Fragment>
      <div className="nav-right col pull-right right-menu">
        <UL attrUL={{ className: `simple-list d-flex flex-row nav-menus ${open ? 'open' : ''}` }}>
          <LI attrLI={{ className: 'onhover-dropdown' }}>
            <a className="txt-dark" href="#javascript"></a>
            <Language />
          </LI>
          {/*<ItemCart />*/}
          {/*<Bookmark />*/}
          <Notification />
          <Message />
          <MoonLight />
          <MaxMiniSize />
          <LI attrLI={{ className: 'onhover-dropdown pe-0' }} >
            <Media className="profile-media align-items-center">
              <Image attrImage={{
                className: 'rounded-circle', src: `${currentUser.data.username  ? currentUser.data.profilePicture : profile}`, alt: '',
              }}
              />
            </Media>
            <UL
              attrUL={{
                className: 'simple-list profile-dropdown onhover-show-div',
              }}
            >
              <LI>
                <User />
                <Link to={`${process.env.PUBLIC_URL}/users/userprofile`}><span className={`p-2 rounded bg-danger`}>{currentUser.data.username} </span></Link>
              </LI>
              {/*<LI>*/}
              {/*  <Mail />*/}
              {/*  <Link to={`${process.env.PUBLIC_URL}/email/mailbox`}><span>Inbox</span></Link>*/}
              {/*</LI>*/}
              {/*<LI>*/}
              {/*  <FileText />*/}
              {/*  <Link to={`${process.env.PUBLIC_URL}/todo`}><span>Taskboard</span></Link>*/}
              {/*</LI>*/}
              {/*<LI>*/}
              {/*  <Settings />*/}
              {/*  <Link to={`${process.env.PUBLIC_URL}/users/useredit`}><span>Settings</span></Link>*/}
              {/*</LI>*/}
              <LI attrLI={{ onClick: logout }}>
                <Link to={`${process.env.PUBLIC_URL}/login`} className="btn btn-light w-100">
                  <LogIn  /> Logout
                </Link>
              </LI>
            </UL>
          </LI>
        </UL>
      </div >
      <div className="d-lg-none col mobile-toggle pull-right" onClick={() => onAdd()}>
        <i> <MoreHorizontal /></i>
      </div>
    </Fragment >
  );
};
export default HeaderContain;