import React, {Fragment, useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { LI, UL } from '../../AbstractElements';
import CustomizerContext from "../../_helper/customizer";
import ConfigDB from "../../Config/Theme-Config";

const Language = () => {
    const { i18n } = useTranslation();
    const [selected, setSelected] = useState('ae');

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
        setSelected(lng);
    };
    const { addLayout } = useContext(CustomizerContext);
    const localStorageLayout = localStorage.getItem('layout_type') || ConfigDB.data.settings.layout_type;
    const [layout_type, setLayout_type] = useState(localStorageLayout);

    useEffect(() => {
        localStorageLayout && document.body.classList.add(localStorageLayout);
        ConfigDB.data.settings.layout_type = localStorageLayout;
        if (localStorageLayout === 'box-layout') {
            document.documentElement.dir = 'ltr';
        } else {
            document.documentElement.dir = localStorageLayout;
        }
    }, [localStorageLayout]);
    const handleLayout = (layout) => {
        addLayout(layout);
        setLayout_type(layout);
        if (layout === 'rtl') {
            document.body.classList.add('rtl');
            document.body.classList.remove('ltr');
            document.body.classList.remove('box-layout');
            document.documentElement.dir = 'rtl';
        } else if (layout === 'ltr') {
            document.body.classList.add('ltr');
            document.body.classList.remove('rtl');
            document.body.classList.remove('box-layout');
            document.documentElement.dir = 'ltr';
        } else if (layout === 'box-layout') {
            document.body.classList.remove('ltr');
            document.body.classList.remove('rtl');
            document.body.classList.add('box-layout');
            document.documentElement.dir = 'ltr';
        }
    };


    return (
        <Fragment>
            <div className={`d-flex flex-row `}>
                <UL attrUL={{ className: 'simple-list d-flex flex-row gap-2' }}>
                    <LI attrLI={{ onClick: () => changeLanguage('en') }}>
                        <LI attrLI={{
                            className: `${layout_type === 'ltr' ? 'active' : ''}`, onClick: () => handleLayout('ltr')
                        }}>
                                <a href="#javascript" data-lng="en">
                                    <i className="flag-icon flag-icon-is "></i>
                                </a>
                        </LI>

                    </LI>
                    <LI attrLI={{onClick: () => changeLanguage('ae') }}>
                        <LI
                            attrLI={{className: `${layout_type === 'rtl' ? 'active' : ''}`, onClick: () => handleLayout('rtl')}}
                        >
                            <a href="#javascript" data-lng="ae">
                                <i className="flag-icon flag-icon-sa"></i> {''}
                            </a>
                        </LI>
                    </LI>
                </UL>
            </div>
        </Fragment>
    );
};
export default Language;


