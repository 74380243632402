import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import { useAddGalleryCategoryMutation } from '../../redux/apis/apiSlice';
import 'bootstrap/dist/css/bootstrap.min.css';
import ImageUploadComponent from "./addPhotosToGallery";
import Gallery from "./Gallery";

const MyForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        image: null,
        description: ''
    });

    const [addGalleryCategory, { isLoading, isSuccess, isError }] = useAddGalleryCategoryMutation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setFormData({ ...formData, image: e.target.files[0] });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // تحقق من وجود الصورة
        if (!formData.image) {
            alert("يجب عليك رفع صورة.");
            return;
        }

        // إنشاء FormData لإرسال الصورة مع البيانات
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('image', formData.image);
        formDataToSend.append('description', formData.description);

        try {
            await addGalleryCategory(formDataToSend).unwrap();
            console.log('تمت إضافة الفئة بنجاح');
            setFormData({ name: '', image: null, description: '' });
        } catch (error) {
            console.error('خطأ في إضافة الفئة:', error);
        }
    };

    return (
        <div className="">
            <Card className="p-3" style={{ width: '300px' }}>
                <CardBody>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="name">الاسم</Label>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="image">رفع الصورة</Label>
                            <Input
                                type="file"
                                name="image"
                                id="image"
                                onChange={handleFileChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="description">الوصف</Label>
                            <Input
                                type="textarea"
                                name="description"
                                id="description"
                                value={formData.description}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        <Button color="primary" type="submit" block disabled={isLoading}>
                            {isLoading ? 'جاري الإضافة...' : 'إضافة'}
                        </Button>
                        {isSuccess && <div className="mt-2 text-success">تمت إضافة الفئة بنجاح!</div>}
                        {isError && <div className="mt-2 text-danger">حدث خطأ في إضافة الفئة.</div>}
                    </Form>
                </CardBody>
            </Card>
            <ImageUploadComponent/>
            <Gallery/>
        </div>
    );
};

export default MyForm;