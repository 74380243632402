import React, { Fragment, useState } from 'react';
import { Row, Col, CardBody, Label, CardFooter, Alert } from 'reactstrap';
import { Btn } from "../../AbstractElements";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import {useAddCustomerMutation, useGetEmployeesQuery} from "../../redux/apis/apiSlice";
import { clearAddress } from "../../redux/address/addressSlice";
import {toast} from "react-toastify"; // تأكد من مسار الاستيراد الصحيح

const AddCustomerForm = () => {
    const dispatch = useDispatch();
    const [formKey, setFormKey] = useState(0);

    // جلب البيانات من Redux
    const userId = useSelector((state) => state.user.currentUser.data._id); // تأكد من المسار الصحيح لـ userId في حالتك
    const address = useSelector((state) => state.address.address);
    const [addCustomer,
        { isLoading:isLoadingAddCustomer,
            isSuccess,
            isError:isErrorAddCustomer,
            error }] = useAddCustomerMutation();

    // جلب قائمة الموظفين باستخدام RTK Query
    const { data: employeesData, isLoading, isError } = useGetEmployeesQuery();
console.log(address)
    const initialValues = {
        customerType: '',
        customerName: '',
        customerReference: '',
        email: '',
        phone: '',
        taxNumber: '',
        commercialNumber: '',
        representative: '',
        workedBy: '',
        status: '',
        notes: '',
        additionalInfo: '',

    };

    const validationSchema = Yup.object().shape({
        customerType: Yup.string().required('نوع العميل مطلوب'),
        customerName: Yup.string().required('اسم العميل مطلوب'),
        customerReference: Yup.string(), // اختياري
        email: Yup.string()
            .email('صيغة الايميل غير صحيحة')
            .test(
                'email-required-if-company-or-organization',
                'الايميل مطلوب للشركات والمؤسسات',
                function(value) {
                    const { customerType } = this.parent;
                    if (['شركة', 'مؤسسة'].includes(customerType)) {
                        return value && value.trim() !== '';
                    }
                    return true;
                }
            ),
        phone: Yup.string()
            .test(
                'phone-required-if-company-or-organization',
                'رقم الجوال مطلوب للشركات والمؤسسات',
                function(value) {
                    const { customerType } = this.parent;
                    if (['شركة', 'مؤسسة'].includes(customerType)) {
                        return value && value.trim() !== '';
                    }
                    return true;
                }
            ),
        taxNumber: Yup.string()
            .test(
                'taxNumber-required-if-company-or-organization',
                'الرقم الضريبي مطلوب للشركات والمؤسسات',
                function(value) {
                    const { customerType } = this.parent;
                    if (['شركة', 'مؤسسة'].includes(customerType)) {
                        return value && value.trim() !== '';
                    }
                    return true;
                }
            ),
        commercialNumber: Yup.string()
            .test(
                'commercialNumber-required-if-company-or-organization',
                'رقم السجل التجاري مطلوب للشركات والمؤسسات',
                function(value) {
                    const { customerType } = this.parent;
                    if (['شركة', 'مؤسسة'].includes(customerType)) {
                        return value && value.trim() !== '';
                    }
                    return true;
                }
            ),
        representative: Yup.string()
            .test(
                'representative-required',
                'المندوب مطلوب',
                function(value) {
                    const { customerType } = this.parent;
                    if (['شركة', 'مؤسسة'].includes(customerType)) {
                        return value && value.trim() !== '';
                    }
                    return true;
                }
            ),
        workedBy: Yup.string()
            .test(
                'workedBy-required',
                'المندوب مطلوب',
                function(value) {
                    const { customerType } = this.parent;
                    if (['فرد', 'عميل ذات معلومات'].includes(customerType)) {
                        return value && value.trim() !== '';
                    }
                    return true;
                }
            ),
        status: Yup.string(), // اختياري
        notes: Yup.string(), // اختياري
        additionalInfo: Yup.string(), // اختياري
    });

    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        console.log("Form submitted", values); // للتحقق من تنفيذ الدالة
        // دمج البيانات من Redux مع بيانات النموذج
        const payload = {
            ...values,
            userId,
            address
        };

        try {
            await addCustomer(payload).unwrap();

            dispatch(clearAddress());

            // تحديث مفتاح النموذج لإعادة تهيئته
            setFormKey(formKey + 1);

            // إعادة تعيين النموذج بعد الحفظ الناجح
            resetForm();
            toast.success("تم اضافة العميل بنجاح");
            // إضافة تنبيه أو أي إجراء آخر هنا
            alert('تم إضافة العميل بنجاح!');
        } catch (error) {
            // التعامل مع الأخطاء إذا حدثت
            if (error && error.data && Array.isArray(error.data.errors)) {
                // إذا كانت الأخطاء تأتي كمصفوفة، نقوم بعرض كل رسالة خطأ على حدة
                error.data.errors.forEach((err) => {
                    toast.error(err.msg);
                });
            } else {
                toast.error('حدث خطأ غير متوقع. يرجى المحاولة لاحقًا.');
            }
            console.error('خطأ في إضافة العميل:', error);

        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Fragment>
            <Formik
                key={formKey}
                initialValues={initialValues}
                // validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize // يسمح بإعادة تهيئة القيم الأولية عند تغيرها
            >
                {({ values, isSubmitting }) => (
                    <Form className="form theme-form base-input">
                        <CardBody>
                            {/* نوع العميل */}
                            <Row>
                                <Col>
                                    <div className='mb-3'>
                                        <Label>نوع العميل</Label>
                                        <Field as="select" name="customerType" className="form-control form-select">
                                            <option value="">اختر نوع العميل</option>
                                            <option value="شركة">شركة</option>
                                            <option value="مؤسسة">مؤسسة</option>
                                            <option value="فرد">فرد</option>
                                            <option value="عميل ذات معلومات">عميل ذات معلومات</option>
                                        </Field>
                                        <ErrorMessage name="customerType" component="div" className="text-danger" />
                                    </div>
                                </Col>
                            </Row>

                            {/* اسم العميل */}
                            <Row>
                                <Col>
                                    <div className='mb-3'>
                                        <Label>اسم العميل</Label>
                                        <Field className="form-control" name="customerName" placeholder="اسم العميل / الشركة / المؤسسة" />
                                        <ErrorMessage name="customerName" component="div" className="text-danger" />
                                    </div>
                                </Col>
                            </Row>

                            {/* مرجع العميل */}
                            <Row>
                                <Col>
                                    <div className='mb-3'>
                                        <Label>مرجع العميل</Label>
                                        <Field className="form-control" name="customerReference" placeholder="مرجع العميل" />
                                        <ErrorMessage name="customerReference" component="div" className="text-danger" />
                                    </div>
                                </Col>
                            </Row>

                            {/* الايميل */}
                            { (['شركة', 'مؤسسة', 'عميل ذات معلومات'].includes(values.customerType)) && (
                                <Row>
                                    <Col>
                                        <div className='mb-3'>
                                            <Label>الايميل</Label>
                                            <Field className="form-control" name="email" type="email" placeholder="name@example.com" />
                                            <ErrorMessage name="email" component="div" className="text-danger" />
                                        </div>
                                    </Col>
                                </Row>
                            )}

                            {/* الجوال */}
                            { (['شركة', 'مؤسسة', 'عميل ذات معلومات'].includes(values.customerType)) && (
                                <Row>
                                    <Col>
                                        <div className='mb-3'>
                                            <Label>الجوال</Label>
                                            <Field className="form-control" name="phone" placeholder="رقم الجوال" />
                                            <ErrorMessage name="phone" component="div" className="text-danger" />
                                        </div>
                                    </Col>
                                </Row>
                            )}

                            {/* الرقم الضريبي */}
                            { (['شركة', 'مؤسسة', 'عميل ذات معلومات'].includes(values.customerType)) && (
                                <Row>
                                    <Col>
                                        <div className='mb-3'>
                                            <Label>الرقم الضريبي</Label>
                                            <Field className="form-control" name="taxNumber" placeholder="الرقم الضريبي" />
                                            <ErrorMessage name="taxNumber" component="div" className="text-danger" />
                                        </div>
                                    </Col>
                                </Row>
                            )}

                            {/* رقم السجل التجاري */}
                            { (['شركة', 'مؤسسة', 'عميل ذات معلومات'].includes(values.customerType)) && (
                                <Row>
                                    <Col>
                                        <div className='mb-3'>
                                            <Label>رقم السجل التجاري</Label>
                                            <Field className="form-control" name="commercialNumber" placeholder="رقم السجل التجاري" />
                                            <ErrorMessage name="commercialNumber" component="div" className="text-danger" />
                                        </div>
                                    </Col>
                                </Row>
                            )}

                            {/* المندوب للشركة/المؤسسة */}
                            { (['شركة', 'مؤسسة'].includes(values.customerType)) && (
                                <Row>
                                    <Col>
                                        <div className='mb-3'>
                                            <Label>المندوب للشركة/المؤسسة</Label>
                                            <Field as="select" name="representative" className="form-control form-select">
                                                <option value="">اختر المندوب</option>
                                                {isLoading && <option>جاري التحميل...</option>}
                                                {isError && <option>خطأ في جلب الموظفين</option>}
                                                {employeesData?.data?.map(emp => (
                                                    <option key={emp._id} value={emp._id}>{emp.name}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="representative" component="div" className="text-danger" />
                                        </div>
                                    </Col>
                                </Row>
                            )}

                            {/* المندوب (فرد / عميل ذات معلومات) */}
                            { (['فرد', 'عميل ذات معلومات'].includes(values.customerType)) && (
                                <Row>
                                    <Col>
                                        <div className='mb-3'>
                                            <Label>المندوب</Label>
                                            <Field as="select" name="representative" className="form-control form-select">
                                                <option value="">اختر المندوب</option>
                                                {isLoading && <option>جاري التحميل...</option>}
                                                {isError && <option>خطأ في جلب الموظفين</option>}
                                                {employeesData?.data?.map(emp => (
                                                    <option key={emp._id} value={emp._id}>{emp.name}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="representative" component="div" className="text-danger" />
                                        </div>
                                    </Col>
                                </Row>
                            )}

                            {/* الحالة */}
                            <Row>
                                <Col>
                                    <div className='mb-3'>
                                        <Label>حالة العميل </Label>
                                        <Field as="select" name="status" className="form-control form-select">
                                            <option value="">اختر الحالة</option>
                                            <option>{"نشط"} </option>
                                            <option>{"غير نشك"} </option>
                                        </Field>
                                        <ErrorMessage name="status" component="div" className="text-danger" />
                                    </div>
                                </Col>
                            </Row>

                            {/* الملاحظات */}
                            <Row>
                                <Col>
                                    <div className='mb-3'>
                                        <Label>ملاحظات</Label>
                                        <Field as="textarea" name="notes" className="form-control" rows="3" placeholder="ملاحظات" />
                                        <ErrorMessage name="notes" component="div" className="text-danger" />
                                    </div>
                                </Col>
                            </Row>

                            {/* عرض العنوان الوطني */}
                            <Row>
                                <Col>
                                    <div className='mb-3'>
                                        <Label>العنوان الوطني:</Label>
                                        {
                                            address.City ? (
                                                <Alert color="success">
                                                    {`تم اضافة العنوان الوطني: ${address.City} - ${address.BuildingNumber} - ${address.StreetName} - ${address.Neighborhood}`}
                                                </Alert>
                                            ) : (
                                                <Alert color="danger">
                                                    اضف العنوان الوطني من القائمة الجانبية
                                                </Alert>
                                            )
                                        }
                                    </div>
                                </Col>
                            </Row>

                            {/* إظهار كافة الحقول عند اختيار "عميل ذات معلومات" */}
                            { (values.customerType === 'عميل ذات معلومات') && (
                                <>
                                    <Row>
                                        <Col>
                                            <div className='mb-3'>
                                                <Label>معلومات إضافية</Label>
                                                <Field as="textarea" name="additionalInfo" className="form-control" rows="3" placeholder="معلومات إضافية" />
                                                <ErrorMessage name="additionalInfo" component="div" className="text-danger" />
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* يمكنك إضافة المزيد من الحقول الإضافية هنا إذا لزم الأمر */}
                                </>
                            )}
                        </CardBody>

                        <CardFooter className='pt-0'>
                            <Col sm="9">
                                {/* إذا كان `Btn` يسبب مشاكل، استبدله مؤقتًا بزر عادي */}
                                <Btn attrBtn={{ color: 'primary', type: 'submit', className: 'me-2', disabled: isSubmitting }}>
                                    {isSubmitting ? 'جارٍ الإضافة...' : 'إضافة'}
                                </Btn>
                                {/* بديل مؤقت:
                                <button
                                    type="submit"
                                    className="btn btn-primary me-2"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'جارٍ الإضافة...' : 'إضافة'}
                                </button>
                                */}
                            </Col>
                        </CardFooter>
                    </Form>
                )}
            </Formik>
        </Fragment>
    );
};

export default AddCustomerForm;