// الكود الكامل للكومبوننت بعد التعديلات السابقة يعمل بشكل صحيح.
// تأكد من أن جميع الحقول يتم تعبئتها بشكل صحيح وأن البيانات المرسلة تتضمن جميع الحقول المطلوبة.

import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Btn, H6 } from "../../../../AbstractElements";
import {
  CardBody,
  CardFooter,
  FormGroup,
  InputGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import {
  useAddPaymentMutation,
  useGetEmployeesQuery,
  useGetCustomersQuery,
  useAddCustomerMutation,
  useGetPaymentsQuery,
} from "../../../../redux/apis/apiSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";

const MegaForms = () => {
  const { currentUser } = useSelector((state) => state.user);
  const { _id } = useParams(); // الحصول على المتغير من الرابط

  // حالة التحكم في ظهور نافذة الـ Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  // حالة البحث
  const [searchTerm, setSearchTerm] = useState("");

  // استخدام الـ mutations
  const [
    addPayment,
    {
      isLoading: isPaymentLoading,
      isSuccess: isPaymentSuccess,
      isError: isPaymentError,
      error: paymentError,
    },
  ] = useAddPaymentMutation();

  const [
    addCustomer,
    {
      isLoading: isAddCustomerLoading,
      isSuccess: isAddCustomerSuccess,
      isError: isAddCustomerError,
      error: addCustomerError,
    },
  ] = useAddCustomerMutation();

  // استخدام الـ queries
  const {
    data: employeesData,
    error: employeesError,
    isLoading: employeesLoading,
  } = useGetEmployeesQuery();

  // تعديل useGetCustomersQuery ليشمل مصطلح البحث
  const {
    data: customersData,
    error: customersError,
    isLoading: customersLoading,
  } = useGetCustomersQuery(
      { customerName: searchTerm, page: 1 }, // تمرير مصطلح البحث وتهيئة الصفحة الأولى
      { skip: false } // لا تخطي الاستعلام حتى عند عدم وجود بحث
  );

  // payments data from useGetPaymentsQuery
  const {
    data: paymentsData,
    error: paymentsError,
    isLoading: paymentsLoading,
  } = useGetPaymentsQuery();

  // إعداد Formik مع Yup
  const formik = useFormik({
    initialValues: {
      employeeId: "",
      amount: "",
      paymentDate: new Date(),
      paymentMethod: "",
      status: "",
      customerId: "",
      customerName: "",
    },
    validationSchema: Yup.object({
      employeeId: Yup.string().required("يرجى اختيار المندوب"),
      amount: Yup.string()
          .matches(/^[0-9]+$/, "يجب أن تحتوي قيمة الدفعة على أرقام إنجليزية فقط")
          .test(
              "is-positive",
              "يجب أن تكون قيمة الدفعة موجبة",
              (value) => Number(value) > 0
          )
          .required("يرجى إدخال قيمة الدفعة")
          .test("is-integer", "يجب أن تكون قيمة الدفعة عددًا صحيحًا", (value) =>
              Number.isInteger(Number(value))
          ),
      paymentDate: Yup.date().required("يرجى اختيار تاريخ الدفع"),
      paymentMethod: Yup.string().required("يرجى اختيار طريقة الدفع"),
      status: Yup.string().required("يرجى اختيار حالة الدفعة"),
      // التحقق من أن أحد الحقلين مملوء
      customerId: Yup.string(),
      customerName: Yup.string(),
    }).test(
        "customer-fields",
        "يرجى اختيار العميل أو إدخال اسم جديد",
        function (values) {
          return values.customerId || values.customerName;
        }
    ),
    onSubmit: async (values, { resetForm }) => {
      console.log("Submitting form with values:", values);
      try {
        // إرسال بيانات الدفع مباشرة كجسم الطلب
        await addPayment({
          userId: currentUser.data._id,
          employeeId: values.employeeId,
          amount: Number(values.amount), // تحويل إلى عدد
          paymentDate: values.paymentDate,
          paymentMethod: values.paymentMethod,
          status: values.status,
          customerId: values.customerId,
          customerName: values.customerName,
        }).unwrap();

        // إعادة تعيين النموذج بعد الإرسال الناجح
        resetForm();
        setIsModalOpen(true);
      } catch (err) {
        toast.error(err?.data?.errors[0].msg);
        console.error("فشل في إضافة الدفع:", err);
      }
    },
  });

  // إدارة إضافة عميل جديد
  const handleAddCustomer = async () => {
    const { customerName, employeeId } = formik.values;
    if (!customerName) {
      alert("يرجى إدخال اسم العميل");
      return;
    }
    if (!employeeId) {
      alert("يرجى اختيار المندوب قبل إضافة العميل");
      return;
    }

    try {
      await addCustomer({
        userId: currentUser.data._id,
        representative: employeeId,
        customerName: customerName,
      }).unwrap();

      // فتح نافذة الـ Modal لإظهار نجاح الإضافة
      setIsModalOpen(true);

      // إعادة تعيين حقل اسم العميل في النموذج
      formik.setFieldValue("customerName", "");
      // لا حاجة لاستدعاء refetchCustomers هنا إذا كنت تستخدم invalidatesTags بشكل صحيح
    } catch (err) {
      console.log(err);
      toast.error(err?.data?.errors[0].msg);
      console.error("فشل في إضافة العميل:", err);
    }
  };

  // إنشاء دالة مع debounce لتحديث مصطلح البحث
  const debouncedSearch = useCallback(
      debounce((value) => {
        setSearchTerm(value);
      }, 500),
      []
  );

  // دالة تغيير البحث
  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  // تحديث الحالة بناءً على نجاح أو فشل إضافة العميل أو الحصول على البيانات
  useEffect(() => {
    if (isAddCustomerSuccess) {
      setIsModalOpen(true); // فتح النافذة عند نجاح إضافة العميل
    }
    if (employeesError) {
      console.error("Error fetching employees:", employeesError);
    }
    if (customersError) {
      console.error("Error fetching customers:", customersError);
    }
  }, [isAddCustomerSuccess, paymentsData, employeesError, customersError]);

  // إضافة useEffect لتعيين employeeId تلقائيًا إذا كان _id موجودًا
  useEffect(() => {
    const getIdEmployee = () => {
      if (_id && employeesData?.data) {
        const employee = employeesData.data.find((emp) => emp._id === _id);
        if (employee) {
          formik.setFieldValue("employeeId", employee._id);
          console.log("تم تعيين employeeId من الرابط:", employee._id);
        } else {
          console.warn(`لم يتم العثور على employeeId مع _id: ${_id}`);
        }
      }
    };

    getIdEmployee();
  }, [_id, employeesData]);

  // عرض حالة التحميل أو الأخطاء
  if (employeesLoading || customersLoading) return <div>جارٍ التحميل...</div>;
  if (employeesError || customersError)
    return <div>خطأ في تحميل بيانات الموظف والعميل.</div>;

  return (
      <Fragment>
        <CardBody className="pb-0">
          <form onSubmit={formik.handleSubmit} className="theme-form mega-form">
            <H6>
              {"أضف عميل من هنا ومن ثم يمكنك التعديل في قسم العملاء وإضافة المزيد من المعلومات"}
            </H6>
            {/* حقل إضافة عميل جديد أو اختيار عميل موجود مع حقل البحث */}
            <FormGroup className="d-flex align-items-center">
              <div className="flex-grow-1 me-2">
                <Input
                    id="customerName"
                    name="customerName"
                    type="text"
                    placeholder="اسم العميل"
                    value={formik.values.customerName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                        formik.touched.customerName &&
                        Boolean(formik.errors.customerName)
                    }
                />
                {/* إخفاء رسالة الخطأ لأن Yup يتحقق من الحقول مجتمعة */}
              </div>
              <span className="mx-2">أو</span>
              <div className="flex-grow-1 me-2">
                <Input
                    id="customerId"
                    name="customerId"
                    type="select"
                    value={formik.values.customerId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                        formik.touched.customerId && Boolean(formik.errors.customerId)
                    }
                >
                  <option value="">اختر...</option>
                  {customersData?.data.map((customer) => (
                      <option key={customer._id} value={customer._id}>
                        {customer.customerName}
                      </option>
                  )) || []}
                </Input>
                {/* إخفاء رسالة الخطأ لأن Yup يتحقق من الحقول مجتمعة */}
              </div>
              {/* حقل البحث */}
              <div className="flex-grow-1 ms-2">
                <Input
                    id="searchTerm"
                    name="searchTerm"
                    type="text"
                    placeholder="ابحث عن عميل..."
                    onChange={handleSearchChange}
                />
              </div>
            </FormGroup>
            {formik.errors["customer-fields"] && (
                <div className="text-danger mb-3">
                  {formik.errors["customer-fields"]}
                </div>
            )}

            {/* زر إضافة عميل جديد */}
            <FormGroup className="mb-3">
              <Button
                  color="success"
                  type="button"
                  onClick={handleAddCustomer}
                  disabled={isAddCustomerLoading}
              >
                {isAddCustomerLoading ? "جارٍ الإضافة..." : "إضافة عميل"}
              </Button>
              {isAddCustomerError && (
                  <p className="text-danger mt-2">
                    فشل في إضافة العميل:{" "}
                    {addCustomerError?.data?.message || "حاول مرة أخرى"}
                  </p>
              )}
            </FormGroup>

            {/* حقل اختيار الموظف */}
            <FormGroup>
              <Label className="col-form-label" htmlFor="employeeId">
                المندوب
              </Label>
              <Input
                  id="employeeId"
                  name="employeeId"
                  type="select"
                  value={formik.values.employeeId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                      formik.touched.employeeId && Boolean(formik.errors.employeeId)
                  }
                  required
              >
                <option value="">اختر...</option>
                {employeesData?.data?.map((employee) => (
                    <option key={employee._id} value={employee._id}>
                      {employee.name}
                    </option>
                ))}
              </Input>
              {formik.touched.employeeId && formik.errors.employeeId ? (
                  <div className="text-danger">{formik.errors.employeeId}</div>
              ) : null}
            </FormGroup>

            {/* حقل قيمة الدفعة */}
            <FormGroup className="flex">
              <Label className="col-form-label" htmlFor="amount">
                قيمة الدفعة
              </Label>
              <Input
                  id="amount"
                  name="amount"
                  type="number"
                  pattern="[0-9]*"
                  placeholder="قيمة الدفعة"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={formik.touched.amount && Boolean(formik.errors.amount)}
                  required
              />
              {formik.touched.amount && formik.errors.amount ? (
                  <div className="text-danger">{formik.errors.amount}</div>
              ) : null}
            </FormGroup>

            {/* حقل طريقة الدفع */}
            <FormGroup>
              <Label className="col-form-label" htmlFor="paymentMethod">
                طريقة الدفع
              </Label>
              <Input
                  id="paymentMethod"
                  name="paymentMethod"
                  type="select"
                  value={formik.values.paymentMethod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={
                      formik.touched.paymentMethod &&
                      Boolean(formik.errors.paymentMethod)
                  }
                  required
              >
                <option value="">اختر طريقة الدفع</option>
                <option value="كاش">كاش</option>
                <option value="تحويل">تحويل</option>
                <option value="شيك">شيك</option>
              </Input>
              {formik.touched.paymentMethod && formik.errors.paymentMethod ? (
                  <div className="text-danger">{formik.errors.paymentMethod}</div>
              ) : null}
            </FormGroup>

            {/* حقل حالة الدفعة */}
            <FormGroup>
              <Label className="col-form-label" htmlFor="status">
                حالة الدفعة
              </Label>
              <Input
                  id="status"
                  name="status"
                  type="select"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={formik.touched.status && Boolean(formik.errors.status)}
                  required
              >
                <option value="">اختر الحالة</option>
                <option value="قيد الانتظار">قيد الانتظار</option>
                <option value="مكتملة">مكتملة</option>
                <option value="فاشلة">فاشلة</option>
                {/* أضف حالات أخرى حسب الحاجة */}
              </Input>
              {formik.touched.status && formik.errors.status ? (
                  <div className="text-danger">{formik.errors.status}</div>
              ) : null}
            </FormGroup>

            {/* حقل اختيار التاريخ */}
            <FormGroup>
              <Label className="col-form-label" htmlFor="paymentDate">
                تاريخ الدفع
              </Label>
              <InputGroup
                  className="date"
                  id="dt-minimum"
                  data-target-input="nearest"
              >
                <DatePicker
                    id="paymentDate"
                    name="paymentDate"
                    selected={formik.values.paymentDate}
                    onChange={(date) => formik.setFieldValue("paymentDate", date)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control datetimepicker-input"
                />
                {formik.touched.paymentDate && formik.errors.paymentDate ? (
                    <div className="text-danger">{formik.errors.paymentDate}</div>
                ) : null}
              </InputGroup>
            </FormGroup>

            {/* يمكن إضافة حقول معلومات الشركة هنا إذا لزم الأمر */}

            <hr className="mt-4 mb-4" />
            {/* تذييل النموذج بأزرار الإرسال والإلغاء */}
            <CardFooter className="pt-0">
              <Button
                  color="primary"
                  className="me-2"
                  type="submit"
                  disabled={isPaymentLoading}
              >
                {isPaymentLoading ? "جارٍ الإرسال..." : "إضافة دفعة"}
              </Button>
            </CardFooter>

            {/* عرض رسائل الحالة لإرسال الدفع */}
            {isPaymentSuccess && (
                <p className="text-success mt-2">تم إضافة الدفع بنجاح!</p>
            )}
            {isPaymentError && (
                <p className="text-danger mt-2">
                  حدث خطأ في إضافة الدفع:{" "}
                  {paymentError?.data?.message || "حاول مرة أخرى"}
                </p>
            )}
          </form>
        </CardBody>

        {/* مكون الـ Modal لإظهار نجاح إضافة العميل */}
        <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
          <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>
            إضافة عميل جديد
          </ModalHeader>
          <ModalBody>
            {isAddCustomerSuccess && (
                <p className="text-success mt-2">
                  تم إضافة العميل بنجاح! إذا كنت تريد إضافة المزيد من المعلومات، قم
                  بتعديل العميل في قائمة العملاء.
                </p>
            )}

            {isAddCustomerError && (
                <p className="text-danger mt-2">
                  حدث خطأ في إضافة العميل:{" "}
                  {addCustomerError?.data?.message || "حاول مرة أخرى"}
                </p>
            )}

            {isPaymentSuccess && (
                <p className="text-success mt-2">
                  تم إضافة الدفعة بنجاح. اضف المزيد أو اذهب إلى صفحة العملاء.
                </p>
            )}
            <br />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => setIsModalOpen(false)}>
              موافق
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
  );
};

export default MegaForms;