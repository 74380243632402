// RTK service
export const cardsForWash = (builder) => ({
    getSinks: builder.query({
        query: ({ page = 1, limit = 10 }) => `api/card-wash?page=${page}&limit=${limit}`,
        providesTags: (result, error, arg) =>
            result
                ? [
                    ...result.data.map(({ _id }) => ({ type: 'Sink', id: _id })),
                    { type: 'Sink', id: 'LIST' },
                ]
                : [{ type: 'Sink', id: 'LIST' }],
    }),
    addSink: builder.mutation({
        query: (sink) => ({
            url: 'api/card-wash',
            method: 'POST',
            body: sink,
        }),
        invalidatesTags: [{ type: 'Sink', id: 'LIST' }],
    }),
    updateSink: builder.mutation({
        query: ({ id, body }) => ({
            url: `api/card-wash/${id}`,
            method: 'PUT',
            body: body,
        }),
        invalidatesTags: [{ type: 'Sink', id: 'LIST' }],
    }),
    // إضافة mutation لحذف الـ sink
    deleteSink: builder.mutation({
        query: (id) => ({
            url: `api/card-wash/${id}`,
            method: 'DELETE',
        }),
        invalidatesTags: [{ type: 'Sink', id: 'LIST' }],
    }),
});