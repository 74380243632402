// frontend/src/components/ObtainCertificate.js
import React, { useState } from 'react'
import axios from 'axios'

const ObtainCertificate = () => {
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const handleObtainCertificate = async () => {
        setLoading(true)
        setMessage('')
        try {
            const response = await axios.post('/api/invoices/obtain-certificate')
            setMessage('تم الحصول على الشهادة بنجاح')
        } catch (error) {
            setMessage(`خطأ في الحصول على الشهادة: ${error.response?.data?.message || error.message}`)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div>
            <button onClick={handleObtainCertificate} disabled={loading}>
                {loading ? 'جارٍ التحميل...' : 'الحصول على الشهادة'}
            </button>
            {message && <p>{message}</p>}
        </div>
    )
}

export default ObtainCertificate