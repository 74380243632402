import React, { Fragment, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Col,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Alert,
} from "reactstrap";
import { useSelector } from "react-redux";
import {
  useGetCustomersQuery,
  useGetEmployeesQuery,
  useAddIncomingInvoiceMutation,
} from "../../../../redux/apis/apiSlice";
import { toast } from "react-toastify";

const IncomingInvoiceForm = () => {
  const { currentUser } = useSelector((state) => state.user);

  // حالة تخزين قيمة البحث عن العملاء
  const [searchCustomer, setSearchCustomer] = useState("");

  // عند تغيير قيمة حقل البحث نقوم بتحديث الحالة
  const handleSearchChange = (e) => {
    setSearchCustomer(e.target.value);
  };

  // تمرير قيمة البحث مع باقي المتغيرات للاستعلام عن العملاء
  const {
    data: GetCustomerData,
    error: errorCustomer,
    isLoading: isLoadingGetCustomer,
  } = useGetCustomersQuery({ customerName: searchCustomer });

  const {
    data: GetEmployeesData,
    error: errorGetEmployees,
    isLoading: isLoadingGetEmployees,
  } = useGetEmployeesQuery();

  const [
    addIncomingInvoice,
    { isLoading: isMutationLoading, error: submitError, isSuccess },
  ] = useAddIncomingInvoiceMutation();

  const employees = GetEmployeesData?.data;
  const customers = GetCustomerData?.data;

  // تعريف مخطط التحقق باستخدام Yup
  const validationSchema = Yup.object().shape({
    customerName: Yup.string().trim(),
    invoiceAmount: Yup.number()
        .required("مبلغ الفاتورة مطلوب")
        .min(0, "مبلغ الفاتورة يجب أن يكون أكبر من الصفر"),
    invoiceDate: Yup.date()
        .max(
            new Date(),
            "تاريخ الفاتورة يجب أن يكون تاريخاً سابقاً أو مساوياً لتاريخ اليوم"
        )
        .required("تاريخ الفاتورة مطلوب"),
    employeeId: Yup.string().required("معرف الموظف مطلوب"),
    customer: Yup.string().required("معرف العميل مطلوب"),
  });

  // دالة للحصول على تاريخ اليوم بصيغة YYYY-MM-DD
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = `0${today.getMonth() + 1}`.slice(-2);
    const day = `0${today.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  // القيم الابتدائية للنموذج
  const initialValues = {
    customerName: "",
    invoiceAmount: "",
    invoiceDate: getTodayDate(),
    employeeId: "",
    customer: "",
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const dataToSubmit = {
        ...values,
        userId: currentUser?.data?._id,
      };
      await addIncomingInvoice(dataToSubmit).unwrap();
      resetForm();
      toast.success("تم إضافة الفاتورة بنجاح!");
    } catch (err) {
      console.error("Failed to save the invoice: ", err);
      toast.error(
          err?.data?.message || "حدث خطأ أثناء إضافة الفاتورة. حاول مرة أخرى."
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
      <Fragment>
        <CardBody>
          {isSuccess && <Alert color="success">تم إضافة الفاتورة بنجاح!</Alert>}
          {submitError && (
              <Alert color="danger">
                حدث خطأ أثناء إضافة الفاتورة. حاول مرة أخرى.
              </Alert>
          )}
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
          >
            {({ isSubmitting: isFormikSubmitting, touched, errors }) => (
                <Form className="theme-form form-default">
                  {/* حقل بحث عن العملاء */}
                  <FormGroup row>
                    <Label for="searchCustomer" sm={3}>
                      بحث عن العميل
                    </Label>
                    <Col sm={9}>
                      <Input
                          id="searchCustomer"
                          type="text"
                          placeholder="ادخل اسم العميل للبحث"
                          value={searchCustomer}
                          onChange={handleSearchChange}
                      />
                    </Col>
                  </FormGroup>

                  {/* باقي الحقول */}
                  <FormGroup row>
                    <Label for="customerName" sm={3}>
                      اسم المورد
                    </Label>
                    <Col sm={9}>
                      <Field
                          name="customerName"
                          as={Input}
                          type="text"
                          placeholder="اسم المورد"
                          invalid={!!(touched.customerName && errors.customerName)}
                      />
                      <ErrorMessage
                          name="customerName"
                          component="div"
                          className="text-danger"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="invoiceAmount" sm={3}>
                      مبلغ الفاتورة
                    </Label>
                    <Col sm={9}>
                      <Field
                          name="invoiceAmount"
                          as={Input}
                          type="number"
                          placeholder="مبلغ الفاتورة"
                          invalid={!!(touched.invoiceAmount && errors.invoiceAmount)}
                      />
                      <ErrorMessage
                          name="invoiceAmount"
                          component="div"
                          className="text-danger"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="invoiceDate" sm={3}>
                      تاريخ الفاتورة
                    </Label>
                    <Col sm={9}>
                      <Field
                          name="invoiceDate"
                          as={Input}
                          type="date"
                          placeholder="تاريخ الفاتورة"
                          invalid={!!(touched.invoiceDate && errors.invoiceDate)}
                      />
                      <ErrorMessage
                          name="invoiceDate"
                          component="div"
                          className="text-danger"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="employee" sm={3}>
                      الموظف
                    </Label>
                    <Col sm={9}>
                      <Field
                          name="employeeId"
                          as={Input}
                          type="select"
                          invalid={!!(touched.employeeId && errors.employeeId)}
                      >
                        <option value="">اختر الموظف</option>
                        {isLoadingGetEmployees ? (
                            <option disabled>جار التحميل...</option>
                        ) : errorGetEmployees ? (
                            <option disabled>خطأ في تحميل الموظفين</option>
                        ) : (
                            employees?.map((emp) => (
                                <option key={emp._id} value={emp._id}>
                                  {emp.name}
                                </option>
                            ))
                        )}
                      </Field>
                      <ErrorMessage
                          name="employeeId"
                          component="div"
                          className="text-danger"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="customer" sm={3}>
                      العميل
                    </Label>
                    <Col sm={9}>
                      <Field
                          name="customer"
                          as={Input}
                          type="select"
                          invalid={!!(touched.customer && errors.customer)}
                      >
                        <option value="">اختر العميل</option>
                        {isLoadingGetCustomer ? (
                            <option disabled>جار التحميل...</option>
                        ) : errorCustomer ? (
                            <option disabled>خطأ في تحميل العملاء</option>
                        ) : customers?.length > 0 ? (
                            customers.map((cust) => (
                                <option key={cust._id} value={cust._id}>
                                  {cust.customerName}
                                </option>
                            ))
                        ) : (
                            <option disabled>لا توجد نتائج</option>
                        )}
                      </Field>
                      <ErrorMessage
                          name="customer"
                          component="div"
                          className="text-danger"
                      />
                    </Col>
                  </FormGroup>

                  <Field type="hidden" name="userId" />

                  <FormGroup row className="mb-0">
                    <Label sm={3} className="col-form-label"></Label>
                    <Col sm={9}>
                      <Button
                          color="primary"
                          type="submit"
                          disabled={isFormikSubmitting || isMutationLoading}
                      >
                        {isFormikSubmitting || isMutationLoading ? (
                            <Spinner size="sm" />
                        ) : (
                            "اضافة"
                        )}
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
            )}
          </Formik>
        </CardBody>
      </Fragment>
  );
};

export default IncomingInvoiceForm;