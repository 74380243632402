
export const updatePhotoInGalleryEndpoint = (builder) => ({
    updatePhotoInGallery: builder.mutation({
        query: ({ galleryId, imageId, formData }) => ({
            url: `/api/gallery/${galleryId}/images/${imageId}`,
            method: 'PUT',
            body: formData,
        }),
        invalidatesTags: (result, error, { imageId }) => [{ type: 'Gallery', id: imageId }],
    }),
})

