// Unauthorized.jsx
import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Button} from "flowbite-react";
import {useDispatch} from "react-redux";
import {signoutSuccess} from "../redux/user/userSlice";
import {toast} from "react-toastify";

const Unauthorized = () => {
    const history = useNavigate();

    const dispatch = useDispatch();
    const logout = () => {
        dispatch(signoutSuccess());
        localStorage.clear();

        history(`${process.env.PUBLIC_URL}/dashboard/default`);
        toast.success("تم تسجيل الخروج بنجاح");

    }
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>غير مصرح لك بالوصول إلى هذه الصفحة.</h1>
            <Button onClick={logout}>تسجيل الخروج </Button>

        </div>
    );
};

export default Unauthorized;