import React, { Fragment } from 'react';
import PostsSidebar from './PostsSidebar';
import PostsTotal from './PostsTotal';

const PostFeatures = () => {
  return (
    <Fragment>
      <div className="feature-products">
        <PostsTotal />
        <PostsSidebar />
      </div>
    </Fragment>
  );
};
export default PostFeatures;