import React, {Fragment, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Button, Col, Container, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import { Btn, H4, H6, P } from '../../../AbstractElements';
import image from '../../../assets/images/login/login_bg.jpg';
import { Done, EnterOTP, NewPassword, RememberPassword, Resend, RetypePassword, Send, SignIn } from '../../../Constant';
import apiAxios from "../../../apiAxios";
import {toast} from "react-toastify";
import {resetPasswordValidationSchema, sendCodeValidationSchema} from "../../../validations/forgetValidation";

const ForgetPwd = () => {
    const [email , setEmail] = useState('')
    const[resetCode , setResetCode] = useState('')
    const [newPassword, setNewPassword] = useState('');
    const [disapleEmailInput, setDisapleEmailInput] = useState(false)
    const[error,setError] = useState([])
    const [loading , setLoading] = useState(false)
    const navigate = useNavigate(); // استخدام useNavigate بدلاً من history
    const submitFormHandler = async (e)=>{
        e.preventDefault()
    }
    const sendCodeToEmail = async (e)=>{
        async function validateEmail(data) {
            try {
                await sendCodeValidationSchema.validate(data);
                setLoading(true)
                try {
                    const res = await apiAxios.post('api/auth/forget-password', {email} )
                    if (res.status === 200) {
                        setDisapleEmailInput(true)
                        alert('Verification code has been sent to your email address')
                        setLoading(false)
                        setError('')
                    } else {
                        alert('Failed to send verification code')
                        setLoading(false)
                    }
                } catch (error) {
                    console.error('خطأ أثناء إرسال رمز التحقق:', error);
                    if (error.response && error.response.data && error.response.data.message) {
                        alert(`خطأ: ${error.response.data.message}`);
                    } else {
                        alert('حدث خطأ أثناء إرسال رمز التحقق');
                    }
                }
            } catch (err) {
                // البيانات غير صالحة
               setError(err.errors);
            }
        }
        await validateEmail({email});
    }
    const changePassword = async ()=>{
        async function validateResetPassword(data) {
            try {
                await resetPasswordValidationSchema.validate(data);
                try {
                    const verifyResponse =  await apiAxios.post('/api/auth/verify-rest-password',{resetCode})
                    if (verifyResponse.status===200) {
                        try {
                            const res = await apiAxios.put('/api/auth/rest-password',{
                                email,
                                newPassword
                            })
                            if (res.status === 200) {
                                alert('Password has been changed successfully')
                                toast.success("Login Success...!");
                                navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
                            } else {
                                alert('Failed to change password')
                            }
                        }catch (error) {
                            if (error.response && error.response.data && error.response.data.message) {
                                alert(`خطا الاول : ${error.response.data.message}`);
                            } else {
                                alert(`خطأ: ${error.response.data.message}`);
                            }
                        }
                    }
                }catch (error) {
                    alert('Failed to change password')
                }
            } catch (err) {
                setError(err.errors);
            }
        }
        await validateResetPassword({
            resetCode: resetCode,
            newPassword: newPassword,
            retypePassword: newPassword,
            rememberPassword: true,
        });

    }
    return (
        <Fragment>
            <section>
                <Container className='p-0' fluid={true}>
                    <Row className="m-0">
                        <Col className="p-0">
                            <div className="login-card" style={{ backgroundImage: `url(${image})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                display: 'block' }}>
                                <div className="login-main">
                                    <Form className="theme-form login-form" onSubmit={submitFormHandler}>
                                        <H4 attrH4={{ className: 'mb-3' }}>Reset Your Password</H4>
                                        <FormGroup>
                                            <Label>{"Enter Your Email"}</Label>
                                            <Row className='gy-2'>
                                                <Col xl="8" sm="9">
                                                    <Input
                                                        className="form-control"
                                                        type="email"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        value={email}
                                                        readOnly={disapleEmailInput}
                                                    />
                                                    {disapleEmailInput && <p className='text-success'>{'Check your email for the verification code.'}</p>}

                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <FormGroup>
                                            <Button onClick={sendCodeToEmail} disabled={loading} attrBtn={{ color: 'primary', type: 'submit' }} >{"Send"}</Button>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>{EnterOTP}</Label>
                                            <Row>
                                                <Col>
                                                    <Input
                                                        className="form-control text-center opt-text"
                                                        onChange={(e)=>setResetCode(e.target.value)}
                                                        placeholder={`ادخل الرمز الذي وصلك على الايميل هنا`}
                                                        type="text"
                                                        value={resetCode} maxlength="6" />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <H6>Create Your Password</H6>
                                        <FormGroup>
                                            <Label>{NewPassword}</Label>
                                            <div className="input-group">
                                                <span className="input-group-text">
                                                    <i className="icon-lock"></i>
                                                </span>
                                                <Input
                                                    className="form-control"
                                                    type="password"
                                                    id="password"
                                                    required=""
                                                    value={newPassword}
                                                    name="password"
                                                    onChange={(e)=>setNewPassword(e.target.value)}
                                                    placeholder="6 and more" />
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>{RetypePassword}</Label>
                                            <div className="input-group">
                                                <span className="input-group-text">
                                                    <i className="icon-lock"></i>
                                                </span>
                                                <Input className="form-control" type="password" name="login[password]" required="" placeholder="*********" />
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            {error.length > 0 && (
                                                <div className="error-summary">
                                                    <ul>
                                                        {error.map((error, index) => (
                                                            <li key={index}>{error}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}

                                        </FormGroup>
                                        <FormGroup>
                                            <Button onClick={changePassword} attrBtn={{ class: 'btn btn-primary', type: 'submit' }} >{Done}</Button>
                                        </FormGroup>
                                        <P>Already have an password?
                                            <Link to={`${process.env.PUBLIC_URL}/login`}>
                                                <a className="ms-2" href="#javascritp">{SignIn}</a>
                                            </Link>
                                        </P>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
};
export default ForgetPwd;