import React, { useState, useEffect } from 'react';
import { H5 } from '../../../../AbstractElements';
import { Row, Col, Card, CardHeader, Table, Spinner, Alert, Input, Button, Form, FormGroup, Label } from 'reactstrap';
import { useGetPaymentsQuery, useGetEmployeesQuery } from "../../../../redux/apis/apiSlice";
import ReactPaginate from 'react-paginate';

// دالة لتنسيق التاريخ
const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // الأشهر تبدأ من 0
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

const TableHeadOptionsClass = () => {
    const itemsPerPage = 10; // عدد العناصر في كل صفحة
    const [currentPage, setCurrentPage] = useState(1); // الصفحة الحالية

    // حالة الفلاتر
    const [filters, setFilters] = useState({
        customerName: '',
        employeeId: '',
        status: '',
        paymentMethod: ''
    });

    // استخدام الاستعلام لجلب جميع الدفعات مع تمرير page, limit, و الفلاتر
    const {
        data: paymentsData,
        error: paymentsError,
        isLoading: paymentsLoading,
        isFetching: paymentsFetching
    } = useGetPaymentsQuery({ page: currentPage, limit: itemsPerPage, filters });

    // استخدام الاستعلام لجلب قائمة الموظفين
    const {
        data: employeesData,
        error: employeesError,
        isLoading: employeesLoading,
        isFetching: employeesFetching
    } = useGetEmployeesQuery();

    const [totalPages, setTotalPages] = useState(0); // إجمالي عدد الصفحات

    // تحديث إجمالي عدد الصفحات عند تغير البيانات
    useEffect(() => {
        if (paymentsData?.totalPages) {
            setTotalPages(paymentsData.totalPages);
        } else if (paymentsData?.total) {
            setTotalPages(Math.ceil(paymentsData.total / itemsPerPage));
        }
    }, [paymentsData, itemsPerPage]);

    // التعامل مع تغيير الصفحة
    const handlePageClick = (data) => {
        const selectedPage = data.selected + 1; // ReactPaginate يبدأ العد من 0
        setCurrentPage(selectedPage);
    };

    // التعامل مع تغيير الفلاتر
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
        setCurrentPage(1); // إعادة تعيين الصفحة الحالية إلى 1 عند تغيير الفلاتر
    };

    // التعامل مع تقديم البحث
    const handleSearch = (e) => {
        e.preventDefault();
        setCurrentPage(1); // إعادة تعيين الصفحة الحالية إلى 1 عند تقديم البحث
    };

    // التعامل مع إعادة تعيين الفلاتر
    const handleResetFilters = () => {
        setFilters({
            customerName: '',
            employeeId: '',
            status: '',
            paymentMethod: ''
        });
        setCurrentPage(1);
    };

    // تحديد حالات التحميل والأخطاء بناءً على البيانات المعروضة
    const isLoading = paymentsLoading || employeesLoading;
    const isFetchingData = paymentsFetching || employeesFetching;
    const error = paymentsError || employeesError;

    // تحديد البيانات المعروضة
    const payments = paymentsData?.data;

    // حساب عدد الأعمدة ديناميكيًا
    const columnCount = 8; // تسلسل، اسم العميل، اسم الموظف، المبلغ، التاريخ، طريقة الدفع، الحالة
console.log(paymentsData)
    return (
        <Col sm="12">
            {paymentsData?.data.length>0 ? (
                    <Card>
                        <CardHeader>
                            <H5>{"قائمة العملاء"}</H5>
                            <span>
                        {'هنا قائمة العملاء'}
                    </span>
                        </CardHeader>
                        <Row className="card-block">
                            <Col sm="12" lg="12" xl="12">
                                {/* عناصر البحث */}
                                <Form onSubmit={handleSearch} className="mb-3">
                                    <Row form className={``}>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="customerName">اسم العميل</Label>
                                                <Input
                                                    type="text"
                                                    name="customerName"
                                                    id="customerName"
                                                    placeholder="ابحث باسم العميل"
                                                    value={filters.customerName}
                                                    onChange={handleFilterChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="employeeId">اسم الموظف</Label>
                                                <Input
                                                    type="select"
                                                    name="employeeId"
                                                    id="employeeId"
                                                    value={filters.employeeId}
                                                    onChange={handleFilterChange}
                                                >
                                                    <option value="">جميع الموظفين</option>
                                                    {employeesData && employeesData?.data.map((employee) => (
                                                        <option key={employee._id} value={employee._id}>
                                                            {employee.name}
                                                        </option>
                                                    ))}
                                                </Input>
                                                {employeesLoading && <Spinner size="sm" color="primary" />}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="status">الحالة</Label>
                                                <Input
                                                    type="select"
                                                    name="status"
                                                    id="status"
                                                    value={filters.status}
                                                    onChange={handleFilterChange}
                                                >
                                                    <option value="">جميع الحالات</option>
                                                    <option value="مكتملة">مكتملة</option>
                                                    <option value="قيد الانتظار">قيد الانتظار</option>
                                                    {/* أضف المزيد من الخيارات حسب الحاجة */}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="paymentMethod">طريقة الدفع</Label>
                                                <Input
                                                    type="select"
                                                    name="paymentMethod"
                                                    id="paymentMethod"
                                                    value={filters.paymentMethod}
                                                    onChange={handleFilterChange}
                                                >
                                                    <option value="">جميع طرق الدفع</option>
                                                    <option value="كاش">كاش</option>
                                                    <option value="تحويل">تحويل</option>
                                                    <option value="شيك">شيك</option>
                                                    {/* أضف المزيد من الخيارات حسب الحاجة */}
                                                </Input>

                                            </FormGroup>

                                        </Col>
                                        <Col md={2} className="d-flex align-items-end">
                                            <Button color="primary" type="submit" block className="me-2">
                                                بحث
                                            </Button>
                                            <Button color="secondary" type="button" block onClick={handleResetFilters}>
                                                إعادة تعيين
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>

                                {/* جدول البيانات */}
                                <div className="table-responsive position-relative">
                                    {isLoading ? (
                                        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                            <Spinner color="primary" />
                                        </div>
                                    ) : error ? (
                                        <Alert color="danger">
                                            حدث خطأ أثناء جلب البيانات. يرجى المحاولة مرة أخرى.
                                        </Alert>
                                    ) : (
                                        <Table>
                                            <thead className="table-primary">
                                            <tr>
                                                <th scope="col">تسلسل.</th>
                                                <th scope="col">اسم العميل</th>
                                                <th scope="col">اسم الموظف</th>
                                                <th scope="col">المبلغ</th>
                                                <th scope="col">التاريخ</th>
                                                <th scope="col">طريقة الدفع</th>
                                                <th scope="col">الحالة</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {payments?.length > 0 ? (
                                                payments.map((item, index) => (
                                                    <tr key={item._id}>
                                                        <th scope="row">{(currentPage - 1) * itemsPerPage + index + 1}</th>
                                                        <td>{item.customer?.customerName || 'غير متوفر'}</td>
                                                        <td>{item.employee?.name || 'غير متوفر'}</td>
                                                        <td>{item.amount}</td>
                                                        <td>{formatDate(item.paymentDate)}</td>
                                                        <td>{item.paymentMethod}</td>
                                                        <td>{item.status}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={columnCount} style={{ textAlign: 'center' }}>لا توجد بيانات لعرضها.</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </Table>
                                    )}

                                    {/* مؤشر التحميل عند جلب البيانات الجديدة */}
                                    {isFetchingData && (
                                        <div className="d-flex justify-content-center align-items-center" style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                            padding: '20px',
                                            borderRadius: '8px'
                                        }}>
                                            <Spinner color="primary" />
                                        </div>
                                    )}
                                </div>

                                {/* مكون Pagination */}
                                {!isLoading && totalPages > 1 && (
                                    <ReactPaginate
                                        previousLabel={"السابق"}
                                        nextLabel={"التالي"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={totalPages} // إجمالي عدد الصفحات
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        forcePage={currentPage - 1} // لضمان تزامن الصفحة الحالية مع ReactPaginate
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Card>
            ):
                <Alert color="info">
                   لا يوجد دفعات لعرضها , اضف من الفورم الاسفل ليظهر هنا
                </Alert>
            }

        </Col>
    );
};

export default TableHeadOptionsClass;