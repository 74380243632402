// redux/apis/endpoints/signIn.js
export const signInEndpoint = (builder) => ({
    signIn: builder.mutation({
        query: (formData) => ({
            url: 'api/auth/signin',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: formData,
        }),
    }),
});