// redux/apis/endpoints/getPosts.js

export const getPostsEndpoint = (builder) => ({
    getPosts: builder.query({
        // تعريف استعلام الـ GET مع الفلاتر والمعلمات المطلوبة
        query: ({
                    userId,
                    category,
                    slug,
                    postId,
                    searchTerm,
                    page = 1,
                    limit = 10,
                    order = 'desc'
                } = {}) => {
            // بناء معلمات الاستعلام (Query Parameters)
            const params = new URLSearchParams();
            if (userId) params.append('userId', userId);
            if (category) params.append('category', category);
            if (slug) params.append('slug', slug);
            if (postId) params.append('postId', postId);
            if (searchTerm) params.append('searchTerm', searchTerm);
            params.append('page', page);
            params.append('limit', limit);
            params.append('order', order);

            return `api/post/getposts?${params.toString()}`;
        },
        // تعريف الـ Tags لتحديث البيانات بكفاءة
        providesTags: (result) =>
            result?.posts
                ? [
                    ...result.posts.map(({ _id }) => ({ type: 'Post', id: _id })),
                    { type: 'Post', id: 'LIST' },
                ]
                : [{ type: 'Post', id: 'LIST' }],
    }),
});