// redux/apis/endpoints/getPayments.js

export const getPaymentsEndpoint = (builder) => ({
    getPayments: builder.query({
        // تعديل query لقبول معلمات page, limit, و الفلاتر
        query: ({ page, limit, filters }) => {
            const { customerName, employeeName, status, paymentMethod } = filters;

            const queryParams = new URLSearchParams({
                page: page.toString(),
                limit: limit.toString(),
            });

            if (customerName) {
                queryParams.append('customerName', customerName);
            }
            if (employeeName) {
                queryParams.append('employeeName', employeeName);
            }
            if (status) {
                queryParams.append('status', status);
            }
            if (paymentMethod) {
                queryParams.append('paymentMethod', paymentMethod);
            }

            return `api/payments/get-payments?${queryParams.toString()}`;
        },
        providesTags: (result) =>
            result
                ? [
                    ...result?.data.map(({ _id }) => ({ type: 'Payments', id: _id })),
                    { type: 'Payments', id: 'LIST' },
                ]
                : [{ type: 'Payments', id: 'LIST' }],
    }),
});