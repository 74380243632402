import { H5 } from "../../../../AbstractElements";
import {
  Col,
  Card,
  CardHeader,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Alert,
} from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { useGetPaymentsByEmployeeQuery } from "../../../../redux/apis/apiSlice";
import { useParams } from "react-router-dom";
import PacmanLoader from "react-spinners/PacmanLoader";
import { PropagateLoader } from "react-spinners";

const BasicTableClass = () => {
  const { employeeId } = useParams();

  // استدعاء الـ Hooks في أعلى الكومبوننت
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10); // يمكنك تغيير القيمة الافتراضية
  const [employeeName, setEmployeeName] = useState("غير متوفر");

  // حالات الفلترة
  const [customerName, setCustomerName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [amount, setAmount] = useState("");

  // حالات البحث المطبقة (عند الضغط على زر البحث)
  const [searchFilters, setSearchFilters] = useState({
    customerName: "",
    startDate: "",
    endDate: "",
    amount: "",
  });

  const {
    data: getPaymentByEmployeeData,
    error: getPaymentByEmployeeError,
    isLoading,
    isFetching,
  } = useGetPaymentsByEmployeeQuery(
    { employeeId, page, limit, ...searchFilters },
    { skip: !employeeId }
  );
  console.log(getPaymentByEmployeeData);
  const payments = getPaymentByEmployeeData?.data || {};
  // ضبط الصفحة إذا تجاوزت totalPages
  const totalCount = payments?.totalCount || 0;
  const totalPages = Math.max(Math.ceil(totalCount / limit), 1);

  useEffect(() => {
    if (page > totalPages) {
      setPage(totalPages);
    }
  }, [totalPages, page]);

  useEffect(() => {
    const employee =
      payments?.payments?.find((e) => e.employeeId._id === employeeId)
        ?.employeeId.name || "غير متوفر";
    setEmployeeName(employee);
  }, [payments, employeeId]);

  // وظيفة للحصول على رسالة الخطأ
  const getErrorMessage = (error) => {
    if (error.status && error.data && error.data.message) {
      // خطأ من الخادم (مثلاً، خطأ 4xx أو 5xx)
      return error.data.message;
    } else if (error.error) {
      // خطأ في الشبكة أو خطأ آخر
      return error.error;
    } else {
      // نوع آخر من الأخطاء
      return "حدث خطأ غير متوقع.";
    }
  };

  // تحديد ما إذا كانت البيانات قيد التحميل
  const isDataLoading = isLoading || isFetching;

  // عرض علامة التحميل عند تحميل البيانات
  if (isDataLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <PropagateLoader color="#36D7B7" size={50} />
      </div>
    );
  }

  // عرض رسالة الخطأ إن وجدت
  if (getPaymentByEmployeeError) {
    console.log("Error object:", getPaymentByEmployeeError);
    const errorMessage = getErrorMessage(getPaymentByEmployeeError);
    return <div>حدث خطأ: {errorMessage}</div>;
  }

  // إنشاء مصفوفة بأرقام الصفحات
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // حساب المبلغ الكلي
  const totalAmount =
    payments?.payments?.reduce(
      (sum, payment) => sum + (Number(payment.amount) || 0),
      0
    ) || 0;

  return (
    <Fragment>
      {getPaymentByEmployeeData?.data?.payments.length > 0 ? (
        <Col sm="12">
          <Card>
            <CardHeader>
              <H5>{"الإيرادات"}</H5>
              <span>جميع الإيرادات الخاصة بالموظف: {employeeName}</span>
              {/* عناصر الفلترة باستخدام Reactstrap */}
              <Form className="filters" style={{ marginTop: "20px" }}>
                <Row form>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="customerName">بحث باسم العميل</Label>
                      <Input
                        type="text"
                        id="customerName"
                        placeholder="بحث باسم العميل"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="startDate">تاريخ البداية</Label>
                      <Input
                        type="date"
                        id="startDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="endDate">تاريخ النهاية</Label>
                      <Input
                        type="date"
                        id="endDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="amount">المبلغ</Label>
                      <Input
                        type="number"
                        id="amount"
                        placeholder="المبلغ"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form className="mt-3">
                  <Col md={6} className="d-flex justify-content-start">
                    <Button
                      color="primary"
                      onClick={() => {
                        // التحقق من صحة نطاق التواريخ
                        if (
                          startDate &&
                          endDate &&
                          new Date(startDate) > new Date(endDate)
                        ) {
                          alert("تاريخ البداية يجب أن يكون قبل تاريخ النهاية.");
                          return;
                        }

                        setSearchFilters({
                          customerName,
                          startDate,
                          endDate,
                          amount,
                        });
                        setPage(1); // إعادة تعيين الصفحة عند تطبيق الفلاتر
                      }}
                      className="me-2"
                    >
                      بحث
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => {
                        setCustomerName("");
                        setStartDate("");
                        setEndDate("");
                        setAmount("");
                        setSearchFilters({
                          customerName: "",
                          startDate: "",
                          endDate: "",
                          amount: "",
                        });
                        setPage(1); // إعادة تعيين الصفحة عند إعادة ضبط الفلاتر
                      }}
                    >
                      إعادة ضبط
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardHeader>
            <div className="table-responsive">
              <Table>
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">اسم العميل</th>
                    <th scope="col">تاريخ الدفعة</th>
                    <th scope="col">طريقة الدفع</th>
                    <th scope="col">المبلغ</th>
                  </tr>
                </thead>
                <tbody>
                  {payments?.payments?.map((payment, index) => (
                    <tr key={payment._id || index}>
                      <th scope="row">{(page - 1) * limit + index + 1}</th>
                      <td>{payment.customerId?.customerName || "غير متوفر"}</td>
                      <td>
                        {new Date(payment.paymentDate).toLocaleDateString()}
                      </td>
                      <td>{payment.paymentMethod}</td>
                      <td>{payment.amount}</td>
                    </tr>
                  ))}
                  {/* صف المبلغ الكلي */}
                  {payments?.payments?.length > 0 && (
                    <tr>
                      <td colSpan="1">
                        <strong>المجموع</strong>
                      </td>
                      <td>
                        <strong>{totalAmount.toLocaleString()} ريال</strong>
                      </td>
                      <td colSpan="3"></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {/* اختيار عدد العناصر في الصفحة */}
            <Row className="justify-content-end align-items-center mb-3">
              <Col xs="auto">
                <Label for="limit" className="me-2">
                  عدد العناصر في الصفحة:
                </Label>
                <Input
                  type="select"
                  id="limit"
                  value={limit}
                  onChange={(e) => {
                    setLimit(parseInt(e.target.value));
                    setPage(1); // إعادة تعيين الصفحة إلى 1 عند تغيير limit
                  }}
                  style={{ width: "100px" }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </Input>
              </Col>
            </Row>
            {/* عناصر التحكم في الباجينيشن باستخدام Reactstrap */}
            <Pagination
              aria-label="Page navigation example"
              className="justify-content-center"
            >
              <PaginationItem disabled={page <= 1}>
                <PaginationLink first onClick={() => setPage(1)} />
              </PaginationItem>
              <PaginationItem disabled={page <= 1}>
                <PaginationLink previous onClick={() => setPage(page - 1)} />
              </PaginationItem>
              {pageNumbers.map((pageNumber) => (
                <PaginationItem active={pageNumber === page} key={pageNumber}>
                  <PaginationLink onClick={() => setPage(pageNumber)}>
                    {pageNumber}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={page >= totalPages}>
                <PaginationLink next onClick={() => setPage(page + 1)} />
              </PaginationItem>
              <PaginationItem disabled={page >= totalPages}>
                <PaginationLink last onClick={() => setPage(totalPages)} />
              </PaginationItem>
            </Pagination>
          </Card>
        </Col>
      ) : (
        <Alert color="info">
          لا يوجد دفعات لعرضها , يمكنك اضافة دفعة من قسم الدفعات
        </Alert>
      )}
    </Fragment>
  );
};

export default BasicTableClass;
