import React, { useState } from "react";
import { H5 } from "../../../../AbstractElements";
import {
  Col,
  Card,
  CardHeader,
  Table,
  Button,
  Spinner,
  Alert,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useGetFawateerByEmployeeQuery } from "../../../../redux/apis/apiSlice";
import { FaEdit, FaTrash } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";

const GetFawateerByEmployee = () => {
  const { employeeId } = useParams();
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  // حالة الصفحة الحالية وعدد الصفوف لكل صفحة
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(5); // تحويل limit إلى حالة

  // جلب البيانات باستخدام RTK Query مع الفلاتر
  const { data, error, isLoading, isFetching, refetch } =
    useGetFawateerByEmployeeQuery(
      {
        employeeId,
        page: currentPage,
        limit,
        name: name.trim() || undefined,
        number: number.trim() || undefined,
        dateFrom: dateFrom || undefined,
        dateTo: dateTo || undefined,
      },
      {
        skip: !employeeId, // تخطي الاستعلام إذا لم يكن employeeId معرفًا
      }
    );

  // التحقق من أن employeeId معرف
  if (!employeeId) {
    return <Alert color="danger">معرف الموظف غير متوفر.</Alert>;
  }

  // دوال التعامل مع أزرار "تعديل" و"حذف"
  const handleEdit = (id) => {
    // قم بتنفيذ منطق التعديل هنا
    toast.info(`تعديل الفاتورة ${id}`);
  };

  const handleDelete = (id) => {
    // قم بتنفيذ منطق الحذف هنا
    toast.error(`حذف الفاتورة ${id}`);
  };

  // دالة تغيير الصفحة
  const handlePageChange = (page) => {
    if (page >= 1 && page <= data?.totalPages && page !== currentPage) {
      setCurrentPage(page);
    }
  };

  // دالة إنشاء عناصر الباجينيشن بناءً على بيانات الباجينيشن من السيرفر
  const renderPagination = () => {
    if (!data || data.totalPages < 1) return null;

    const { totalPages } = data;

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <Pagination aria-label="Page navigation example" size="sm">
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink
            first
            onClick={(e) => {
              e.preventDefault();
              handlePageChange(1);
            }}
            href="#"
          />
        </PaginationItem>
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink
            previous
            onClick={(e) => {
              e.preventDefault();
              handlePageChange(currentPage - 1);
            }}
            href="#"
          />
        </PaginationItem>
        {pageNumbers.map((p) => (
          <PaginationItem active={p === currentPage} key={p}>
            <PaginationLink
              onClick={(e) => {
                e.preventDefault();
                handlePageChange(p);
              }}
              href="#"
            >
              {p}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink
            next
            onClick={(e) => {
              e.preventDefault();
              handlePageChange(currentPage + 1);
            }}
            href="#"
          />
        </PaginationItem>
        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink
            last
            onClick={(e) => {
              e.preventDefault();
              handlePageChange(totalPages);
            }}
            href="#"
          />
        </PaginationItem>
      </Pagination>
    );
  };

  // دالة التعامل مع البحث
  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1); // إعادة تعيين الصفحة الحالية إلى 1 عند البحث
    refetch();
  };

  // دالة إعادة تعيين الفلاتر
  const handleReset = () => {
    setName("");
    setNumber("");
    setDateFrom("");
    setDateTo("");
    setCurrentPage(1);
    refetch();
  };

  // أنماط مخصصة لتصغير الأزرار والجدول
  const customStyles = {
    button: {
      padding: "0.25rem 0.5rem",
      fontSize: "0.8rem",
    },
    table: {
      fontSize: "0.9rem",
    },
    tableCell: {
      padding: "0.5rem",
    },
  };
  console.log("datadatadatadata", data?.data);
  return (
    <Col sm="12">
      {data?.data.length > 0 ? (
        <Card>
          <CardHeader>
            <H5>{"قائمة المشتريات"}</H5>
          </CardHeader>
          <div className="p-3">
            {/* عناصر البحث (الفلاتر) */}
            <Form onSubmit={handleSearch}>
              <div className="row g-3 align-items-end">
                <div className="col-md-3">
                  <FormGroup>
                    <Label for="name">اسم المورد</Label>
                    <Input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="أدخل اسم المورد"
                    />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup>
                    <Label for="dateFrom">تاريخ الفاتورة من</Label>
                    <Input
                      type="date"
                      id="dateFrom"
                      value={dateFrom}
                      onChange={(e) => setDateFrom(e.target.value)}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup>
                    <Label for="dateTo">تاريخ الفاتورة إلى</Label>
                    <Input
                      type="date"
                      id="dateTo"
                      value={dateTo}
                      onChange={(e) => setDateTo(e.target.value)}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <Button color="primary" type="submit" className="me-2">
                    بحث
                  </Button>
                  <Button color="secondary" type="button" onClick={handleReset}>
                    إعادة تعيين
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <div className="table-responsive" style={{ position: "relative" }}>
            {/* عرض علامة تحميل أثناء جلب البيانات الجديدة */}
            {isFetching && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 10,
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  padding: "1rem",
                  borderRadius: "0.5rem",
                }}
              >
                <Spinner color="primary" />
              </div>
            )}

            {isLoading ? (
              <div className="d-flex justify-content-center my-4">
                <Spinner color="primary" />
              </div>
            ) : error ? (
              <Alert color="danger">حدث خطأ أثناء جلب البيانات.</Alert>
            ) : data && Array.isArray(data.data) && data.data.length > 0 ? (
              <>
                <Table
                  className="table-border-horizontal"
                  style={customStyles.table}
                >
                  <thead>
                    <tr>
                      <th scope="col" style={customStyles.tableCell}>
                        #
                      </th>
                      <th scope="col" style={customStyles.tableCell}>
                        اسم المورد
                      </th>
                      <th scope="col" style={customStyles.tableCell}>
                        مبلغ الفاتورة
                      </th>
                      <th scope="col" style={customStyles.tableCell}>
                        تاريخ الفاتورة
                      </th>
                      <th scope="col" style={customStyles.tableCell}>
                        الإجراءات
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.data.map((item, index) => (
                      <tr key={item._id}>
                        <th scope="row" style={customStyles.tableCell}>
                          {(currentPage - 1) * limit + index + 1}
                        </th>
                        <td style={customStyles.tableCell}>
                          {item.customerName}
                        </td>
                        <td style={customStyles.tableCell}>
                          {item.invoiceAmount}
                        </td>
                        <td style={customStyles.tableCell}>
                          {new Date(item.invoiceDate).toLocaleDateString()}
                        </td>
                        <td style={customStyles.tableCell}>
                          <div className="d-flex gap-1">
                            <Button
                              color="primary"
                              size="sm"
                              style={customStyles.button}
                              onClick={() => handleEdit(item._id)}
                            >
                              <FaEdit style={{ marginRight: "0.25rem" }} />{" "}
                              تعديل
                            </Button>
                            <Button
                              color="danger"
                              size="sm"
                              style={customStyles.button}
                              onClick={() => handleDelete(item._id)}
                            >
                              <FaTrash style={{ marginRight: "0.25rem" }} /> حذف
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-between align-items-center my-2 container">
                  {/* عنصر Select لتحديد عدد العناصر في الصفحة */}
                  <div>
                    <label htmlFor="limitSelect" className="me-2">
                      عدد العناصر لكل صفحة:
                    </label>
                    <select
                      id="limitSelect"
                      value={limit}
                      onChange={(e) => {
                        setLimit(Number(e.target.value));
                        setCurrentPage(1); // إعادة تعيين الصفحة الحالية إلى 1 عند تغيير الـ limit
                      }}
                      className="form-select form-select-sm d-inline-block"
                      style={{ width: "auto" }}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                    </select>
                  </div>
                  {/* عرض الباجينيشن فقط إذا كان هناك أكثر من صفحة واحدة */}
                  {data && data.totalPages > 1 && (
                    <div>{renderPagination()}</div>
                  )}
                </div>
              </>
            ) : (
              <Alert color="warning">لا توجد بيانات للعرض.</Alert>
            )}
          </div>
          <ToastContainer />
        </Card>
      ) : (
        <Alert color="info">
          لا يوجد فواتير مضافة , اضف الفواتير من قسم الفواتير الواردة او
          المشتريات
        </Alert>
      )}
    </Col>
  );
};

export default GetFawateerByEmployee;
