// redux/apis/endpoints/getGalleryCategories.js

export const getGalleryEndpoint = (builder) => ({
    getGalleryByCategory: builder.query({
        query: (categoryId) => {
            if (categoryId) {
                return `/api/gallery/category/${categoryId}`;
            } else {
                return `/api/gallery/get-all`;
            }
        // يمكنك استخدام transformResponse إذا كنت تحتاج إلى تعديل البيانات المستقبلة
    }}),
});


