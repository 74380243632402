// redux/apis/endpoints/addCustomer.js
export const addCustomerEndpoint = (builder) => ({
    addCustomer: builder.mutation({
        query: (customer) => ({
            url: 'api/customers/add-customer',
            method: 'POST',
            body: customer,
        }),
        invalidatesTags: [{ type: 'Customer', id: 'LIST' }],
    }),
});