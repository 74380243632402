// frontend/src/components/SendInvoice.js
import React, { useState } from 'react'
import axios from 'axios'

const SendInvoice = () => {
    const [invoiceId, setInvoiceId] = useState('')
    const [message, setMessage] = useState('')

    const handleSend = async () => {
        try {
            // إرسال الفاتورة إلى الخادم الخلفي لإرسالها إلى ZATCA
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/invoices/${invoiceId}/send`)
            setMessage('Invoice sent successfully')
        } catch (error) {
            setMessage('Error sending invoice')
        }
    }

    return (
        <div>
            <input
                type="text"
                placeholder="Enter Invoice ID"
                value={invoiceId}
                onChange={(e) => setInvoiceId(e.target.value)}
            />
            <button onClick={handleSend}>Send to ZATCA</button>
            {message && <p>{message}</p>}
        </div>
    )
}

export default SendInvoice