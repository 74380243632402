// validationSchema.js
import * as Yup from 'yup';

export const sendCodeValidationSchema = Yup.object({
    email: Yup.string()
        .email('البريد الإلكتروني غير صالح')
        .required('البريد الإلكتروني مطلوب'),
});

export const resetPasswordValidationSchema = Yup.object({
    resetCode: Yup.string()
        .length(6, 'رمز التحقق يجب أن يتكون من 6 أرقام')
        .required('رمز إعادة الضبط مطلوب'),
    newPassword: Yup.string()
        .min(6, 'يجب أن تكون كلمة المرور 6 أحرف على الأقل')
        .required('كلمة المرور الجديدة مطلوبة'),
    retypePassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'كلمات المرور غير متطابقة')
        .required('إعادة إدخال كلمة المرور مطلوبة'),
    rememberPassword: Yup.boolean(),
});