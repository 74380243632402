import React, {Fragment, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Button, FormGroup, Input, InputGroup, Label} from 'reactstrap';
import { Btn, H4, H5, H6, LI, P, UL } from '../../../AbstractElements';
import { CreateAccount, EmailAddress, Password, PrivacyPolicy, SignIn, YourName } from '../../../Constant';
import {ErrorMessage, Field ,Form, Formik} from "formik";
import {validationSchema} from "../../../validations/validationRegister";
import apiAxios from "../../../apiAxios";
import {toast} from "react-toastify";
import {useSignUpMutation} from "../../../redux/apis/apiSlice";

const RegisterFrom = () => {
    const navigate = useNavigate()
    const[loading, setLoading] = useState(false)
    const [signUp, { isLoading }] = useSignUpMutation();

    const initialValues = {
        username: '',
        email: '',
        phone: '',
        password: '',
        retypePassword: '',
    };
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setLoading(true)
        try {
            const { data, error } = await signUp(values).unwrap();
            if (error) {
                toast.error('Failed to Register');
            } else {
                toast.success('تم تسجيلك كمستخدم جديد , سيتم نقلك الى صفحة تسجيل الدخول');
                resetForm();
                navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
            }
        } catch (err) {
            setLoading(false)
            toast.error(err?.data?.message || err.message);
        }

    };
    return (
        <Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({isSubmitting}) => (
                    <Form className="theme-form login-form">
                        <div className="login-header text-center">
                            <H4>Create your account</H4>
                            <H6>Enter your personal details to create account</H6>
                        </div>
                        <div className="login-social-title">
                            <H5>Sign in with Email</H5>
                        </div>
                        <FormGroup>
                            <Label>{YourName}</Label>
                            <div className="small-group">
                                <InputGroup>
                                 <span className="input-group-text">
                                     <i className='icon-user'></i>
                                     <Field id='username'  type="text" name="username" placeholder={`username`}
                                            className="form-control"/>
                                </span>
                                    <ErrorMessage name="username" component="div" className="text-danger"/>
                                </InputGroup>
                                <InputGroup>
                            <span className="input-group-text">
                                <i className='icon-direction'></i>
                            <Field id='phone' type="text" name="phone" placeholder={`Phone`}
                                   className="form-control hide-spinner"/>
                            </span>
                                    <ErrorMessage name="phone" component="div" className="text-danger"/>
                                </InputGroup>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label>{EmailAddress}</Label>
                            <InputGroup>
                        <span className="input-group-text">
                            <i className='icon-email'></i>
                        </span>
                                <Field id='email' type="email" name="email" placeholder={`Email`} className="form-control"/>
                                <ErrorMessage name="email" component="div" className="text-danger"/>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label>{Password}</Label>
                            <div className="small-group">
                                <InputGroup>
                                 <span className="input-group-text">
                                     <i className='icon-user'></i>
                                     <Field id='password' type="text" name="password" placeholder={`password`}
                                            className="form-control"/>
                                </span>
                                    <ErrorMessage name="password" component="div" className="text-danger"/>
                                </InputGroup>
                                <InputGroup>
                            <span className="input-group-text">
                                <i className='icon-direction'></i>
                            <Field id='retypePassword' type="text" name="retypePassword" placeholder={`retype Password`}
                                   className="form-control hide-spinner"/>
                            </span>
                                    <ErrorMessage name="retypePassword" component="div" className="text-danger"/>
                                </InputGroup>
                            </div>

                        </FormGroup>
                        <FormGroup>
                            <div className="checkbox">
                                <Input id="checkbox1" type="checkbox"/>
                                <Label className="text-muted" for="checkbox1">Agree with
                                    <span>{PrivacyPolicy}</span></Label>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Button disabled={loading} attrBtn={{color: 'primary', type: 'submit'}}>
                                {CreateAccount}</Button>
                        </FormGroup>
                        <P>Already have an account?
                            <Link to={`${process.env.PUBLIC_URL}/login`} className="ms-2">
                                {SignIn}
                            </Link>
                        </P>
                    </Form>
                )}
            </Formik>
        </Fragment>
    );
};

export default RegisterFrom;