// redux/apis/endpoints/addPayment.js

export const addPaymentEndpoint = (builder) => ({
  addPayment: builder.mutation({
    query: (payment) => ({  // تعديل هنا لإزالة التفكيك ({ userId, employeeId, payment })
      url: `api/payments/add-payment`,
      method: "POST",
      body: payment,  // إرسال الجسم مباشرة بدون تجميع تحت حقل 'payment'
    }),
    invalidatesTags: [{ type: "Payments", id: "LIST" }],
  }),
});