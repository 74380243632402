// redux/apis/apiSlice.js

export const getFawateerByEmployeeEndpoint = (builder) => ({
  getFawateerByEmployee: builder.query({
    query: ({ employeeId, page = 1, limit = 10, name, dateFrom, dateTo }) => {
      const params = new URLSearchParams({
        page: page.toString(),
        limit: limit.toString(),
      });

      if (name) params.append("name", name);
      if (dateFrom) params.append("dateFrom", dateFrom);
      if (dateTo) params.append("dateTo", dateTo);
      return {
        url: `api/incomingInvoices/${employeeId}/fawateer?${params.toString()}`,
      };
    },
    providesTags: (result, error, { employeeId }) => [
      { type: "IncomingInvoice", id: "LIST" },
      { type: "IncomingInvoice", id: employeeId },
    ],
  }),
});
