// src/components/CustomersTable.js
import React, { Fragment, useState } from 'react';
import {
    Col,
    Card,
    CardHeader,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Alert,
    Spinner,
} from 'reactstrap';
import { useGetCustomersQuery, useGetEmployeesQuery } from '../../redux/apis/apiSlice';

const CustomersTable = () => {
    // حالة لإدارة الفلاتر المدخلة
    const [inputFilters, setInputFilters] = useState({
        customerName: '',
        date: '',
        taxNumber: '',
        representative: '',
    });

    // حالة لإدارة الفلاتر المطبقة
    const [appliedFilters, setAppliedFilters] = useState({
        customerName: '',
        date: '',
        taxNumber: '',
        representative: '',
    });

    // حالة لإدارة التقسيم
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // عدد العناصر لكل صفحة

    // حالة لإدارة رسائل الخطأ
    const [errorMessage, setErrorMessage] = useState(null);

    // جلب قائمة الموظفين
    const { data: employeesData, error: employeesError, isLoading: employeesLoading } = useGetEmployeesQuery();

    // جلب البيانات باستخدام الخطاف مع الفلاتر المطبقة والتقسيم
    const { data, error, isLoading, refetch } = useGetCustomersQuery({
        ...appliedFilters,
        page: currentPage,
        limit: itemsPerPage,
    });

    // حساب عدد الصفحات بناءً على البيانات المستلمة
    const totalPages = data ? Math.ceil(data.pagination.totalItems / itemsPerPage) : 0;

    // دالة لتغيير الصفحة
    const paginate = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    // دالة للتعامل مع تغيير مدخلات الفلترة
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    // دالة للتحقق من صحة المدخلات قبل البحث
    const validateFilters = () => {
        const { taxNumber } = appliedFilters;
        // مثال: التحقق من أن الرقم الضريبي يحتوي على 9 أرقام
        if (taxNumber && !/^\d{9}$/.test(taxNumber)) {
            return 'الرقم الضريبي يجب أن يتكون من 9 أرقام.';
        }
        // يمكن إضافة المزيد من قواعد التحقق حسب الحاجة
        return null;
    };

    // دالة لإعادة تعيين الفلاتر المطبقة عند تقديم البحث
    const handleSearch = (e) => {
        e.preventDefault();
        const validationError = validateFilters();
        if (validationError) {
            setErrorMessage(validationError);
            return;
        }
        setErrorMessage(null);
        setAppliedFilters(inputFilters);
        setCurrentPage(1); // إعادة تعيين الصفحة إلى الأولى عند تطبيق الفلاتر
    };

    // دالة لإعادة تعيين الفلاتر وجلب جميع العملاء
    const handleReset = () => {
        setInputFilters({
            customerName: '',
            date: '',
            taxNumber: '',
            representative: '',
        });
        setAppliedFilters({
            customerName: '',
            date: '',
            taxNumber: '',
            representative: '',
        });
        setCurrentPage(1);
        setErrorMessage(null);
    };

    return (
        <Fragment>
            {data?.data.length > 0 ?(
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>{"البحث في العملاء"}</h5>
                                <span>
ابحث بحسب الحقول المتوفرة
                        </span>
                            </CardHeader>

                            {/* واجهة الفلترة والبحث */}
                            <div className="p-3">
                                {errorMessage && (
                                    <Alert color="danger">
                                        {errorMessage}
                                    </Alert>
                                )}
                                <Form onSubmit={handleSearch}>
                                    <div className="row">
                                        <FormGroup className="col-md-3">
                                            <Label for="customerName">اسم العميل</Label>
                                            <Input
                                                type="text"
                                                name="customerName"
                                                id="customerName"
                                                placeholder="ابحث بالاسم"
                                                value={inputFilters.customerName}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup className="col-md-3">
                                            <Label for="date">التاريخ</Label>
                                            <Input
                                                type="date"
                                                name="date"
                                                id="date"
                                                value={inputFilters.date}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup className="col-md-3">
                                            <Label for="taxNumber">الرقم الضريبي</Label>
                                            <Input
                                                type="text"
                                                name="taxNumber"
                                                id="taxNumber"
                                                placeholder="ابحث بالرقم الضريبي"
                                                value={inputFilters.taxNumber}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup className="col-md-3">
                                            <Label for="representative">المندوب الذي يتبع للعميل</Label>
                                            {employeesLoading ? (
                                                <Spinner size="sm" color="primary" />
                                            ) : employeesError ? (
                                                <Alert color="danger">
                                                    حدث خطأ أثناء جلب قائمة المندوبين.
                                                </Alert>
                                            ) : (
                                                <Input
                                                    type="select"
                                                    name="representative"
                                                    id="representative"
                                                    value={inputFilters.representative}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="">اختر الممثل البيعي</option>
                                                    {employeesData?.data.map((rep) => (
                                                        <option key={rep._id} value={rep._id}>
                                                            {rep.name} {/* تأكد من أن خاصية الاسم صحيحة */}
                                                        </option>
                                                    ))}
                                                </Input>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <Button color="primary" type="submit" className="me-2">
                                        بحث
                                    </Button>
                                    <Button color="secondary" type="button" onClick={handleReset}>
                                        إعادة تعيين
                                    </Button>
                                </Form>
                            </div>

                            <div className="table-responsive">
                                <Table className="table-xl" responsive>
                                    <thead>
                                    <tr>
                                        <th>{'#'}</th>
                                        <th>{'اسم العميل'}</th>
                                        <th>{'نوع العميل'}</th>
                                        <th>{'البريد الإلكتروني'}</th>
                                        <th>{'رقم الهاتف'}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {isLoading ? (
                                        <tr>
                                            <td colSpan="5" className="text-center">
                                                <Spinner size="sm" color="primary" /> جاري التحميل...
                                            </td>
                                        </tr>
                                    ) : error ? (
                                        <tr>
                                            <td colSpan="5" className="text-center text-danger">
                                                {error?.data?.message || 'حدث خطأ أثناء جلب البيانات.'}
                                            </td>
                                        </tr>
                                    ) : data && data.data.length > 0 ? (
                                        data.data.map((item) => (
                                            <tr key={item._id}>
                                                <th scope="row">{item.address?.City || "لا يوجد عنوان"}</th>
                                                <td>{item.customerName}</td>
                                                <td>{item.customerType}</td>
                                                <td>{item.email || 'لا يوجد'}</td>
                                                <td>{item.phone || 'لا يوجد'}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5" className="text-center">
                                                لا توجد بيانات لعرضها.
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                            </div>

                            {/* عناصر التحكم في التقسيم */}
                            {totalPages > 1 && (
                                <Pagination aria-label="Page navigation example" className="justify-content-center m-3">
                                    <PaginationItem disabled={currentPage <= 1}>
                                        <PaginationLink
                                            first
                                            onClick={() => paginate(1)}
                                        />
                                    </PaginationItem>
                                    <PaginationItem disabled={currentPage <= 1}>
                                        <PaginationLink
                                            previous
                                            onClick={() => paginate(currentPage - 1)}
                                        />
                                    </PaginationItem>
                                    {Array.from({ length: totalPages }, (_, i) => (
                                        <PaginationItem active={i + 1 === currentPage} key={i + 1}>
                                            <PaginationLink onClick={() => paginate(i + 1)}>
                                                {i + 1}
                                            </PaginationLink>
                                        </PaginationItem>
                                    ))}
                                    <PaginationItem disabled={currentPage >= totalPages}>
                                        <PaginationLink
                                            next
                                            onClick={() => paginate(currentPage + 1)}
                                        />
                                    </PaginationItem>
                                    <PaginationItem disabled={currentPage >= totalPages}>
                                        <PaginationLink
                                            last
                                            onClick={() => paginate(totalPages)}
                                        />
                                    </PaginationItem>
                                </Pagination>
                            )}
                        </Card>
                    </Col>
            ):
                <Alert color="info">
                 لا يوجد عملاء حتى الان , اضف من الاسفل ليتم عرضها هنا
                </Alert>
            }

        </Fragment>
    );
};

export default CustomersTable;