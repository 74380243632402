import React, { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Col } from "reactstrap";
import { H4, H6, LI, UL, Image } from "../../../AbstractElements";
import { useGetEmployeeQuery } from "../../../redux/apis/apiSlice";
import { PacmanLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const ProfileHeader = () => {
  const { currentUser } = useSelector((state) => state.user);
  const userId = currentUser.data._id;
  const { employeeId } = useParams();
  const { data, error, isLoading } = useGetEmployeeQuery({
    userId,
    employeeId,
  });
  if (isLoading) {
    // console.log(data)
    return (
      <Fragment>
        <PacmanLoader />
      </Fragment>
    );
  }
  if (error) {
    return toast.error(`حدث خطا اثناء جلب البيانات: ${error?.data?.message}`);
  }
  if (!data) {
    return <div>لا توجد بيانات متاحة.</div>;
  }

  return (
    <Fragment>
      <Col sm="12">
        <Card
          className="profile-header bg-image"
          style={{
            backgroundImage: `url(${data?.image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "block",
          }}
        >
          <div className="profile-img-wrrap">
            <Image
              attrImage={{
                className: "img-fluid bg-img-cover",
                src: data?.image,
                alt: "",
              }}
            />
          </div>
          <div className="userpro-box">
            <div className="img-wrraper">
              <div className="avatar">
                <Image
                  attrImage={{
                    className: "img-fluid",
                    alt: "",
                    src: data?.image,
                  }}
                />
              </div>
              <Link
                className="icon-wrapper"
                to={`${process.env.PUBLIC_URL}/users/useredit`}
              >
                <i className="icofont icofont-pencil-alt-5"></i>
              </Link>
            </div>
            <div className="user-designation">
              <div className="title">
                <a target="_blank" href="#javascript">
                  <H4>{data.name}</H4>
                  <H6>{data?.position}</H6>
                </a>
              </div>
              <div className="social-media">
                <UL
                  attrUL={{
                    className: "simple-list user-list-social d-flex flex-row",
                  }}
                >
                  <LI>
                    <a href="https://www.facebook.com/">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </LI>
                  <LI>
                    <a href="https://twitter.com/">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </LI>
                  <LI>
                    <a href="https://www.instagram.com/">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </LI>
                </UL>
              </div>
              <div className="follow">
                <UL
                  attrUL={{
                    className: "simple-list follow-list d-flex flex-row",
                  }}
                >
                  <LI>
                    <div className="follow-num counter">{data.salary}</div>
                    <span>{"الراتب"}</span>
                  </LI>
                  <LI>
                    <div className="follow-num counter">450</div>
                    <span>{"اخر دفعة"}</span>
                  </LI>
                  <LI>
                    <div className="follow-num counter">500</div>
                    <span>{"الحسابات"}</span>
                  </LI>
                </UL>
              </div>
            </div>
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};
export default ProfileHeader;
