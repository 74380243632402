import React from 'react';
import { Col, Card, CardBody, Pagination, PaginationItem, PaginationLink, CardHeader } from 'reactstrap';
import { H5, UL } from '../../../AbstractElements';
import { PaginationActiveDisabled, Previous, Next } from '../../../Constant';

const PaginationActiveDisabledClass = () => {
    return (
        <Col xl="12">
            <Card>
                <CardHeader className='pb-0'>
                    <H5>{PaginationActiveDisabled}</H5>
                </CardHeader>
                <CardBody>
                    <Pagination aria-label="...">
                        <UL attrUL={{ className: 'simple-list pagination pagination-primary flex-row' }}>
                            <PaginationItem disabled>
                                <PaginationLink>{Previous}</PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                                <PaginationLink href="#javascript" >{'1'}</PaginationLink>
                            </PaginationItem>
                            <PaginationItem active>
                                <PaginationLink href="#javascript">{'2'} 
                                    <span className="sr-only">{'(current)'}</span>
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                                <PaginationLink href="#javascript">{'3'}</PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                                <PaginationLink href="#javascript">{Next}</PaginationLink>
                            </PaginationItem>
                        </UL>
                    </Pagination>
                </CardBody>
            </Card>
        </Col>
    );
};

export default PaginationActiveDisabledClass;