// YourFormComponentFormik.jsx
import React, { useState } from 'react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import MainPriority from './MainPriority';
import MainDates from './MainDates';
import DropItem from './DropItem';
import { useSelector } from "react-redux";
import axios from "axios";
import {toast} from "react-toastify";

const validationSchema = Yup.object().shape({
    name: Yup.string().required('الاسم مطلوب'),
    email: Yup.string().email('بريد إلكتروني غير صالح').required('البريد الإلكتروني مطلوب'),
    country: Yup.string().required('الدولة مطلوبة'),
    phone: Yup.string().required('رقم الهاتف مطلوب'),
    position: Yup.string().required('الرتبة مطلوبة'),
    salary: Yup.string().required('الراتب مطلوبة'),
    content: Yup.string(),
    // أضف باقي الحقول هنا إذا لزم الأمر
});

const YourFormComponentFormik = () => {
    const { currentUser } = useSelector((state) => state.user);
    const [errorFromServer, setErrorFromServer] = useState('');

    // التحقق من currentUser
    if (!currentUser || !currentUser.data || !currentUser.data._id) {
        return <div>Loading...</div>; // أو أي مكون تحميل آخر
    }

    const initialValues = {
        name: '',
        email: '',
        country: '',
        phone: '',
        jobTitle: '',
        position: '',
        salary: '',
        startingDate: '',
        endingDate: '',
        content: '',
        image: null,
        userId: currentUser.data._id
    };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('email', values.email);
            formData.append('country', values.country);
            formData.append('phone', values.phone);
            formData.append('jobTitle', values.jobTitle);
            formData.append('position', values.position);
            formData.append('salary', values.salary);
            formData.append('content', values.content);
            if (values.image) {
                formData.append('image', values.image);
            }
            formData.append('userId', currentUser.data._id);

            for (let pair of formData.entries()) {
                console.log(`${pair[0]}: ${pair[1]}`);
            }
            const response = await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/employees/add-employees`, formData, {
                headers: {
                    'Authorization': `Bearer ${currentUser?.token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            resetForm();
            setErrorFromServer(response.data.message || 'تمت الإضافة بنجاح');
            toast.success(response.data.message || 'تمت الإضافة بنجاح');

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setErrorFromServer(error.response.data.message);
                toast.error(error.response.data.message || 'خطا في الاضافة');

            } else {
                setErrorFromServer('حدث خطأ غير متوقع');
                toast.error(error.response.data.message || 'خطا في الاضافة');

            }
            console.error('Error submitting form:', error);
            toast.error(error.response.data.message || 'خطا في الاضافة');

        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ setFieldValue, isSubmitting }) => (
                <FormikForm className="theme-form">
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{"EmployeeName"}</Label>
                                <Field
                                    type="text"
                                    name="name"
                                    id={'name'}
                                    placeholder="أدخل الاسم"
                                    className="form-control"
                                />
                                <ErrorMessage name="name" component="span" style={{ color: 'red' }} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>{"Email"}</Label>
                                <Field
                                    type="email"
                                    name="email"
                                    id={'email'}
                                    placeholder="أدخل البريد الإلكتروني"
                                    className="form-control"
                                />
                                <ErrorMessage name="email" component="span" style={{ color: 'red' }} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>{"Phone"}</Label>
                                <Field
                                    type="text" // استخدم type="text" بدلاً من "phone" حيث أن "phone" ليس نوعًا صالحًا في HTML
                                    name="phone"
                                    id={'phone'}
                                    placeholder="أدخل رقم الجوال"
                                    className="form-control"
                                />
                                <ErrorMessage name="phone" component="span" style={{ color: 'red' }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4">
                            <FormGroup>
                                <Label>{"Country"}</Label>
                                <Field
                                    type="text"
                                    name="country"
                                    placeholder="أدخل الدولة"
                                    className="form-control"
                                />
                                <ErrorMessage name="country" component="span" style={{ color: 'red' }} />
                            </FormGroup>
                        </Col>

                        <Col sm="4">
                            <FormGroup>
                                <Label>{"الوظيفة"}</Label>
                                <Field
                                    type="text"
                                    name="jobTitle"
                                    placeholder="مدير عام"
                                    id="jobTitle"
                                    className="form-control"
                                />
                                <ErrorMessage name="jobTitle" component="span" style={{ color: 'red' }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            <FormGroup>
                                <Label>{"الرتبة"}</Label>
                                <Field
                                    type="text"
                                    name="position"
                                    id={'position'}
                                    placeholder="مدير عام"
                                    className="form-control"
                                />
                                <ErrorMessage name="position" component="span" style={{ color: 'red' }} />
                            </FormGroup>
                        </Col>
                        <Col >
                            {/* يمكن حذف العمود الفارغ أو إضافة حقل إذا كان مقصودًا */}
                        </Col>
                        <Col >
                            <FormGroup>
                                <Label>الراتب</Label>
                                <Field
                                    type="text"
                                    name="salary"
                                    id="salary"
                                    placeholder="أدخل الراتب"
                                    className="form-control"
                                />
                                <ErrorMessage name="salary" component="span" style={{ color: 'red' }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <MainPriority />
                        <Col sm="4">
                            <MainDates name="startingDate" label="تاريخ البدء" />
                        </Col>
                        <Col sm="4">
                            <MainDates name="endingDate" label="تاريخ الانتهاء" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <Label>أدخل بعض التفاصيل الإضافية</Label>
                                <Field
                                    as="textarea"
                                    name="content"
                                    rows="3"
                                    className="form-control"
                                    style={{ height: '80px' }}
                                />
                                <ErrorMessage name="content" component="span" style={{ color: 'red' }} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>رفع صورة الموظف</Label>
                                <input
                                    id="image"
                                    name="image"
                                    type="file"
                                    accept=".jpg,.png,.jpeg"
                                    className="form-control"
                                    onChange={(event) => {
                                        setFieldValue("image", event.currentTarget.files[0]);
                                    }}
                                />
                                <ErrorMessage name="image" component="span" style={{ color: 'red' }} />
                            </FormGroup>
                        </Col>
                    </Row>
                    {/*<DropItem />*/}
                    {errorFromServer && (
                        <Row>
                            <Col>
                                <div style={{ color: 'red', marginBottom: '10px' }}>
                                    {errorFromServer}
                                </div>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col className="text-end">
                            <FormGroup className="mb-0">
                                <button
                                    type="submit"
                                    className="btn btn-success me-3"
                                    disabled={isSubmitting}
                                >
                                    إضافة
                                </button>
                            </FormGroup>
                        </Col>
                    </Row>
                </FormikForm>
            )}
        </Formik>
    );
};

export default YourFormComponentFormik;