import D1 from '../../assets/images/dashboard-2/i_1.png';
import D2 from '../../assets/images/dashboard-2/i_2.png';
import D3 from '../../assets/images/dashboard-2/i_3.png';
import D4 from '../../assets/images/dashboard-2/i_4.png';
import D5 from '../../assets/images/dashboard-2/i_5.png';

export const SlideImg =
    [
        { img: '../../../assets/images' },
        { img: '../../../assets/images' },
        { img: '../../../assets/images' },
        { img: '../../../assets/images' }
    ];


export const PaymentSlider = [
    {
        id: 1,
        img: 'dashboard-2/visa-card/master-card.png',
        star: '****',
        number: 8597,
        name: 'Johan deo',
        valid: 'VALID THRU',
        vdate: '11 / 22',
        btn: 'next '
    },
    {
        id: 2,
        img: 'dashboard-2/american-ex.png',
        star: '****',
        number: 358,
        name: 'Johan deo',
        valid: 'VALID THRU',
        vdate: '9 / 22',
        btn: 'next '
    },
    {
        id: 3,
        img: 'dashboard-2/visa-card/visa.png',
        star: '****',
        number: 8597,
        name: 'Johan deo',
        valid: 'VALID THRU',
        vdate: '11 / 22',
        btn: 'next '
    }
];

export const ServiceContactdata = [
    {
        id: 1,
        srno: '01',
        img: D1,
        product: 'Yellow New Nike Shoes',
        time: '16 August',
        qty: '54146',
        discount: '65.00%',
        prize: '$210326',
        status: 'Done',
        class: 'fa fa-arrow-up font-primary me-1'
    },
    {
        id: 2,
        srno: '02',
        img: D2,
        product: 'Sony Brand New Headphon',
        time: '02 Octomber',
        qty: '32015',
        discount: '95.00%',
        prize: '$548564',
        status: 'Done',
        class: 'fa fa-arrow-up font-primary me-1'
    },
    {
        id: 3,
        srno: '03',
        img: D3,
        product: 'Beautiful Golden Tree Plant',
        time: '12 March',
        qty: '95486',
        discount: '02.00%',
        prize: '$884596',
        status: 'Done',
        class: 'fa fa-arrow-up font-primary me-1'
    },
    {
        id: 4,
        srno: '04',
        img: D4,
        product: 'Marco M Kely Handbags',
        time: '31 December',
        qty: '15495',
        discount: '43.00%',
        prize: '$441523',
        status: 'Done',
        class: 'fa fa-arrow-down font-secondary me-1'
    },
    {
        id: 5,
        srno: '05',
        img: D5,
        product: 'Being Human Branded T-shirt',
        time: '26 January',
        qty: '66251',
        discount: '88.00%',
        prize: '$112103',
        status: 'Done',
        class: 'fa fa-arrow-up font-primary me-1'
    },
];

export const TableRowData = [
    {
        id: 1,
        divClass: 'round-light icon-secondary',
        Icons: 'fa fa-star',
        title: 'Win best seller of the year award',
        place: 'Company,San Francisco',
        time: '34 min ago',
    },
    {
        id: 2,
        divClass: 'round-light icon-primary',
        Icons: 'fa fa-check-circle',
        title: 'Approved our product in checking',
        place: 'Elisse Joson,San Francisco',
        time: '1 hour ago',
    },
    {
        id: 3,
        divClass: 'round-light icon-success',
        Icons: 'fa fa-bookmark',
        title: 'Win best seller of the year award',
        place: 'Company,San Francisco',
        time: '34 min ago',
    },
    {
        id: 4,
        divClass: 'round-light icon-warning',
        Icons: 'fa fa-shopping-basket',
        title: 'Approved our product in checking',
        place: 'Elisse Joson,San Francisco',
        time: '1 hour ago',
    },
    {
        id: 5,
        divClass: 'round-light icon-warning',
        Icons: 'fa fa-microchip',
        title: 'Approved our product in checking',
        place: 'Elisse Joson,San Francisco',
        time: '1 hour ago',
    },
];