import * as Yup from 'yup';

export const validationSchema = Yup.object({
    username: Yup.string()
        .required('الاسم مطلوب')
    ,
    phone: Yup.string()
        .required('رقم الجوال مطلوب مطلوب'),
    email: Yup.string()
        .email('البريد الإلكتروني غير صالح')
        .required('البريد الإلكتروني مطلوب'),
    password: Yup.string()
        .min(6, 'يجب أن تكون كلمة المرور 6 أحرف على الأقل')
        .required('كلمة المرور  مطلوبة'),
    retypePassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'كلمات المرور غير متطابقة')
        .required('إعادة إدخال كلمة المرور مطلوبة'),
    rememberPassword: Yup.boolean(),
});