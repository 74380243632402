

// Routers.js
import React, { Fragment, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Loader from "../Layout/Loader";
import LayoutRoutes from "./LayoutRoutes";
import { authRoutes } from "./AuthRoutes";
import PrivateRoute from "./PrivateRoute";
import ScrollToTop from "../Services/ScrollToTop";
import SignIn from "../Auth";
import UnauthorizedPage from "./Unauthorized"; // تأكد من إنشاء صفحة Unauthorized

const Routers = () => {
  const login = localStorage.getItem("login");

  return (
      <Fragment>
        <BrowserRouter basename={"/"}>
          <ScrollToTop />
          <>
            <Suspense fallback={<Loader />}>
              <Routes>
                {/* الروتس المحمية */}
                <Route path="/" element={<PrivateRoute roles={['admin', 'superAdmin' ,'writer']} />}>
                  {login ? (
                      <>
                        <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/default`} />} />
                        <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/default`} />} />
                        <Route path="/*" element={<LayoutRoutes />} />
                      </>
                  ) : (
                      <Route path="/*" element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />} />
                  )}
                </Route>

                {/* صفحة تسجيل الدخول */}
                <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<SignIn />} />

                {/* الروتس الخاصة بالمصادقة (إذا كانت هناك) */}
                {authRoutes.map(({ path, Component }, i) => (
                    <Route path={path} element={Component} key={i} />
                ))}

                {/* صفحة Unauthorized */}
                <Route path={`${process.env.PUBLIC_URL}/unauthorized`} element={<UnauthorizedPage />} />

                {/* روت افتراضي لتحويل الروتس غير المعروفة إلى الصفحة الرئيسية أو صفحة 404 */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Suspense>
          </>
        </BrowserRouter>
      </Fragment>
  );
};

export default Routers;