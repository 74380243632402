import React, { useEffect } from 'react';
import { Button, Card, CardHeader, Col, Container, Input, Label, Row, Alert, Spinner } from 'reactstrap';
import { cities } from "../../Data/Cites/Cities";
import { useDispatch, useSelector } from "react-redux";
import { clearAddress, saveAddress } from "../../redux/address/addressSlice";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {toast} from "react-toastify";

const AddCustomerAddressForm = () => {
    const dispatch = useDispatch();

    const address = useSelector((state) => state.address.address);
    const isLoading = useSelector((state) => state.address.isLoading);
    const isSuccess = useSelector((state) => state.address.isSuccess);
    const isError = useSelector((state) => state.address.isError);
    const errorMessage = useSelector((state) => state.address.errorMessage);

    // تعريف validationSchema باستخدام Yup
    const validationSchema = Yup.object({
        BuildingNumber: Yup.number()
            .typeError('رقم المبنى يجب أن يكون رقمًا')
            .required('رقم المبنى مطلوب'),
        City: Yup.string()
            .required('المدينة مطلوبة'),
        StreetName: Yup.string()
            .required('اسم الشارع مطلوب'),
        District: Yup.string()
            .required('الحي مطلوب'),
        PostalCode: Yup.number()
            .typeError('الرمز البريدي يجب أن يكون رقمًا')
            .required('الرمز البريدي مطلوب'),
        AdditionalNumber: Yup.number()
            .typeError('الرقم الإضافي يجب أن يكون رقمًا')
            .required('الرقم الإضافي مطلوب'),
        UnitNumber: Yup.number()
            .typeError('رقم الوحدة يجب أن يكون رقمًا')
            .required('رقم الوحدة مطلوب'),
    });

    const onSubmit = async (values, { resetForm }) => {
        try {
            await dispatch(saveAddress(values)).unwrap();
            resetForm(); // إعادة تعيين النموذج بعد الحفظ الناجح
        } catch (error) {
            dispatch(clearAddress());
        }
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success("تم إضافة عنوان");


        }
        if (isError) {
            alert(`خطأ: ${errorMessage}`);
        }
    }, [isSuccess, isError, errorMessage, dispatch]);

    return (
        <Container fluid={true}>
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader className="pb-0">
                            <Formik
                                initialValues={{
                                    BuildingNumber: address.BuildingNumber || '',
                                    City: address.City || '',
                                    StreetName: address.StreetName || '',
                                    District: address.District || '',
                                    PostalCode: address.PostalCode || '',
                                    AdditionalNumber: address.AdditionalNumber || '',
                                    UnitNumber: address.UnitNumber || '',
                                }}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                                enableReinitialize // أضف هذه الخاصية
                            >
                                {({
                                      values,
                                      handleChange,
                                      handleSubmit,
                                      errors,
                                      touched,
                                  }) => (
                                    <Form className="needs-validation" noValidate onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md="4" className="mb-3">
                                                <Label htmlFor="BuildingNumber">{"رقم المبنى:"}</Label>
                                                <Input
                                                    className={`form-control ${touched.BuildingNumber && errors.BuildingNumber ? 'is-invalid' : touched.BuildingNumber ? 'is-valid' : ''}`}
                                                    name="BuildingNumber"
                                                    type="number"
                                                    placeholder="رقم المبنى"
                                                    value={values.BuildingNumber}
                                                    onChange={handleChange}
                                                />
                                                {touched.BuildingNumber && errors.BuildingNumber && (
                                                    <div className="invalid-feedback">{errors.BuildingNumber}</div>
                                                )}
                                            </Col>
                                            <Col md="4" className="mb-3">
                                                <Label>{"المدينة: "}</Label>
                                                <Input
                                                    onChange={handleChange}
                                                    type="select"
                                                    name="City"
                                                    value={values.City}
                                                    className={`form-control form-select ${touched.City && errors.City ? 'is-invalid' : touched.City ? 'is-valid' : ''}`}
                                                >
                                                    <option value="">اختر مدينة</option>
                                                    {cities.data.map((item) => (
                                                        <option key={item} value={item}>{item}</option>
                                                    ))}
                                                </Input>
                                                {touched.City && errors.City && (
                                                    <div className="invalid-feedback">{errors.City}</div>
                                                )}
                                            </Col>
                                            <Col md="4" className="mb-3">
                                                <Label htmlFor="StreetName">{"اسم الشارع:"}</Label>
                                                <Input
                                                    className={`form-control ${touched.StreetName && errors.StreetName ? 'is-invalid' : touched.StreetName ? 'is-valid' : ''}`}
                                                    name="StreetName"
                                                    type="text"
                                                    placeholder="اسم الشارع"
                                                    value={values.StreetName}
                                                    onChange={handleChange}
                                                />
                                                {touched.StreetName && errors.StreetName && (
                                                    <div className="invalid-feedback">{errors.StreetName}</div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4" className="mb-3">
                                                <Label htmlFor="District">{"الحي:"}</Label>
                                                <Input
                                                    className={`form-control ${touched.District && errors.District ? 'is-invalid' : touched.District ? 'is-valid' : ''}`}
                                                    name="District"
                                                    type="text"
                                                    placeholder="الحي"
                                                    value={values.District}
                                                    onChange={handleChange}
                                                />
                                                {touched.District && errors.District && (
                                                    <div className="invalid-feedback">{errors.District}</div>
                                                )}
                                            </Col>
                                            <Col md="4" className="mb-3">
                                                <Label htmlFor="PostalCode">{"الرمز البريدي:"}</Label>
                                                <Input
                                                    className={`form-control ${touched.PostalCode && errors.PostalCode ? 'is-invalid' : touched.PostalCode ? 'is-valid' : ''}`}
                                                    name="PostalCode"
                                                    type="number"
                                                    placeholder="الرمز البريدي"
                                                    value={values.PostalCode}
                                                    onChange={handleChange}
                                                />
                                                {touched.PostalCode && errors.PostalCode && (
                                                    <div className="invalid-feedback">{errors.PostalCode}</div>
                                                )}
                                            </Col>
                                            <Col md="4" className="mb-3">
                                                <Label htmlFor="AdditionalNumber">{"الرقم الإضافي:"}</Label>
                                                <Input
                                                    className={`form-control ${touched.AdditionalNumber && errors.AdditionalNumber ? 'is-invalid' : touched.AdditionalNumber ? 'is-valid' : ''}`}
                                                    name="AdditionalNumber"
                                                    type="number"
                                                    placeholder="الرقم الإضافي"
                                                    value={values.AdditionalNumber}
                                                    onChange={handleChange}
                                                />
                                                {touched.AdditionalNumber && errors.AdditionalNumber && (
                                                    <div className="invalid-feedback">{errors.AdditionalNumber}</div>
                                                )}
                                            </Col>
                                            <Col md="4" className="mb-3">
                                                <Label htmlFor="UnitNumber">{"رقم الوحدة:"}</Label>
                                                <Input
                                                    className={`form-control ${touched.UnitNumber && errors.UnitNumber ? 'is-invalid' : touched.UnitNumber ? 'is-valid' : ''}`}
                                                    name="UnitNumber"
                                                    type="number"
                                                    placeholder="رقم الوحدة"
                                                    value={values.UnitNumber}
                                                    onChange={handleChange}
                                                />
                                                {touched.UnitNumber && errors.UnitNumber && (
                                                    <div className="invalid-feedback">{errors.UnitNumber}</div>
                                                )}
                                            </Col>
                                        </Row>
                                        {isError && <Alert color="danger">{errorMessage}</Alert>}
                                        <Button type="submit" color="primary" disabled={isLoading}>
                                            {isLoading ? <Spinner size="sm" /> : 'اضافة عنوان'}
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default AddCustomerAddressForm;