// components/PostsGrid.js

import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Card } from 'reactstrap';
import PostModal from './PostModal';
import { Btn, H4, Image, LI, P, UL } from "../../AbstractElements";
import { useGetPostsQuery } from '../../redux/apis/apiSlice'; // تأكد من مسار الاستيراد الصحيح

const PostsGrid = () => {
    // حالة إدارة الـ Modal
    const [dataId, setDataId] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    // إدارة الباجينيشن والفلاتر
    const [filters, setFilters] = useState({
        userId: '',
        category: '',
        slug: '',
        postId: '',
        searchTerm: '',
        page: 1,
        limit: 9,
        order: 'desc',
    });

    // استخدام الـ Hook لجلب البيانات من السيرفر
    const { data:getPostsData, error:getPostsError, isLoading:isLoadingPosts, isFetching:isFetchingPosts } = useGetPostsQuery(filters);


    // دالة لمعالجة فتح الـ Modal
    const onClickHandle = (id) => {
        setDataId(id);
        setOpenModal(true);
    };

    // دالة لمعالجة إغلاق الـ Modal
    const closeModal = () => {
        setOpenModal(false);
        setDataId(null);
    };

    // دالة لتغيير الصفحة
    const handlePageChange = (newPage) => {
        setFilters((prev) => ({ ...prev, page: newPage }));
    };

    // دالة لتغيير الفلاتر (يمكنك تعديلها حسب الحاجة)
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value, page: 1 })); // إعادة تعيين الصفحة عند تغيير الفلاتر
    };

    // دالة مساعدة لتحديد فئة الشريط (ribbon) بناءً على حالة المنشور
    const getRibbonClass = (status) => {
        switch (status.toLowerCase()) {
            case 'sale':
                return 'danger';
            case '50%':
                return 'success';
            case 'gift':
                return 'secondary';
            case 'love':
                return 'info';
            case 'hot':
                return 'warning';
            default:
                return 'primary';
        }
    };

    // تحديد baseURL إذا كانت الصور تأتي بمسارات نسبية
    const baseURL = process.env.REACT_APP_URL_BACKEND // تأكد من ضبط الـ baseURL بشكل صحيح

    return (
        <Fragment>
            <div className="filter-section">
                {/* مثال على عناصر الفلترة */}
                <input
                    type="text"
                    name="searchTerm"
                    value={filters.searchTerm}
                    onChange={handleFilterChange}
                    placeholder="بحث..."
                />
                <select name="category" value={filters.category} onChange={handleFilterChange}>
                    <option value="">اختر الفئة</option>
                    <option value="Technology">التكنولوجيا</option>
                    <option value="Health">الصحة</option>
                    <option value="Business">الأعمال</option>
                    {/* أضف المزيد من الخيارات حسب الحاجة */}
                </select>
                {/* أضف المزيد من عناصر الفلترة هنا */}
            </div>

            <div className="product-wrapper-grid">
                {isLoadingPosts || isFetchingPosts ? (
                    <div>جارٍ التحميل...</div>
                ) : getPostsError ? (
                    <div>حدث خطأ أثناء جلب البيانات.</div>
                ) : getPostsData?.posts?.length === 0 ? (
                    <div>لا توجد منشورات لعرضها.</div>
                ) : (
                    <Row className="gridRow">
                        {getPostsData.posts.map((post) => (
                            <div
                                id="gridId"
                                className="col-xl-4 col-lg-6 col-sm-6 xl-4"
                                key={post._id}
                            >
                                <Card>
                                    <div className="product-box">
                                        <div className="product-img">
                                            {/* عرض حالة المنشور إذا كانت موجودة */}
                                            {post.status && (
                                                <span className={`ribbon ribbon-${getRibbonClass(post.status)}`}>
                                                    {post.status}
                                                </span>
                                            )}
                                            <Image
                                                attrImage={{
                                                    className: 'img-fluid',
                                                    src: post.images && post.images.length > 0
                                                        ? `${baseURL}${process.env.REACT_APP_UPLOAD_DIR || 'uploadImageDevlopment'}/posts/${post.images[0].filename}`
                                                        : '/images/default.jpg', // مسار صورة افتراضية إذا لم تتوفر الصور
                                                    alt: post.title,
                                                }}
                                            />
                                            <div className="product-hover">
                                                <UL attrUL={{ className: 'simple-list d-flex flex-row' }}>
                                                    <LI>
                                                        <Btn
                                                            attrBtn={{
                                                                color: 'transprant',
                                                                className: 'btn',
                                                                type: 'button',
                                                                onClick: () => onClickHandle(post._id),
                                                                datatoggle: 'modal',
                                                                datatarget: '#exampleModalCenter',
                                                            }}
                                                        >
                                                            <i className="icon-eye"></i>
                                                        </Btn>
                                                    </LI>
                                                    {/* إذا كنت تريد إضافة زر "إضافة إلى السلة"، يمكنك إضافته هنا */}
                                                </UL>
                                            </div>
                                        </div>
                                        <div className="product-details">
                                            <Link to={`/posts/${post.slug}`}>
                                                <H4>{post.title}</H4>
                                            </Link>
                                            <div className="rating">
                                                {/* يمكنك تعديل نظام التقييم حسب بياناتك */}
                                                {[...Array(5)].map((star, index) => (
                                                    <i key={index} className="fa fa-star font-warning"></i>
                                                ))}
                                            </div>
                                            {/*<P>{post.content.substring(0, 100)}...</P>*/}
                                            <div className="product-price">
                                                {/* عرض السعر إذا كان موجودًا */}
                                                {post.price && `${post.price} ريال`}
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        ))}
                        {/* عرض الـ Modal إذا كانت الحالة مفتوحة */}
                        {openModal && <PostModal value={openModal} setOpenModal={setOpenModal} dataid={dataId} />}
                    </Row>
                )}
            </div>

            {/* قسم الباجينيشن */}
            {getPostsData && getPostsData.pagination.totalPages > 1 && (
                <div className="pagination-wrapper">
                    <button
                        onClick={() => handlePageChange(filters.page - 1)}
                        disabled={filters.page === 1}
                    >
                        الصفحة السابقة
                    </button>
                    <span>
                        الصفحة {filters.page} من {getPostsData.pagination.totalPages}
                    </span>
                    <button
                        onClick={() => handlePageChange(filters.page + 1)}
                        disabled={filters.page === getPostsData.pagination.totalPages}
                    >
                        الصفحة التالية
                    </button>
                </div>
            )}
        </Fragment>
    );
};

export default PostsGrid;