import Breadcrumbs from '../../CommonElements/Breadcrumbs';
import React, { Fragment } from 'react';
import EmployeesCardssContain from "../../Component/Employees/EmployeesCardComponents";

const UsersCardss = () => {
    return (
        <Fragment>
            <div className=" page-header dash-breadcrumb">
                <Breadcrumbs parent={"Employees"} title={"All Employees"} />
            </div>
            <EmployeesCardssContain />
        </Fragment>
    );
};
export default UsersCardss;