// components/Gallery.js

import React, { useState } from 'react';
import {
    useGetAllCategoriesQuery,
    useGetGalleryByCategoryQuery,

} from '../../redux/apis/apiSlice';
import { Container, Row, Col, Card, Spinner, Alert, Modal, Dropdown } from 'react-bootstrap';

const Gallery = () => {
    // جلب جميع الفئات
    const { data: categoriesData, error: categoriesError, isLoading: categoriesLoading } = useGetAllCategoriesQuery();

    // حالة الفئة المحددة
    const [selectedCategory, setSelectedCategory] = useState(null);

    // جلب المعارض بناءً على الفئة المحددة
    const { data: galleryData, error: galleryError, isLoading: galleryLoading } = useGetGalleryByCategoryQuery(selectedCategory, {
        skip: !selectedCategory, // تخطي الاستعلام إذا لم يتم اختيار فئة
    });

    // جلب جميع المعارض إذا لم يتم اختيار فئة
    const { data: allGalleryData, error: allGalleryError, isLoading: allGalleryLoading } = useGetGalleryByCategoryQuery({
        skip: selectedCategory, // تخطي إذا تم اختيار فئة
    });

    console.log("allGalleryData:",categoriesData)
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = (image) => {
        setSelectedImage(image);
        setShow(true);
    };

    // التعامل مع تحميل الفئات
    if (categoriesLoading) {
        return (
            <Container className="text-center my-5">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">جاري التحميل...</span>
                </Spinner>
            </Container>
        );
    }

    if (categoriesError) {
        return (
            <Container className="my-5">
                <Alert variant="danger">
                    حدث خطأ أثناء جلب الفئات. يرجى المحاولة لاحقًا.
                </Alert>
            </Container>
        );
    }

    // التعامل مع تحميل المعارض
    if ((selectedCategory && galleryLoading) || (!selectedCategory && allGalleryLoading)) {
        return (
            <Container className="text-center my-5">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">جاري التحميل...</span>
                </Spinner>
            </Container>
        );
    }

    if ((selectedCategory && galleryError) || (!selectedCategory && allGalleryError)) {
        return (
            <Container className="my-5">
                <Alert variant="danger">
                    حدث خطأ أثناء جلب الصور. يرجى المحاولة لاحقًا.
                </Alert>
            </Container>
        );
    }

    // تحديد البيانات التي سيتم عرضها
    const displayData = selectedCategory ? galleryData?.data : allGalleryData?.data;

    return (
        <Container className="my-5">
            {/* قائمة الفئات */}
            <Dropdown className="mb-4">
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {selectedCategory ? 'تغيير الفئة' : 'اختر الفئة'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {categoriesData?.data.map((category) => (
                        <Dropdown.Item
                            key={category._id}
                            onClick={() => setSelectedCategory(category._id)}
                        >
                            {category.name}
                        </Dropdown.Item>
                    ))}
                    <Dropdown.Item onClick={() => setSelectedCategory(null)}>جميع الفئات</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            {/* عرض المعارض */}
            {displayData.map((gallery) => (
                <div key={gallery._id} className="mb-5">
                    <h3 className="mb-4">{gallery.category.name}</h3>
                    <Row xs={1} sm={2} md={3} lg={4} className="g-4">
                        {gallery.images.map((image) => (
                            <Col key={image._id}>
                                <Card className="h-100" onClick={() => handleShow(image)} style={{ cursor: 'pointer' }}>
                                    <Card.Img
                                        variant="top"
                                        src={image.path} // تأكد من المسار الصحيح
                                        alt={image.title}
                                        style={{ objectFit: 'cover', height: '200px' }}
                                    />
                                    <Card.Body>
                                        <Card.Title>{image.title}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            ))}

            {/* Modal لعرض الصورة الكبيرة */}
            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{selectedImage?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {selectedImage && (
                        <img
                            src={`${selectedImage.path}`}
                            alt={selectedImage.title}
                            style={{ maxWidth: '100%', maxHeight: '80vh' }}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default Gallery;