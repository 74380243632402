// src/components/YearRevenueCard.js
import React from "react";
import CountUp from "react-countup";
import { Card, CardBody, Col } from "reactstrap";
import { useGetTotalPaymentsByYearQuery } from "../../redux/apis/apiSlice";

const YearRevenueCard = ({ employeeId, year }) => {
    // يمكنك تخصيص ألوان الخلفية لبطاقات الإيرادات حسب السنة باستخدام قائمة ألوان أو أي منطق آخر
    const colors = ["#28a745", "#007bff", "#6f42c1", "#fd7e14", "#17a2b8"];
    // على سبيل المثال: أخذ لون من القائمة بناءً على رقم السنة (أو ترتيبها)
    const bgColor = colors[parseInt(year, 10) % colors.length] || "#007bff";

    const { data, isLoading, error } = useGetTotalPaymentsByYearQuery({ employeeId, year });

    return (
        <Col sm="6" xl="3" lg="6" className="mb-4">
            <Card style={{ backgroundColor: bgColor, color: "#fff" }}>
                <CardBody>
                    <h5>{year}</h5>
                    {isLoading ? (
                        <div>جاري التحميل...</div>
                    ) : error ? (
                        <div>حدث خطأ</div>
                    ) : (
                        <div>
                            <CountUp end={data?.data?.totalPayments || 0} separator="," decimals={0} />
                        </div>
                    )}
                </CardBody>
            </Card>
        </Col>
    );
};

export default YearRevenueCard;