import React, { Fragment, useContext } from 'react';
import { Container } from 'reactstrap';
import FilterContext from '../../_helper/ecommerce/filter';
import PostFeatures from './PostFeatures';
import PostGrid from "./PostGrid";

const ProductContain = () => {
    const { IsFilter } = useContext(FilterContext);
    return (
        <Fragment>
            <Container fluid={true} className={`product-wrapper ${IsFilter ? 'sidebaron' : ''}`}>
                <div className="product-grid">
                    <PostFeatures />
                    <PostGrid />
                </div>
            </Container>
        </Fragment>
    );
};
export default ProductContain;