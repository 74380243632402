// redux/apis/endpoints/addGalleryCategory.js

export const addGalleryCategoryEndpoint = (builder) => ({
  addGalleryCategory: builder.mutation({
    query: (category) => ({
      url: `api/gallery-category/add-category`, // تغيير المسار إلى المسار الخاص بإضافة الفئة
      method: "POST",
      body: category,  // إرسال الجسم مباشرة بدون تجميع تحت حقل 'category'
    }),
    invalidatesTags: [{ type: "Gallery", id: "LIST" }], // تعديل التاج حسب الحاجة
  }),
});