// import React from 'react';
// import { Navigate, Outlet } from 'react-router-dom';
// import {useSelector} from "react-redux";
//
// const PrivateRoutes = () => {
//    const { currentUser } = useSelector((state) => state.user);
//
//    // const login = JSON.parse(localStorage.getItem("login")) || false;
//    const login = currentUser.data.role === 'superAdmin'
//    return login !== false ? <Outlet/> : <Navigate exact to={`${process.env.PUBLIC_URL}/login`}/>
// }
// export default PrivateRoutes;
//


// PrivateRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector} from "react-redux";


const PrivateRoute = ({ roles }) => {

   const { currentUser } = useSelector((state) => state.user);

   if (!currentUser?.token) {
      // غير مسجل الدخول
      return <Navigate to={`${process.env.PUBLIC_URL}/login`} replace />;
   }

   const userRole = currentUser?.data?.role;

   if (roles && roles.length && !roles.includes(userRole)) {
      // ليس لديه الدور المناسب
      return <Navigate to={`${process.env.PUBLIC_URL}/unauthorized`} replace />;
   }

   // يملك الدور المناسب
   return <Outlet />;
}

export default PrivateRoute;