import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import React, { Fragment } from "react";
import DataTablesContain from "../../Component/Tables/DataTable";
import HorizontalLayout from "../../Component/Forms/Form Layout/Form Default/HorizontalLayout";
import { Card, CardHeader, Col, Row } from "reactstrap";
import { H5 } from "../../AbstractElements";
import { HorizontalFormLayout } from "../../Constant";
const IncomingInvoice = () => {
  return (
    <Fragment>
      <div className=" page-header dash-breadcrumb">
        <Breadcrumbs parent="المشتريات" title="قائمة المشتريات" />
      </div>
      <DataTablesContain />
      <Row>
        <Col sm="12" xl="6">
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="pb-0">
                  <H5>{HorizontalFormLayout}</H5>
                </CardHeader>
                <HorizontalLayout />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col sm="12" xl="6">
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="pb-0">
                  <H5>{"قيد الانشاء"}</H5>
                </CardHeader>
                {/*<InLineForm />*/}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};
export default IncomingInvoice;
