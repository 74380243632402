// src/components/SinkForm.js
import React, { useState } from 'react';
import {
    Button, Form, FormGroup, Label, Input, FormFeedback, Alert, Col, Row
} from 'reactstrap';
import { useAddSinkMutation } from "../../../redux/apis/apiSlice";
import { toast } from "react-toastify";

const SinkForm = ({ onSuccess }) => {
    const [formData, setFormData] = useState({
        title: '',
        marbleUsed: '',
        marbleColor: '',
        sinkType: '',
        woodType: '',
        mirrorType: '',
        size: '',
        quantity: '',
        price: '',
        status: 'Completed',
        image: '' // حقل الصورة
    });

    const [errors, setErrors] = useState({});
    const [submitError, setSubmitError] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState('');

    const [addSink, { isLoading }] = useAddSinkMutation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // إزالة الخطأ عند تعديل الحقل
        if (errors[name]) {
            setErrors({ ...errors, [name]: null });
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, image: file });

        // إزالة الخطأ عند اختيار صورة
        if (errors.image) {
            setErrors({ ...errors, image: null });
        }
    };

    const validate = () => {
        const newErrors = {};

        Object.keys(formData).forEach(field => {
            if (!formData[field] && field !== 'image') { // جعل جميع الحقول مطلوبة باستثناء الصورة
                newErrors[field] = 'هذا الحقل مطلوب';
            }
        });

        // التحقق من صحة حقل status
        const validStatuses = ['Completed', 'Incomplete', 'Design'];
        if (formData.status && !validStatuses.includes(formData.status)) {
            newErrors.status = 'الحالة المحددة غير صالحة';
        }

        // التحقق من أن الكمية والسعر أعداد صحيحة
        if (formData.quantity && isNaN(formData.quantity)) {
            newErrors.quantity = 'الكمية يجب أن تكون رقمًا';
        } else if (formData.quantity) {
            const quantity = Number(formData.quantity);
            if (quantity < 1 || quantity > 5) {
                newErrors.quantity = 'الكمية يجب أن تكون بين 1 و5';
            }
        }

        if (formData.price && isNaN(formData.price)) {
            newErrors.price = 'السعر يجب أن يكون رقمًا';
        }


        if (!formData.image) {
            newErrors.image = 'الصورة مطلوبة';
        }

        return newErrors;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitError('');
        setSubmitSuccess('');

        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            // إنشاء كائن FormData وإرفاق جميع الحقول
            const data = new FormData();
            data.append('title', formData.title);
            data.append('marbleUsed', formData.marbleUsed);
            data.append('marbleColor', formData.marbleColor);
            data.append('sinkType', formData.sinkType);
            data.append('woodType', formData.woodType);
            data.append('mirrorType', formData.mirrorType);
            data.append('size', formData.size);
            data.append('quantity', formData.quantity);
            data.append('price', formData.price);
            data.append('status', formData.status);
            data.append('image', formData.image); // التأكد من إضافة الصورة دائمًا

            await addSink(data).unwrap();
            setSubmitSuccess('تم إنشاء Sink بنجاح!');
            setFormData({
                title: '',
                marbleUsed: '',
                marbleColor: '',
                sinkType: '',
                woodType: '',
                mirrorType: '',
                size: '',
                quantity: '',
                price: '',
                status: 'Completed',
                image: ''
            });
            if (onSuccess) {
                onSuccess(); // لتنبيه المكون الأب لتحديث القائمة
            }
        } catch (error) {
            toast.error(error?.data?.message || 'فشل في إنشاء Sink. حاول مرة أخرى.');
            setSubmitError('فشل في إنشاء Sink. حاول مرة أخرى.');
            console.error(error);
        }
    };

    return (
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <h3 className="text-center">إنشاء  بطاقة جديدة</h3>
            {submitError && <Alert color="danger">{submitError}</Alert>}
            {submitSuccess && <Alert color="success">{submitSuccess}</Alert>}

            <Row>
                <Col md={{ size: 8, offset: 2 }}>
                    <FormGroup>
                        <Label for="marbleUsed">العنوان:</Label>
                        <Input
                            type="text"
                            name="title"
                            id="title"
                            value={formData.title}
                            onChange={handleChange}
                            invalid={!!errors.title}
                            required
                        />
                        {errors.title && <FormFeedback>{errors.title}</FormFeedback>}
                    </FormGroup>
                    <FormGroup>
                        <Label for="marbleUsed">نوع الرخام:</Label>
                        <Input
                            type="text"
                            name="marbleUsed"
                            id="marbleUsed"
                            value={formData.marbleUsed}
                            onChange={handleChange}
                            invalid={!!errors.marbleUsed}
                            required
                        />
                        {errors.marbleUsed && <FormFeedback>{errors.marbleUsed}</FormFeedback>}
                    </FormGroup>

                    <FormGroup>
                        <Label for="marbleColor">لون الرخام:</Label>
                        <Input
                            type="text"
                            name="marbleColor"
                            id="marbleColor"
                            value={formData.marbleColor}
                            onChange={handleChange}
                            invalid={!!errors.marbleColor}
                            required
                        />
                        {errors.marbleColor && <FormFeedback>{errors.marbleColor}</FormFeedback>}
                    </FormGroup>

                    <FormGroup>
                        <Label for="sinkType">نوع الحوض:</Label>
                        <Input
                            type="text"
                            name="sinkType"
                            id="sinkType"
                            value={formData.sinkType}
                            onChange={handleChange}
                            invalid={!!errors.sinkType}
                            required
                        />
                        {errors.sinkType && <FormFeedback>{errors.sinkType}</FormFeedback>}
                    </FormGroup>

                    <FormGroup>
                        <Label for="woodType">نوع الخشب:</Label>
                        <Input
                            type="text"
                            name="woodType"
                            id="woodType"
                            value={formData.woodType}
                            onChange={handleChange}
                            invalid={!!errors.woodType}
                            required
                        />
                        {errors.woodType && <FormFeedback>{errors.woodType}</FormFeedback>}
                    </FormGroup>

                    <FormGroup>
                        <Label for="mirrorType">نوع المرايا:</Label>
                        <Input
                            type="text"
                            name="mirrorType"
                            id="mirrorType"
                            value={formData.mirrorType}
                            onChange={handleChange}
                            invalid={!!errors.mirrorType}
                            required
                        />
                        {errors.mirrorType && <FormFeedback>{errors.mirrorType}</FormFeedback>}
                    </FormGroup>

                    <FormGroup>
                        <Label for="size">المقاس:</Label>
                        <Input
                            type="text"
                            name="size"
                            id="size"
                            value={formData.size}
                            onChange={handleChange}
                            invalid={!!errors.size}
                            required
                        />
                        {errors.size && <FormFeedback>{errors.size}</FormFeedback>}
                    </FormGroup>

                    <FormGroup>
                        <Label for="quantity">التقييم</Label>
                        <Input
                            type="number"
                            name="quantity"
                            id="quantity"
                            value={formData.quantity}
                            onChange={handleChange}
                            invalid={!!errors.quantity}
                            required
                        />
                        {errors.quantity && <FormFeedback>{errors.quantity}</FormFeedback>}
                    </FormGroup>

                    <FormGroup>
                        <Label for="price">السعر التقريبي: (SAR)</Label>
                        <Input
                            type="number"
                            name="price"
                            id="price"
                            value={formData.price}
                            onChange={handleChange}
                            invalid={!!errors.price}
                            required
                        />
                        {errors.price && <FormFeedback>{errors.price}</FormFeedback>}
                    </FormGroup>

                    <FormGroup>
                        <Label for="status">الحالة:</Label>
                        <Input
                            type="select"
                            name="status"
                            id="status"
                            value={formData.status}
                            onChange={handleChange}
                            invalid={!!errors.status}
                            required
                        >
                            <option value="Completed">مكتملة</option>
                            <option value="Incomplete">غير مكتملة</option>
                            <option value="Design">تصميم</option>
                        </Input>
                        {errors.status && <FormFeedback>{errors.status}</FormFeedback>}
                    </FormGroup>

                    {/* حقل الصورة */}
                    <FormGroup>
                        <Label for="image">الصورة:</Label>
                        <Input
                            type="file"
                            name="image"
                            id="image"
                            accept="image/*"
                            onChange={handleImageChange}
                            invalid={!!errors.image}
                            required // جعل الحقل مطلوبًا
                        />
                        {errors.image && <FormFeedback>{errors.image}</FormFeedback>}
                    </FormGroup>

                    <Button color="primary" type="submit" disabled={isLoading} block>
                        {isLoading ? 'Creating...' : 'Create Sink'}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default SinkForm;