// redux/apis/endpoints/signUp.js
export const signUpEndpoint = (builder) => ({
    signUp: builder.mutation({
        query: (values) => ({
            url: 'api/auth/signup',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: values,
        }),
    }),
});