import React, { useState } from 'react';
import {
    Card, CardBody, CardTitle, CardText, CardFooter, Badge, CardImg, Button, Form, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { useUpdateSinkMutation, useDeleteSinkMutation } from "../../../redux/apis/apiSlice";
import {toast} from "react-toastify";

const SinkCard = ({ sink }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedSink, setEditedSink] = useState(sink);
    const [file, setFile] = useState(null);
    const [updateSink, { isLoading, isError }] = useUpdateSinkMutation();
    const [deleteSink, { isLoading: isDeleting }] = useDeleteSinkMutation();

    // حالة لفتح وإغلاق الـ modal
    const [modalOpen, setModalOpen] = useState(false);

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedSink({
            ...editedSink,
            [name]: value
        });
    };


    const handleSave = async () => {
        const formData = new FormData();
        formData.append('title', editedSink.title);
        formData.append('marbleUsed', editedSink.marbleUsed);
        formData.append('marbleColor', editedSink.marbleColor);
        formData.append('sinkType', editedSink.sinkType);
        formData.append('woodType', editedSink.woodType);
        formData.append('mirrorType', editedSink.mirrorType);
        formData.append('size', editedSink.size);
        formData.append('quantity', editedSink.quantity);
        formData.append('price', editedSink.price);

        if (file) {
            formData.append('image', file);
        }

        try {
            await updateSink({ id: sink._id, body: formData }).unwrap();
            setIsEditing(false);
        } catch (error) {
            console.error("Failed to update sink:", error);
        }
    };

    const handleDelete = async () => {
        try {
            await deleteSink(sink._id).unwrap();
            setModalOpen(false); // إغلاق الـ modal بعد الحذف
            toast.success('تم حذف البطاقة بنجاح');

        } catch (error) {
            console.error("Failed to delete sink:", error);
        }
    };

    const imageSrc = sink.image;

    return (
        <Card className="mb-4">
            <CardImg
                top
                width="100%"
                src={imageSrc}
                alt="Sink image"
            />
            <CardBody>
                <CardTitle tag="h5">Sink ID: {sink._id}</CardTitle>

                {isEditing ? (
                    <Form>
                        {/* باقي الحقول */}
                        <FormGroup>
                            <Input
                                type="text"
                                name="title"
                                value={editedSink.title}
                                onChange={handleChange}
                                placeholder="العنوان"
                            />
                        </FormGroup>
                        {/* المزيد من الحقول */}
                        <Button onClick={handleSave} color="primary" disabled={isLoading}>حفظ</Button>
                        <Button onClick={handleEditClick} color="secondary" className="ml-2">إلغاء</Button>
                    </Form>
                ) : (
                    <CardText>
                        <strong>العنوان:</strong> {sink.title}<br />
                        <strong>نوع الرخام:</strong> {sink.marbleUsed}<br />
                        <strong>لون الرخام:</strong> {sink.marbleColor}<br />
                        <strong>نوع الحوض:</strong> {sink.sinkType}<br />
                        <strong>نوع الخشب:</strong> {sink.woodType}<br />
                        <strong>نوع المرآة:</strong> {sink.mirrorType}<br />
                        <strong>الحجم:</strong> {sink.size}<br />
                        <strong>الكمية:</strong> {sink.quantity}<br />
                        <strong>السعر:</strong> {sink.price} SAR<br />
                    </CardText>
                )}

                <Button onClick={handleEditClick} color="secondary" className="mb-2">
                    {isEditing ? 'إلغاء' : 'تعديل'}
                </Button>

                {/* زر الحذف */}
                <Button onClick={() => setModalOpen(true) } color="danger" className="ml-2">حذف</Button>

                {/* نافذة التأكيد للحذف */}
                <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
                    <ModalHeader toggle={() => setModalOpen(!modalOpen)}>تأكيد الحذف</ModalHeader>
                    <ModalBody>
                        هل أنت متأكد من أنك تريد حذف هذه البطاقة؟
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setModalOpen(false)}>إلغاء</Button>
                        <Button color="danger" onClick={handleDelete} disabled={isDeleting}>تأكيد الحذف</Button>
                    </ModalFooter>
                </Modal>
            </CardBody>
            <CardFooter>
                <Badge color={
                    sink.status === 'Completed' ? 'success' :
                        sink.status === 'Incomplete' ? 'warning' :
                            'info'
                }>
                    {sink.status === 'Completed' ? 'مكتملة' : (sink.status === 'Incomplete' ? 'غير مكتملة' : 'تصميم')}
                </Badge>
            </CardFooter>
        </Card>
    );
};

export default SinkCard;