import ProfileHeader from "./ProfileHeader";
import { Card, Col, Container, Row } from "reactstrap";
import React, { Fragment } from "react";
import { H5 } from "../../../AbstractElements";
import Earnings from "../../Widgets/General/Earnings";
import { Outlet, useParams } from "react-router-dom";
import GetFawateerByEmployee from "./GetFawaterByEmployee/GetFawateerByEmployee";
import GetPaymentByEmployeeComponent from "./GetPaymentForEmployee/GetPaymentByEmployeeComponent";

const UsersProfileContain = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <ProfileHeader />
            <Earnings />
            <Row>
              <Col sm="12" xl="6">
                <Row>
                  <Col sm="12">
                    <Card>
                      <GetPaymentByEmployeeComponent />
                    </Card>
                  </Col>
                  <Col sm="12"></Col>
                </Row>
              </Col>
              <Col sm="12" xl="6">
                <Row>
                  <Col sm="12">
                    <Card>
                      <GetFawateerByEmployee />
                    </Card>
                  </Col>
                  <Col xl="12">
                    <Outlet />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
export default UsersProfileContain;
