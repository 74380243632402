import React, { Fragment } from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import TableHeadOptionsClass from "../../Component/Tables/Reactstrap/StylingTable/TableHeadOptions";
import { MegaForm } from "../../Constant";
import { H5 } from "../../AbstractElements";

import MegaForms from "../../Component/Forms/Form Layout/Form Default/MegaForm";

const PaymentsPage = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <TableHeadOptionsClass />
        <Row>
          <Col sm="12" xl="6">
            <Row>
              <Col sm="12">
                <Card>
                  <CardHeader className="pb-0">
                    <H5>{MegaForm}</H5>
                  </CardHeader>
                  <MegaForms />
                </Card>
              </Col>
              <Col xl="12"></Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PaymentsPage;
