import { SelectDateWithTime, CustomDateFormat, TodayButton, DisableDaysOfWeek, InlineVersion, DisableDatepicker, SelectTimeOnly, Default } from '../../../../Constant';
import ChildDatepicker from './ChildDatepicker';
import React, { Fragment, useState } from 'react';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';

const DatePickersData = () => {
  const [startDate, setstartDate] = useState(new Date());
  const handleChange = date => {
    setstartDate(date);
  };
  return (
    <Fragment>
      <Form className="theme-form">
        <Row className="mb-3">
          <Label className="col-sm-3 col-form-label text-end">{Default}</Label>
          <Col xl="5" sm="9">
            <div className="input-group">
              <DatePicker className="form-control" selected={startDate} onChange={handleChange} />
            </div>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};
export default DatePickersData;