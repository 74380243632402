import React, { useState, useCallback } from 'react';
import { Alert, Button, Col, Container, Row, Spinner, Form, Image, ProgressBar } from "react-bootstrap";
import { useGetAllCategoriesQuery, useUploadGalleryImagesMutation } from "../../redux/apis/apiSlice";
import { useDropzone } from 'react-dropzone';

const ImageUploadComponent = () => {
    // جلب الفئات باستخدام RTK Query
    const { data: categories, error, isLoading } = useGetAllCategoriesQuery();
    // تعريف الـ Mutation لرفع الصور
    const [uploadGalleryImages, { isLoading: isUploading, isError, error: uploadError }] = useUploadGalleryImagesMutation();

    // إدارة حالة النموذج
    const [category, setCategory] = useState('');
    const [images, setImages] = useState([]); // تخزين الصور كمصفوفة
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0); // لإظهار تقدم الرفع

    // التعامل مع السحب والإفلات
    const onDrop = useCallback((acceptedFiles) => {
        // التحقق من عدد الصور المرفوعة (تجاوز 50)
        if (images.length + acceptedFiles.length > 1000) { // حدد حدًا أعلى حسب الحاجة
            setErrorMessage('لا يمكن رفع أكثر من 1000 صورة في المرة الواحدة.');
            return;
        }

        // تحويل الملفات إلى كائنات تحتوي على ملف وعنوان
        const newImages = acceptedFiles.map(file => ({
            file,
            title: '',
            preview: URL.createObjectURL(file) // لإنشاء معاينة للصورة
        }));

        setImages(prevImages => [...prevImages, ...newImages]);
    }, [images]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: true,
        maxFiles: 1000, // تحديد الحد الأقصى للملفات
    });

    // التعامل مع تغيير العنوان
    const handleTitleChange = (index, event) => {
        const updatedImages = [...images];
        updatedImages[index].title = event.target.value;
        setImages(updatedImages);
    };

    // إزالة صورة من القائمة
    const removeImage = (index) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };

    // التعامل مع إرسال النموذج
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        // التحقق من وجود صورة واحدة على الأقل
        if (images.length < 1) {
            setErrorMessage('يجب رفع 1 صور على الأقل.');
            return;
        }

        // التحقق من ملء جميع الحقول
        for (let i = 0; i < images.length; i++) {
            if (!images[i].file ) {
                setErrorMessage('يرجى ملء جميع حقول الصور والعناوين.');
                return;
            }
        }

        // إنشاء FormData لإرسال البيانات
        const formData = new FormData();
        formData.append('category', category);
        images.forEach((image, index) => {
            formData.append('images', image.file);
            formData.append('titles', image.title);
        });

        try {
            // استخدام RTK Query لإرسال البيانات مع تتبع التقدم
            await uploadGalleryImages(formData).unwrap();
            setSuccessMessage('تم رفع الصور بنجاح.');
            // إعادة تعيين النموذج
            setImages([]);
            setCategory('');
        } catch (err) {
            setErrorMessage('حدث خطأ أثناء رفع الصور.');
            console.error('Upload Error:', err);
        }
    };

    // تنظيف المعاينات عند إلغاء الكمبوننت
    React.useEffect(() => {
        return () => {
            images.forEach(image => URL.revokeObjectURL(image.preview));
        };
    }, [images]);

    // عرض حالة التحميل أو الخطأ في جلب الفئات
    if (isLoading) return <div>جاري تحميل الفئات...</div>;
    if (error) return <div>حدث خطأ أثناء جلب الفئات.</div>;

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col md={10}>
                    <h2>رفع الصور</h2>
                    {/* عرض رسائل الخطأ والنجاح */}
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    {isError && <Alert variant="danger">{uploadError?.data?.message || 'حدث خطأ أثناء رفع الصور.'}</Alert>}
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}

                    {/* نموذج رفع الصور */}
                    <Form onSubmit={handleSubmit}>
                        {/* اختيار الفئة */}
                        <Form.Group controlId="formCategory">
                            <Form.Label>اختر الفئة</Form.Label>
                            <Form.Control
                                as="select"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                required
                            >
                                <option value="">اختر الفئة</option>
                                {categories?.data && categories.data.map((cat) => (
                                    <option key={cat._id} value={cat._id}>
                                        {cat.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        {/* منطقة السحب والإفلات */}
                        <div
                            {...getRootProps()}
                            className={`border p-4 text-center mb-3 ${isDragActive ? 'bg-light' : ''}`}
                            style={{ cursor: 'pointer', backgroundColor: isDragActive ? '#f8f9fa' : 'transparent' }}
                        >
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>أفلت الصور هنا...</p> :
                                    <p>اسحب وأفلت الصور هنا، أو انقر لاختيار الملفات</p>
                            }
                        </div>

                        {/* عرض الصور المرفوعة */}
                        {images.length > 0 && (
                            <div className="mb-3">
                                <h5>الصور المرفوعة ({images.length})</h5>
                                <Row>
                                    {images.map((image, index) => (
                                        <Col key={index} xs={6} md={3} lg={2} className="mb-3">
                                            <div className="position-relative">
                                                <Image src={image.preview} thumbnail />
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    className="position-absolute top-0 end-0"
                                                    onClick={() => removeImage(index)}
                                                >
                                                    &times;
                                                </Button>
                                            </div>
                                            <Form.Control
                                                type="text"
                                                placeholder="عنوان الصورة"
                                                value={image.title}
                                                onChange={(e) => handleTitleChange(index, e)}
                                                className="mt-2"
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        )}

                        {/* زر رفع الصور */}
                        <Button variant="primary" type="submit" disabled={isUploading}>
                            {isUploading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    {' '}جارٍ الرفع...
                                </>
                            ) : 'رفع الصور'}
                        </Button>

                        {/* شريط تقدم الرفع */}
                        {isUploading && (
                            <ProgressBar animated now={uploadProgress} label={`${uploadProgress}%`} className="mt-3" />
                        )}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default ImageUploadComponent;