import React, {createContext, useContext, useState} from 'react';

export const StateContext = createContext();

export const StateProvider = ({ children }) => {
    const [formData, setFormData] = useState(
        ()=> {
            const saved = localStorage.getItem('formData')
            return saved ? JSON.parse(saved) :
                {
                    title: "",
                    metaDesc: "",
                    firstH2: "",
                    firstParagraphForH2: "",
                    firstH3: "",
                    firstParagraphForH3: "",
                    imageCover: "",
                    category: "",
                    // isPublic: false,
                    images: [],
                    lastSection: "",
                }
        }

       )

    const [keyword, setKeyword] = useState(
        ()=> {
            const saved = localStorage.getItem('keyword')
            return saved ? JSON.parse(saved) : ''
        }
    )

    return (
        <StateContext.Provider value={{ formData, setFormData, keyword,setKeyword }}>
            {children}
        </StateContext.Provider>
    );
};

export const useAppContext = () => useContext(StateContext);