// src/redux/address/addressSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// تعريف إجراء غير متزامن لحفظ العنوان
export const saveAddress = createAsyncThunk(
    'address/saveAddress',
    async (addressData, { rejectWithValue }) => {
        try {
            // هنا يمكنك وضع منطق حفظ العنوان (مثل طلب API)
            // على سبيل المثال:
            // const response = await api.saveAddress(addressData);
            // return response.data;

            // لمحاكاة عملية غير متزامنة، سنستخدم setTimeout
            return new Promise((resolve) => {
                setTimeout(() => {
                    // يمكنك إضافة منطق للتحقق من صحة البيانات هنا
                    resolve(addressData);
                }, 200);
            });
        } catch (error) {
            // يمكنك تخصيص رسالة الخطأ هنا
            return rejectWithValue('فشل في حفظ العنوان. حاول مرة أخرى.');
        }
    }
);

const initialState = {
    address: {
        buildingNumber: '',
        street: '',
        District: '',
        city: '',
        postalCode: '',
        additionalInfo: '',
        neighborhood: '',
    },
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
};

const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        clearAddress(state) {
            state.address = initialState.address;
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.errorMessage = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveAddress.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
                state.errorMessage = '';
            })
            .addCase(saveAddress.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.address = { ...state.address, ...action.payload };
            })
            .addCase(saveAddress.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.errorMessage = action.payload || 'حدث خطأ غير متوقع';
            });
    },
});

export const { clearAddress } = addressSlice.actions;

export default addressSlice.reducer;