import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardFooter, CardHeader, Col } from 'reactstrap';
import { H3, H4, H6, LI, UL, Image } from '../../AbstractElements';
import {useGetEmployeesQuery} from "../../redux/apis/apiSlice";
import {PacmanLoader, PropagateLoader} from "react-spinners";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";

import employeeImage from '../../assets/images/employeeImage.jpg';
import {FaWhatsapp} from "react-icons/fa";


const AllCards = () => {
    const { currentUser } = useSelector((state) => state.user);
    const userId = currentUser.data._id
    const { data, error, isLoading } = useGetEmployeesQuery(userId);
    if (isLoading) {
        return (
            <Fragment>
                <PropagateLoader />
            </Fragment>
            )
    }
    if (error) {
        return toast.error(`حدث خطا اثناء جلب البيانات: ${error?.data?.message}`);
    }
    if (!data) {
        return <div>لا توجد بيانات متاحة.</div>;
    }
    const employees = data.data

    return (
        <Fragment>
            {employees.map((item) => {
                // تأكد من إزالة أي رموز غير رقمية من رقم الهاتف
                const formattedPhone = item.phone.replace(/\D/g, '');

                // بناء رابط واتساب ديناميكي
                const whatsappLink = `https://wa.me/${formattedPhone}`;

                return (
                    <Col md="6" xl="4" lg="6" className="box-col-6" key={item?._id}>
                        <Card className="custom-card">
                            <CardHeader className="p-0 card-header-relative">
                                <Image
                                    attrImage={{
                                        src: item.image || employeeImage,
                                        className: 'img-fluid w-100',
                                        alt: 'صورة الموظف'
                                    }}
                                />
                                <div className="employee-name-overlay">
                                    {item.name}
                                </div>
                            </CardHeader>
                            <div className="card-profile">
                                <Image
                                    attrImage={{
                                        src: item.image || employeeImage,
                                        className: 'rounded-circle',
                                        alt: 'صورة الموظف'
                                    }}
                                />
                            </div>

                            <UL attrUL={{ as: 'ul', className: 'simple-list card-social d-flex flex-row' }}>
                                <LI>
                                    <a
                                        href={whatsappLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaWhatsapp className="font-dark fs-1 text" />
                                    </a>
                                </LI>
                            </UL>

                            <div className="text-center profile-details">
                                <H4>
                                    <Link to={`${process.env.PUBLIC_URL}/employees/${item._id}`}>
                                        {item.name}
                                    </Link>
                                </H4>
                                <H6>{item?.phone}</H6>
                            </div>

                            <CardFooter className="row">
                                <Col sm="4" className="col-4">
                                    <H6>الراتب</H6>
                                    <H3 attrH3={{ className: 'counter' }}>{item.salary}</H3>
                                </Col>
                                <Col sm="4" className="col-4">
                                    <H6>المسمى الوظيفي</H6>
                                    <H3>
                                        <span className="counter">{item.jobTitle}</span>
                                    </H3>
                                </Col>
                                <Col sm="4" className="col-4">
                                    <H6>إجمالي الإرادات</H6>
                                    <H3>
                                        <span className="counter">{item.position}</span>
                                    </H3>
                                </Col>
                            </CardFooter>
                        </Card>
                    </Col>
                );
            })}
        </Fragment>
    );

};
export default AllCards;