import React, { Fragment, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { LI, UL } from '../../AbstractElements';
import { MENUITEMS } from './Menu';
import { useSelector } from "react-redux";

const SidebarMenuItems = ({ setMainMenu, sidebartoogle, setNavActive }) => {
  const { currentUser } = useSelector((state) => state.user);
  const { t } = useTranslation();

  // تعريف userRole بشكل صحيح باستخدام التحويل الآمن
  const userRole = currentUser?.data?.role;

  // حالة لتتبع القائمة النشطة
  const [active, setActive] = useState(null);

  // دالة للتحقق من الأدوار (تتعامل مع مصفوفة الأدوار أو دور واحد)
  const hasRole = (itemRoles, userRoles) => {
    if (!itemRoles) return true; // العناصر بدون خاصية 'roles' متاحة للجميع
    if (Array.isArray(userRoles)) {
      return itemRoles.some(role => userRoles.includes(role));
    }
    return itemRoles.includes(userRoles);
  };

  // دالة لفلترة العناصر بناءً على الدور
  const filteredMenu = useMemo(() => {
    if (!userRole) {
      return [];
    }
    const userRoles = Array.isArray(userRole) ? userRole : [userRole];
    const filtered = MENUITEMS.map(menu => {
      const filteredItems = menu.Items.filter(item => {
        return hasRole(item.roles, userRoles);
      });
      return { ...menu, Items: filteredItems };
    }).filter(menu => menu.Items.length > 0); // إزالة الأقسام التي أصبحت بدون عناصر

    console.log('Filtered Menu:', filtered);
    console.log('User Role:', userRoles);

    return filtered;
  }, [userRole]);

  // دالة للتعامل مع النقر على القائمة الفرعية
  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector('.page-header').className =
          'page-header close_icon';
      document.querySelector('.main-nav').className =
          'main-nav close_icon ';
      if (item.type === 'sub') {
        document.querySelector('.page-header').className = 'page-header ';
        document.querySelector('.main-nav').className =
            'main-nav ';
      }
    }
    if (!item.active) {
      MENUITEMS.forEach((menu) => {
        menu.Items.forEach((menuItem) => {
          if (menuItem !== item) {
            menuItem.active = false;
          }
          if (menuItem.children) {
            menuItem.children.forEach((child) => {
              if (child !== item) {
                child.active = false;
              }
              if (child.children) {
                child.children.forEach((subChild) => {
                  if (subChild !== item) {
                    subChild.active = false;
                  }
                });
              }
            });
          }
        });
      });
    }
    if (item.children) {
      item.active = !item.active;
    }
    setMainMenu({ mainmenu: MENUITEMS });
  };

  // التحقق من وجود currentUser و role
  if (!currentUser || !currentUser.data) {
    return null; // أو عرض مؤشر تحميل أو رسالة خطأ
  }

  return (
      <Fragment>
        <UL attrUL={{ className: 'simple-list nav-menu custom-scrollbar d-block' }}>
          <LI attrLI={{ className: 'back-btn' }}>
            <div className="mobile-back text-end">
              <span>Back</span><i className="fa fa-angle-right ps-2"></i>
            </div>
          </LI>
          {filteredMenu.map((Item, i) => (
              <Fragment key={i}>
                {Item.Items.map((menuItem, j) => (
                    <LI attrLI={{ className: 'dropdown' }} key={j}>
                      {hasRole(menuItem.roles, userRole) && (
                          <>
                            {/* نوع 'sub' */}
                            {menuItem.type === 'sub' && (
                                <a href="#javascript"
                                   className={`nav-link menu-title ${menuItem.active || active ? 'active' : ''}`}
                                   onClick={(event) => {
                                     event.preventDefault();
                                     toggletNavActive(menuItem);
                                   }} >
                                  {menuItem.icon && <menuItem.icon />}
                                  <span>{t(menuItem.title)}</span>
                                  <div className="according-menu">
                                    {menuItem.active ? (
                                        <i className="fa fa-caret-down"></i>
                                    ) : (
                                        <i className="fa fa-caret-right"></i>
                                    )}
                                  </div>
                                </a>
                            )}

                            {/* نوع 'link' */}
                            {menuItem.type === 'link' && (
                                <Link
                                    to={menuItem.path}
                                    className={`nav-link menu-title ${menuItem.active ? 'active' : ''}`}
                                    onClick={() => toggletNavActive(menuItem)}
                                >
                                  {menuItem.icon && <menuItem.icon />}
                                  <span>{t(menuItem.title)}</span>

                                  {menuItem.badge && (
                                      <Label className={menuItem.badge}>
                                        {menuItem.badgetxt}
                                      </Label>
                                  )}
                                </Link>
                            )}

                            {/* عناصر الأطفال */}
                            {menuItem.children && (
                                <UL attrUL={{
                                  className: 'simple-list nav-submenu',
                                  style: menuItem.active
                                      ? sidebartoogle
                                          ? {
                                            opacity: 1,
                                            transition: 'opacity 500ms ease-in',
                                          }
                                          : { display: 'block' }
                                      : { display: 'none' }
                                }}>
                                  {menuItem.children.map((childrenItem, index) => (
                                      <LI key={index}>
                                        {hasRole(childrenItem.roles, userRole) && (
                                            <>
                                              {childrenItem.type === 'sub' && (
                                                  <a href="#javascript" className={`${childrenItem.active ? 'active' : ''}`}
                                                     onClick={(event) => {
                                                       event.preventDefault();
                                                       toggletNavActive(childrenItem);
                                                     }}>
                                                    {t(childrenItem.title)}
                                                    <div className="according-menu">
                                                      {childrenItem.active ? (
                                                          <i className="fa fa-caret-down"></i>
                                                      ) : (
                                                          <i className="fa fa-caret-right"></i>
                                                      )}
                                                    </div>
                                                  </a>
                                              )}
                                              {childrenItem.type === 'link' && (
                                                  <Link
                                                      to={childrenItem.path} className={`${childrenItem.active ? 'active' : ''}`}
                                                      onClick={() => toggletNavActive(childrenItem)} >
                                                    {t(childrenItem.title)}
                                                  </Link>
                                              )}
                                              {childrenItem.children && (
                                                  <UL attrUL={{
                                                    className: 'simple-list nav-sub-childmenu submenu-content',
                                                    style: childrenItem.active
                                                        ? { display: 'block' }
                                                        : { display: 'none' }
                                                  }}>
                                                    {childrenItem.children.map((childrenSubItem, key) => (
                                                        <LI key={key}>
                                                          {childrenSubItem.type === 'link' && (
                                                              <Link
                                                                  to={childrenSubItem.path}
                                                                  className={`${childrenSubItem.active ? 'active' : ''}`}
                                                                  onClick={() => toggletNavActive(childrenSubItem)}>
                                                                {t(childrenSubItem.title)}
                                                              </Link>
                                                          )}
                                                        </LI>
                                                    ))}
                                                  </UL>
                                              )}
                                            </>
                                        )}
                                      </LI>
                                  ))}
                                </UL>
                            )}
                          </>
                      )}
                    </LI>
                ))}
              </Fragment>
          ))}
        </UL>
      </Fragment>
  );
};

export default SidebarMenuItems;