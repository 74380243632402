// import React, { Fragment, } from 'react';
// import { Route, Routes } from 'react-router-dom';
// import { routes } from './Routes';
// import Layout from '../Layout/Layout';
//
// const LayoutRoutes = () => {
//   return (
//     <Fragment>
//       <Routes>
//         {routes.map(({ path, Component }, i) => (
//           <Route element={<Layout />} key={i}>
//             <Route path={path} element={Component} />
//           </Route>
//         ))}
//       </Routes>
//     </Fragment >
//   );
// };
//
// export default LayoutRoutes;




// LayoutRoutes.js
import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes } from './Routes';
import Layout from '../Layout/Layout';
import PrivateRoute from './PrivateRoute';

const LayoutRoutes = () => {
    return (
        <Fragment>
            <Routes>
                {routes.map(({ path, Component, roles }, i) => (
                    <Route element={<PrivateRoute roles={roles} />} key={i}>
                        <Route element={<Layout />}>
                            <Route path={path} element={Component} />
                        </Route>
                    </Route>
                ))}
            </Routes>
        </Fragment>
    );
};

export default LayoutRoutes;