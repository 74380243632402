// components/HorizontalTableClass.js
import React, { useState } from 'react';
import { H5 } from '../../../AbstractElements';
import { HorizontalBorders } from '../../../Constant';
import {
  Col,
  Card,
  CardHeader,
  Table,
  Button,
  Spinner,
  Alert,
  Pagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap';
import { useGetIncomingInvoicesQuery } from '../../../redux/apis/apiSlice';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';

const HorizontalTableClass = () => {
  // حالة الصفحة الحالية وعدد الصفوف لكل صفحة
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  // جلب البيانات باستخدام RTK Query بناءً على الصفحة الحالية وعدد الصفوف
  const { data,
    error,
    isLoading,
    isFetching
  } = useGetIncomingInvoicesQuery({ page: currentPage, limit });

  // دوال التعامل مع أزرار "تعديل" و"حذف"
  const handleEdit = (id) => {
    // قم بتنفيذ منطق التعديل هنا
    toast.info(`تعديل الفاتورة ${id}`);
  };

  const handleDelete = (id) => {
    // قم بتنفيذ منطق الحذف هنا
    toast.error(`حذف الفاتورة ${id}`);
  };

  // دالة تغيير الصفحة
  const handlePageChange = (page) => {
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  // دالة إنشاء عناصر الباجينيشن بناءً على بيانات الباجينيشن من السيرفر
  const renderPagination = () => {
    if (!data || !data.pagination) return null;

    const { page, pages, hasNextPage, hasPrevPage } = data.pagination;

    const pageNumbers = [];
    for (let i = 1; i <= pages; i++) {
      pageNumbers.push(i);
    }

    return (
        <Pagination aria-label="Page navigation example">
          <PaginationItem disabled={!hasPrevPage}>
            <PaginationLink
                first
                onClick={() => handlePageChange(1)}
                href="#"
            />
          </PaginationItem>
          <PaginationItem disabled={!hasPrevPage}>
            <PaginationLink
                previous
                onClick={() => handlePageChange(page - 1)}
                href="#"
            />
          </PaginationItem>
          {pageNumbers.map(p => (
              <PaginationItem active={p === page} key={p}>
                <PaginationLink
                    onClick={() => handlePageChange(p)}
                    href="#"
                >
                  {p}
                </PaginationLink>
              </PaginationItem>
          ))}
          <PaginationItem disabled={!hasNextPage}>
            <PaginationLink
                next
                onClick={() => handlePageChange(page + 1)}
                href="#"
            />
          </PaginationItem>
          <PaginationItem disabled={!hasNextPage}>
            <PaginationLink
                last
                onClick={() => handlePageChange(pages)}
                href="#"
            />
          </PaginationItem>
        </Pagination>
    );
  };
console.log("data",data)
  return (
      <Col sm="12">
        {
            data?.data.length>0?(
                <Card>

                  <CardHeader>
                    <H5>{"قائمة المشتريات"}</H5>

                  </CardHeader>

                  <div className="table-responsive" style={{ position: 'relative' }}>
                    {/* عرض علامة تحميل أثناء جلب البيانات الجديدة */}
                    {isFetching && (
                        <div style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          zIndex: 10,
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          padding: '1rem',
                          borderRadius: '0.5rem'
                        }}>
                          <Spinner color="primary" />
                        </div>
                    )}

                    {isLoading ? (
                        <div className="d-flex justify-content-center my-4">
                          <Spinner color="primary" />
                        </div>
                    ) : error ? (
                        <Alert color="danger">حدث خطأ أثناء جلب البيانات.</Alert>
                    ) : data && Array.isArray(data.data) ? (
                        <>
                          <Table className="table-border-horizontal">
                            <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">اسم العميل</th>
                              <th scope="col">رقم الفاتورة</th>
                              <th scope="col">مبلغ الفاتورة</th>
                              <th scope="col">تاريخ الفاتورة</th>
                              <th scope="col">تاريخ التعديل</th>
                              <th scope="col">الموظف</th>
                              <th scope="col">المستخدم</th>
                              <th scope="col">الإجراءات</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.data.map((item, index) => (
                                <tr key={item._id}>
                                  <th scope="row">{(currentPage - 1) * limit + index + 1}</th>
                                  <td>{item.customerName}</td>
                                  <td>{item.invoiceNumber || 'غير متوفر'}</td>
                                  <td>{item.invoiceAmount}</td>
                                  <td>{new Date(item.invoiceDate).toLocaleDateString()}</td>
                                  <td>{new Date(item.updatedAt).toLocaleDateString()}</td>
                                  <td>{item.employee?.name || 'غير متوفر'}</td>
                                  <td>{item.user?.username || 'غير متوفر'}</td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <Button color="primary" size="sm" onClick={() => handleEdit(item._id)}>
                                        <FaEdit style={{ marginRight: '0.25rem' }} /> تعديل
                                      </Button>
                                      <Button color="danger" size="sm" onClick={() => handleDelete(item._id)}>
                                        <FaTrash style={{ marginRight: '0.25rem' }} /> حذف
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                            ))}
                            </tbody>
                          </Table>
                          {/* عناصر الباجينيشن */}
                          <div className="d-flex justify-content-center my-4">
                            {renderPagination()}
                          </div>
                        </>
                    ) : (
                        <Alert color="warning">لا توجد بيانات للعرض.</Alert>
                    )}
                  </div>
                  <ToastContainer />
                </Card>
            ):
          <Alert color="info">
          لا يوجد مشتريات بعد
          </Alert>
        }

      </Col>
  );
};

export default HorizontalTableClass;