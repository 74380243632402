import React, { Fragment, useEffect, useState } from "react";
import CountUp from "react-countup";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import {
  Database,
  DollarSign,
  MessageCircle,
  Percent,
  ShoppingBag,
  UserPlus,
} from "react-feather";
import {
  useGetFawateerByEmployeeQuery,
  useGetPaymentsByEmployeeQuery,
  useGetYearsWithPaymentsQuery,
  useGetIncomingInvoicesByYearQuery,
} from "../../../redux/apis/apiSlice";
import { useParams } from "react-router-dom";
import YearRevenueCard from "../../Employees/YearRevenueCard";

const Earnings = () => {
  const { employeeId } = useParams();

  // الحالة الافتراضية للسنة هي السنة الحالية (تُستخدم في باقي الأقسام)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");

  // استخدام نسبة الربح المحفوظة في الـ localStorage
  const getLocalStorageKey = (id) => `profitPercentage_${id}`;
  const [profitPercentage, setProfitPercentage] = useState(() => {
    if (!employeeId) return 0;
    const savedPercentage = localStorage.getItem(getLocalStorageKey(employeeId));
    return savedPercentage !== null ? parseFloat(savedPercentage) : 0;
  });

  useEffect(() => {
    if (!employeeId) return;
    const savedPercentage = localStorage.getItem(getLocalStorageKey(employeeId));
    setProfitPercentage(savedPercentage !== null ? parseFloat(savedPercentage) : 0);
  }, [employeeId]);

  useEffect(() => {
    if (!employeeId) return;
    localStorage.setItem(getLocalStorageKey(employeeId), profitPercentage);
  }, [profitPercentage, employeeId]);

  // جلب السنوات التي تحتوي على بيانات المدفوعات
  const {
    data: yearsResponse,
    isLoading: yearsLoading,
    error: yearsError,
  } = useGetYearsWithPaymentsQuery(employeeId);

  // جلب بيانات المدفوعات بناءً على السنة المختارة (تُستخدم في بطاقات الأرباع وباقي الحسابات)
  const {
    data: paymentByEmployee,
    error: paymentByEmployeeError,
    isLoading: paymentByEmployeeLoading,
    refetch: refetchPayments,
  } = useGetPaymentsByEmployeeQuery({ employeeId, page: 1, limit: 10, year: selectedYear });

  // جلب بيانات الفواتير القديمة (من الـ API القديم)
  const {
    data: FawateerByEmployee,
    error: FawateerByEmployeeError,
    isLoading: FawateerByEmployeeLoading,
    refetch: refetchFawateer,
  } = useGetFawateerByEmployeeQuery({
    employeeId,
    page: 1,
    limit: 10,
    dateFrom,
    dateTo,
    name,
    number,
  });

  // استخدام الهوك الجديد لجلب الفواتير بناءً على السنة
  const {
    data: incomingInvoicesByYear,
    error: incomingInvoicesByYearError,
    isLoading: incomingInvoicesByYearLoading,
    refetch: refetchInvoicesByYear,
  } = useGetIncomingInvoicesByYearQuery({
    year: selectedYear,
    page: 1,
    limit: 10,
    dateFrom,
    dateTo,
  });

  useEffect(() => {
    if (!employeeId) {
      console.error("Employee ID is missing");
    }
  }, [employeeId]);

  useEffect(() => {
    refetchPayments();
    refetchInvoicesByYear();
  }, [selectedYear, refetchPayments, refetchInvoicesByYear]);

  if (
      FawateerByEmployeeLoading ||
      paymentByEmployeeLoading ||
      incomingInvoicesByYearLoading
  ) {
    return <div>جاري التحميل...</div>;
  }

  if (FawateerByEmployeeError) {
    return (
        <div>
          خطأ في تحميل الفواتير القديمة:{" "}
          {FawateerByEmployeeError.data?.message || FawateerByEmployeeError.message}
        </div>
    );
  }

  if (paymentByEmployeeError) {
    return (
        <div>
          خطأ في تحميل المدفوعات:{" "}
          {paymentByEmployeeError.data?.message || paymentByEmployeeError.message}
        </div>
    );
  }

  if (incomingInvoicesByYearError) {
    return (
        <div>
          خطأ في تحميل الفواتير حسب السنة:{" "}
          {incomingInvoicesByYearError.data?.message || incomingInvoicesByYearError.message}
        </div>
    );
  }

  // -------------------
  // بطاقات الأرباع (لا تعديل بها)
  const totalQ1Invoices = incomingInvoicesByYear?.totalQ1 || 0;
  const totalQ2Invoices = incomingInvoicesByYear?.totalQ2 || 0;
  const totalQ3Invoices = incomingInvoicesByYear?.totalQ3 || 0;
  const totalQ4Invoices = incomingInvoicesByYear?.totalQ4 || 0;

  const totalQ1Payments = paymentByEmployee?.data?.totalQ1 || 0;
  const totalQ2Payments = paymentByEmployee?.data?.totalQ2 || 0;
  const totalQ3Payments = paymentByEmployee?.data?.totalQ3 || 0;
  const totalQ4Payments = paymentByEmployee?.data?.totalQ4 || 0;

  const calculateDifference = (payments, invoices) => payments - invoices;
  const calculateTax = (difference) => {
    const tax = (difference / 1.15) * 0.15;
    return parseFloat(tax.toFixed(2));
  };
  const calculateProfit = (payments) => (payments / 1.15) * (profitPercentage / 100);
  const calculatePercentage = (difference, invoices) =>
      invoices === 0 ? 0 : (difference / invoices) * 100;

  const quartersData = [
    {
      quarter: "Q1",
      color: "Q1",
      payments: totalQ1Payments,
      invoices: totalQ1Invoices,
      difference: calculateDifference(totalQ1Payments, totalQ1Invoices),
      tax: calculateTax(calculateDifference(totalQ1Payments, totalQ1Invoices)),
      profit: calculateProfit(totalQ1Payments),
      percentage: calculatePercentage(
          calculateDifference(totalQ1Payments, totalQ1Invoices),
          totalQ1Invoices
      ),
      number: 1,
    },
    {
      quarter: "Q2",
      color: "Q2",
      payments: totalQ2Payments,
      invoices: totalQ2Invoices,
      difference: calculateDifference(totalQ2Payments, totalQ2Invoices),
      tax: calculateTax(calculateDifference(totalQ2Payments, totalQ2Invoices)),
      profit: calculateProfit(totalQ2Payments),
      percentage: calculatePercentage(
          calculateDifference(totalQ2Payments, totalQ2Invoices),
          totalQ2Invoices
      ),
      number: 2,
    },
    {
      quarter: "Q3",
      color: "Q3",
      payments: totalQ3Payments,
      invoices: totalQ3Invoices,
      difference: calculateDifference(totalQ3Payments, totalQ3Invoices),
      tax: calculateTax(calculateDifference(totalQ3Payments, totalQ3Invoices)),
      profit: calculateProfit(totalQ3Payments),
      percentage: calculatePercentage(
          calculateDifference(totalQ3Payments, totalQ3Invoices),
          totalQ3Invoices
      ),
      number: 3,
    },
    {
      quarter: "Q4",
      color: "Q4",
      payments: totalQ4Payments,
      invoices: totalQ4Invoices,
      difference: calculateDifference(totalQ4Payments, totalQ4Invoices),
      tax: calculateTax(calculateDifference(totalQ4Payments, totalQ4Invoices)),
      profit: calculateProfit(totalQ4Payments),
      percentage: calculatePercentage(
          calculateDifference(totalQ4Payments, totalQ4Invoices),
          totalQ4Invoices
      ),
      number: 4,
    },
  ];

  // -------------------
  // البيانات الخاصة بالبطاقات الإضافية بعد بطاقات الأرباع

  // حساب إجمالي دفعات السنة (جمع دفعات الأرباع)
  const totalYearPayments =
      totalQ1Payments + totalQ2Payments + totalQ3Payments + totalQ4Payments;

  // حساب إجمالي الفواتير للسنة (جمع فواتير الأرباع)
  const totalYearInvoices =
      totalQ1Invoices + totalQ2Invoices + totalQ3Invoices + totalQ4Invoices;

  // نفترض أن الـ API الخاص بالمدفوعات يعيد قيمة آخر دفعة ضمن paymentByEmployee.data.lastPayment
  const lastPayment = paymentByEmployee?.data?.lastPayment || 0;

  // حساب الربح لكل ربع باستخدام الدالة calculateProfit، ثم جمعها لحساب الربح الكلي للسنة
  const totalYearProfit =
      calculateProfit(totalQ1Payments) +
      calculateProfit(totalQ2Payments) +
      calculateProfit(totalQ3Payments) +
      calculateProfit(totalQ4Payments);

  // مصفوفة البطاقات باستخدام inline styles لتلوين كل بطاقة بشكل مختلف، مع تحديد لون الخط أبيض
  const NumbersUpData = [
    {
      type: "YearPayments",
      style: { backgroundColor: "#007bff", color: "#fff" },
      icon: <ShoppingBag color="#fff" size={50} aria-label="Year Payments Icon" />,
      heading: "إجمالي الدفعات للسنة",
      amount: totalYearPayments,
    },
    {
      type: "YearInvoices",
      style: { backgroundColor: "#6c757d", color: "#fff" },
      icon: <Database color="#fff" size={50} aria-label="Year Invoices Icon" />,
      heading: "إجمالي الفواتير للسنة",
      amount: totalYearInvoices,
    },
    {
      type: "LastPayment",
      style: { backgroundColor: "#ffc107", color: "#fff" },
      icon: <DollarSign color="#fff" size={50} aria-label="Last Payment Icon" />,
      heading: "آخر دفعة",
      amount: lastPayment,
    },
    {
      type: "YearProfit",
      style: { backgroundColor: "#17a2b8", color: "#fff" },
      icon: <Percent color="#fff" size={50} aria-label="Year Profit Icon" />,
      heading: `مجموع الربح (%${profitPercentage}) للسنة`,
      amount: totalYearProfit,
    },
  ];

  const handleFilter = () => {
    // إعادة جلب بيانات الفواتير والمدفوعات عند التغيير
    refetchFawateer();
    refetchPayments();
    refetchInvoicesByYear();
  };

  return (
      <Fragment>
        {/* عناصر الفلترة */}
        <div className="date-filters mb-4">
          <Row className="mt-3">
            <Col md="3" sm="6">
              <Label htmlFor="yearSelect">اختر السنة:</Label>
              <Input
                  type="select"
                  id="yearSelect"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
              >
                {yearsLoading && <option>جاري التحميل...</option>}
                {yearsError && <option>حدث خطأ في تحميل السنوات</option>}
                {yearsResponse &&
                yearsResponse.data &&
                Array.isArray(yearsResponse.data.years) &&
                yearsResponse.data.years.length > 0 ? (
                    yearsResponse.data.years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                    ))
                ) : (
                    !yearsLoading && <option>لا توجد سنوات</option>
                )}
              </Input>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="3" sm="6">
              <Label htmlFor="profitPercentage">يرجى تحديد نسبة الربح:</Label>
              <Input
                  type="number"
                  id="profitPercentage"
                  placeholder="أدخل نسبة الربح (%)"
                  value={profitPercentage}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    setProfitPercentage(isNaN(value) ? 0 : value);
                  }}
                  step="0.1"
                  min="0"
              />
            </Col>
          </Row>
          {/* يمكن إضافة عناصر فلترة إضافية هنا */}
          <Row className="mt-3">
            <Col md="3" sm="6">
              <Button color="primary" onClick={handleFilter}>
                تطبيق الفلاتر
              </Button>
            </Col>
          </Row>
        </div>

        {/* عرض بطاقات الأرباع */}
        <Row className="mb-4">
          {quartersData.map((qData) => (
              <Col sm="12" xl="3" lg="6" key={`quarter-${qData.quarter}`} className="mb-4">
                <Card className={`quarter-card ${qData.quarter}`}>
                  <CardBody className="quarter-card-body">
                    <div className="quarter-icon">{qData.number}</div>
                    <div className="quarter-info">
                      <div className="quarter-title">{`إجمالي الربع ${qData.quarter}`}</div>
                      <div className="quarter-value">
                        <CountUp end={qData.payments} separator="," decimals={2} decimal="." />
                      </div>
                      <div className="quarter-subinfo">
                    <span>
                      مجموع الفواتير: <strong>{qData.invoices.toLocaleString()}</strong>
                    </span>
                        <span>
                      الفرق: <strong>{qData.difference.toLocaleString()}</strong>
                    </span>
                        <span>
                      ضريبة الفرق 15%: <strong>{qData.tax.toLocaleString()}</strong>
                    </span>
                        <span>
                      الربح (%{profitPercentage}): <strong>{qData.profit.toLocaleString()}</strong>
                    </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
          ))}
        </Row>

        {/* عرض البطاقات الإضافية */}
        <Row>
          {NumbersUpData.map((data, i) => (
              <Col sm="6" xl="3" lg="6" key={`data-${i}`} className="mb-4">
                <Card className={`numbers-up-card ${data.type}`} style={data.style}>
                  <CardBody className="numbers-up-card-body">
                    <div className="numbers-up-icon">{data.icon}</div>
                    <div className="numbers-up-info">
                      <div className="numbers-up-title">{data.heading}</div>
                      <div className="numbers-up-value">
                        <CountUp end={data.amount} separator="," decimals={2} decimal="." />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
          ))}
        </Row>

        {/* قسم جديد لعرض مجموع الإيرادات لكل سنة */}
        <Row className="mt-4">
          <Col xs="12">
            <h4 style={{ color: "#000" }}>إيرادات كل سنة</h4>
          </Col>
          {yearsLoading ? (
              <div>جاري التحميل...</div>
          ) : yearsError ? (
              <div>حدث خطأ في تحميل السنوات</div>
          ) : (
              yearsResponse &&
              yearsResponse.data &&
              Array.isArray(yearsResponse.data.years) &&
              yearsResponse.data.years.map((year) => (
                  <YearRevenueCard key={year} employeeId={employeeId} year={year}  />
              ))
          )}
        </Row>
      </Fragment>
  );
};

export default Earnings;