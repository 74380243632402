import AllCards from '../Employees/EmployeesCards';
import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';

const EmployeesCardssContain = () => {
    return (
        <Fragment>
            <Container fluid className="user-card">
                <Row>
                    <AllCards />
                </Row>
            </Container>
        </Fragment>
    );
};
export default EmployeesCardssContain;