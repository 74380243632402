import React, { useState } from 'react';
import {
    Container, Row, Col, Pagination, PaginationItem, PaginationLink, Spinner, Alert, Button, Modal, ModalHeader, ModalBody
} from 'reactstrap';
import {useGetSinksQuery} from "../../../redux/apis/apiSlice";
import SinkCard from "./SinkCard";
import SinkForm from "./index";


const SinkList = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const { data, error, isLoading, refetch } = useGetSinksQuery({ page: currentPage, limit: 10 });

    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);

    const handlePageChange = (page) => {
        if (page !== currentPage && page > 0 && page <= (data?.totalPages || 1)) {
            setCurrentPage(page);
        }
    };

    const handleFormSuccess = () => {
        toggleModal();
        refetch(); // إعادة جلب البيانات بعد إضافة سند جديد
    };

    return (
        <Container className="mt-4">
            <h2 className="mb-4 text-center">Porcelain Sinks</h2>
            <Button color="primary" className="mb-4" onClick={toggleModal}>
                Create New Sink
            </Button>

            {isLoading && <Spinner color="primary" />}
            {error && <Alert color="danger">Failed to fetch sinks. Please try again later.</Alert>}
            <Row>
                {data?.data.map(sink => (
                    <Col sm="12" md="6" lg="4" key={sink._id}>
                        <SinkCard sink={sink} />
                    </Col>
                ))}
            </Row>
            <Pagination aria-label="Page navigation example" className="justify-content-center mt-4">
                <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink first onClick={() => handlePageChange(1)} />
                </PaginationItem>
                <PaginationItem disabled={currentPage <= 1}>
                    <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
                </PaginationItem>
                {data && Array.from({ length: data.totalPages }, (_, index) => (
                    <PaginationItem active={currentPage === index + 1} key={index}>
                        <PaginationLink onClick={() => handlePageChange(index + 1)}>
                            {index + 1}
                        </PaginationLink>
                    </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage >= (data?.totalPages || 1)}>
                    <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
                </PaginationItem>
                <PaginationItem disabled={currentPage >= (data?.totalPages || 1)}>
                    <PaginationLink last onClick={() => handlePageChange(data?.totalPages || 1)} />
                </PaginationItem>
            </Pagination>

            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Create New Sink</ModalHeader>
                <ModalBody>
                    <SinkForm onSuccess={handleFormSuccess} />
                </ModalBody>
            </Modal>
        </Container>
    );
};

export default SinkList;