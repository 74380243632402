// frontend/src/App.js
import React from 'react'
import SendInvoice from "./SendInvoice";
import Invoices from "./Invoices";
import ObtainCertificate from "./ObtainCertificate";


const InvoicesPage = () => {
    return (
        <div>
            <h1>ZATCA E-Invoicing Integration</h1>
            <Invoices />
            <SendInvoice />
            <ObtainCertificate />
        </div>
    )
}

export default InvoicesPage