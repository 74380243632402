// redux/apis/apiSlice.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// استيراد جميع Endpoints
import { getEmployeesEndpoint } from "./endpoints/getEmployees";
import { getEmployeeEndpoint } from "./endpoints/getEmployee";
import { addPaymentEndpoint } from "./endpoints/addPayment";
import { getPaymentsEndpoint } from "./endpoints/getPayments";
import { paymentsEndpoints} from "./endpoints/getPaymentsByEmployee";
import { getCustomersEndpoint } from "./endpoints/getCustomers";
import { addCustomerEndpoint } from "./endpoints/addCustomer";
import { addIncomingInvoiceEndpoint } from "./endpoints/addIncomingInvoice";
import { getIncomingInvoicesEndpoint } from "./endpoints/getIncomingInvoices";
import { getFawateerByEmployeeEndpoint } from "./endpoints/getFawateerByEmployee";
import { getPostsEndpoint } from "./endpoints/getPosts";
import { getCategoriesEndpoint } from "./endpoints/getCategoriesEndpoint";
import { signInEndpoint } from "./endpoints/signIn";
import { signUpEndpoint } from "./endpoints/signUp";
import {addGalleryCategoryEndpoint} from "./endpoints/addGalleryCategory";
import {getAllCategoriesGalleryEndpoint} from "./endpoints/getAllCategoriesGalleryEndpoint";
import {uploadGalleryImagesEndpoint} from "./endpoints/uploadGalleryImages";
import {getGalleryEndpoint} from "./endpoints/getGalleryCategories";
import {updatePhotoInGalleryEndpoint} from "./endpoints/updatePhotoInGallery";
import {cardsForWash} from "./endpoints/cardForWash";
import {getIncomingInvoicesByYearEndpoint} from "./endpoints/getIncomingInvoicesByYearEndpoint";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_URL_BACKEND,
    prepareHeaders: (headers, { getState }) => {
      // الحصول على التوكن من حالة Redux
      const token = getState().user.currentUser?.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Customer", "Employees", "Payments", "IncomingInvoices"],
  endpoints: (builder) => ({
    // دمج جميع Endpoints المستوردة
    ...getEmployeesEndpoint(builder),
    ...getEmployeeEndpoint(builder),
    ...addPaymentEndpoint(builder),
    ...getPaymentsEndpoint(builder),
    // ...getPaymentsByEmployeeEndpoint(builder),
    ...getCustomersEndpoint(builder),
    ...addCustomerEndpoint(builder),
    ...addIncomingInvoiceEndpoint(builder),
    ...getIncomingInvoicesEndpoint(builder),
    ...getFawateerByEmployeeEndpoint(builder),
    ...getPostsEndpoint(builder),
    ...getCategoriesEndpoint,
    ...signInEndpoint(builder),
    ...signUpEndpoint(builder),
    ...addGalleryCategoryEndpoint(builder),
    ...getAllCategoriesGalleryEndpoint(builder),
    ...uploadGalleryImagesEndpoint(builder),
    ...getGalleryEndpoint(builder),
    ...updatePhotoInGalleryEndpoint(builder),
    ...cardsForWash(builder),
    ...paymentsEndpoints(builder),
    ...getIncomingInvoicesByYearEndpoint(builder),



  }),
});

// تصدير الخطافات (Hooks) لاستخدامها في المكونات
export const {
  useGetEmployeesQuery,
  useGetEmployeeQuery,
  useAddPaymentMutation,
  useGetPaymentsQuery,
  useGetPaymentsByEmployeeQuery,
  useGetCustomersQuery,
  useAddCustomerMutation,
  useAddIncomingInvoiceMutation,
  useGetIncomingInvoicesQuery,
  useGetFawateerByEmployeeQuery,
  useGetPostsQuery,
  useSignUpMutation,
  useAddGalleryCategoryMutation,
  useGetAllCategoriesQuery,
  useUploadGalleryImagesMutation,
  useGetGalleryByCategoryQuery,
    useUpdatePhotoInGalleryMutation,
  useGetSinksQuery,
  useAddSinkMutation,
  useUpdateSinkMutation,
  useDeleteSinkMutation,
  useGetYearsWithPaymentsQuery,
  useGetIncomingInvoicesByYearQuery,
  useGetTotalPaymentsByYearQuery


} = apiSlice;
