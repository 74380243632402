import { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Form, FormGroup, Input, Label } from "reactstrap";
import { Facebook, Linkedin, Twitter } from "react-feather";
import { Btn, H4, H6, Image, P } from "../../../AbstractElements";

export default function LoginForm() {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const toggle = () => setPasswordVisible(!isPasswordVisible);
  const handleSubmit = (e) => {
    e.preventDefault();
    document.getElementById("email").value = "";
    document.getElementById("password").value = "";
    document.getElementById("checkbox1").checked = "";
  };
  var images = require.context("../../../assets/images", true);
  const dynamicImage = (image) => {
    return images(`./${image}`);
  };
  return (
    <div>
      <div>
        <Link
          className='logo'
          to={`${process.env.PUBLIC_URL}/dashboard/default`}
        >
          <Image
            className="img-fluid for-light"
            src={dynamicImage(`logo/morden-logo.png`)}
            alt="looginpage"
            body={true}
          />
        </Link>
      </div>
      <div className="login-main">
        <Form className="theme-form" onSubmit={handleSubmit}>
          <H4>{"Sign in to account"}</H4>
          <P>{"Enter your email & password to login"}</P>
          <FormGroup>
            <Col>
              <Label>{"Email Address"}</Label>
            </Col>
            <Input
              type="email"
              id="email"
              required
              placeholder="Test@gmail.com"
            />
          </FormGroup>
          <FormGroup>
            <Col>
              <Label>{"Password"}</Label>
            </Col>
            <div className="form-input position-relative">
              <Input
                type={isPasswordVisible ? "text" : "password"}
                id="password"
                name="login[password]"
                required
                placeholder="*********"
              />
              <div className="show-hide" onClick={toggle}>
                <span className={!isPasswordVisible ? "show" : ""}></span>
              </div>
            </div>
          </FormGroup>
          <div className="mb-0 form-group">
            <div className="checkbox p-0">
              <Input id="checkbox1" type="checkbox" />
              <Label className="text-muted" htmlFor="checkbox1">
                {"Remember password"}
              </Label>
            </div>
            <Link
              className="link"
              to={`${process.env.PUBLIC_URL}/pages/authentication/forget-pwd`}
            >
              {"Forgot password?"}
            </Link>
            <div className="text-end mt-3">
              <Btn color="primary" className="btn-block w-100">
                {"Sign In"}
              </Btn>
            </div>
          </div>
          <H6 className="text-muted mt-4 or">{"Or Sign in with"}</H6>
          <div className="social mt-4">
            <div className="btn-showcase">
              <Link
                to="https://www.linkedin.com/login"
                className="btn btn-light me-1"
                target="_blank"
              >
                <Linkedin /> {"LinkedIn"}
              </Link>
              <Link
                to="https://twitter.com/login?lang=en"
                className="btn btn-light m-0"
                target="_blank"
              >
                <Twitter /> {"Twitter"}
              </Link>
              <Link
                to="https://www.facebook.com/"
                className="btn btn-light"
                target="_blank"
              >
                <Facebook /> {"Facebook"}
              </Link>
            </div>
          </div>
          <P className="mt-4 mb-0 text-center">
            {"Don't have account?"}
            <Link
              className="ms-2"
              to={`${process.env.PUBLIC_URL}/pages/authentication/register-simpleimg`}
            >
              {"Create Account"}
            </Link>
          </P>
        </Form>
      </div>
    </div>
  );
}
