import React, { useEffect, useState } from "react";
import {
    Button,
    Input,
    Label,
    Alert,
    Spinner,
    Form,
    FormGroup,
    FormFeedback,
    ListGroup,
    ListGroupItem,
    Container,
    Row,
    Col,
} from "reactstrap";
import * as Yup from "yup";
import axios from "axios";
import { useAppContext } from "../context/FormData.jsx";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {toast} from "react-toastify";

const apenAiToken = process.env.REACT_APP_OPENAI_API_KEY

const FormForPost = () => {
    const { currentUser } = useSelector((state) => state.user);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [options, setOptions] = useState([]); // State to store options
    const [categorydOption, setCategoryOption] = useState(""); // State to store selected option
    const [file, setFile] = useState(null);
    const [errors, setErrors] = useState({});
    const { formData, setFormData, keyword, setKeyword } = useAppContext();
    const [temperature, setTemperature] = useState(Number(0.5));
    const [images, setImages] = useState([]);
    const [messages, setMessages] = useState(() => {
        const savedMessages = localStorage.getItem("chatMessages");
        return savedMessages
            ? JSON.parse(savedMessages)
            : [{ role: "system", content: "You are a helpful assistant." }];
    });
    const [response, setResponse] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleImagesChange = (e) => {
        const files = Array.from(e.target.files);
        setImages(files);
    };

    const handleSendMessage = async (userContent, temperature, setLoadingFunc) => {
        const newMessages = [...messages, { role: "user", content: userContent }];
        setMessages(newMessages);
        setLoadingFunc(true); // تعيين حالة التحميل إلى true
        try {
            const response = await fetch(
                "https://api.openai.com/v1/chat/completions",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${apenAiToken}`,
                    },
                    body: JSON.stringify({
                        model: "gpt-4", // أو gpt-3.5-turbo
                        messages: newMessages,
                        temperature: temperature, // تحديد درجة الحرارة هنا
                    }),
                }
            );

            const data = await response.json();
            const assistantMessage = data.choices[0].message.content;

            setMessages([
                ...newMessages,
                { role: "assistant", content: assistantMessage },
            ]);
            setResponse(assistantMessage);
        } catch (error) {
            console.error("Error fetching the API:", error);
        } finally {
            setLoadingFunc(false); // تعيين حالة التحميل إلى false عند انتهاء الطلب
        }
    };

    const dynamicRegex = new RegExp(`(^|\\s)${keyword}(\\s|$)`, "i");
    const [previewImages, setPreviewImages] = useState([]);
    const schema = Yup.object().shape({
        category: Yup.string().required("يجب ان تنتمي المقالة الى تصنيف"),
        title: Yup.string()
            .min(45, "اقل طول للعنوان يجب ان يكون 45 حرف")
            .max(60, "اقصى طول للعنوان يجب ان يكون 60 حرفا.")
            .test(
                "contains-specific-word",
                `يجب ان يحتوي العنوان على الكلمة المفتاحية : ${keyword}`, // رسالة الخطأ
                (value) => {
                    return value && dynamicRegex.test(value); // التحقق باستخدام التعبير المنتظم
                }
            ),
        metaDesc: Yup.string()
            .min(100, "طول الوصف يجب ان لا يقل عن 100 حرف")
            .max(160, "الوصف طويل جدا , يجب ان لا يتجاوز 160 حرف")
            .test(
                "contains-specific-word",
                `الوصف يجب ان يحتوي على الكلمة المفتاحية : ${keyword}`, // رسالة الخطأ
                (value) => {
                    return value && dynamicRegex.test(value); // التحقق باستخدام التعبير المنتظم
                }
            ),
        firstH2: Yup.string()
            .min(35, "العنوان قصير جدا , يجب ان لا يقل عن 35 حرف")
            .max(100, "العنوان طويل جدا , يجب ان يكون اقل من 100 حرف")
            .test(
                "contains-specific-word",
                `يجب ان يحتوي العنوان على الكلمة المفتاحية : ${keyword}`, // رسالة الخطأ
                (value) => {
                    return value && dynamicRegex.test(value); // التحقق باستخدام التعبير المنتظم
                }
            ),
        firstParagraphForH2: Yup.string()
            .min(400, "المحتوى قصير جدا , يجب ان لا يقل عن 400 حرف ")
            .max(1500, "طول المحتوى اكثر من 1500 حرف وهذا كثير جدا"),
        firstH3: Yup.string()
            .min(35, "العنوان الثالث h3 يجب ان يكون اكثر من 30 حرف ")
            .max(100, "h3 must be at least 100 characters long"),
        firstParagraphForH3: Yup.string()
            .min(400, "firstParagraphForH3 must be at least 400 characters long")
            .max(1500, "firstParagraphForH3 must be at least 1500 characters long")
            .test(
                "contains-specific-word",
                `العنوان الثالث يجب ان يحتوي على الكلمة المفتاحية : ${keyword}`, // رسالة الخطأ
                (value) => {
                    return value && dynamicRegex.test(value); // التحقق باستخدام التعبير المنتظم
                }
            ),
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData();
        data.append("title", formData.title);
        data.append("metaDesc", formData.metaDesc);
        data.append("firstH2", formData.firstH2);
        data.append("firstParagraphForH2", formData.firstParagraphForH2);
        data.append("firstH3", formData.firstH3);
        data.append("firstParagraphForH3", formData.firstParagraphForH3);
        data.append("category", formData.category);
        data.append("lastSection", formData.lastSection);
        data.append("userId", currentUser.data._id);
        for (let i = 0; i < images.length; i++) {
            data.append("images", images[i]);
        }
        schema
            .validate(formData, { abortEarly: false }) // abortEarly: false لجمع كل الأخطاء
            .then(async () => {
                setErrors({});
                if (images.length === 0) {
                    alert("يجب اختيار صورة أو ملفات غيرة للصور الحد الاقصى ١٥ صورة");
                    setLoading(false);
                    return;
                }
                setIsSubmitting(true);
                try {
                    await axios.post(`${process.env.REACT_APP_URL_BACKEND}/api/post/create`, data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${currentUser.token}`,
                        },
                    });
                    handleCleanState();
                    setLoading(false);
                    navigate(`/post/`);
                    // عرض تنبيه النجاح باستخدام Reactstrap
                    window.alert("تمت الإضافة بنجاح");

                } catch (error) {
                    toast.error(('errors',error?.response?.data?.errors[0].msg));
                    toast.error(('errors',error.message));
                    setLoading(false);
                    if (error.response) {
                        console.error("Response data:", error.response.data);
                    }
                    console.error("Error:", error);
                }
                console.log("Form submitted:", formData);
                setIsSubmitting(false);
            })
            .catch((err) => {
                setLoading(false);
                const newErrors = {};
                console.log('err',err)
                // err.inner.forEach((error) => {
                //     newErrors[error.path] = error.message;
                //     console.log(error);
                // });
                setErrors(newErrors);
            });
    };

    useEffect(() => {
        localStorage.setItem("formData", JSON.stringify(formData));
        localStorage.setItem("keyword", JSON.stringify(keyword));
        localStorage.setItem("chatMessages", JSON.stringify(messages));
        const fetchData = async () => {
            try {
                const [category] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/category/get-categories`),
                ]);
                const categoryData = category.data.data;
                setOptions(categoryData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData(); // Call fetchData on component mount
    }, [keyword, formData, messages]); // Dependency array

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCleanState = () => {
        setFormData({
            title: "",
            description: "",
            firstH2: "",
            firstParagraphForH2: "",
            firstH3: "",
            firstParagraphForH3: "",
            lastSection: "",
            category: "",
            imageCover: "",
            images: [],
        });
        setErrors({});
        setMessages([]);
        setKeyword("");
        localStorage.setItem("formData", JSON.stringify(""));
        localStorage.setItem("keyword", JSON.stringify(""));
        localStorage.setItem("chatMessages", JSON.stringify(""));
    };

    const [messagesChatGpt] = useState({
        title: `اقترح لي عنوان لمقالة تتحدث عن الكلمة المفتاحية : ${keyword} ويجب ان يحتوي العنوان على الكلمة المفتاحية , وبسيط للغاية`,
        metaDesc: `اريد وصفا يحتوي على الكلمة المفتاحية لمرة واحدة فقط ومتوافق مع محركات البحث ويجب ان لا يزيد عن 160 حرف ,ولا يقل عن 100 ويجب ان يحتوي على الكلمة المفتاحية , وتكون الكلمة المفتاحية مطابقة تمام ل ${keyword} `,
        firstH2: "الان اكتب لي عنوان h2 الاول وببساطة ويحتوي على الكلمة المفتاحية ",
        firstParagraphForH2: "اكتب لي وصفا لا يقل عن 400 حرف للعنوان السابق ويكون على شكل قائمة مرقطة",
        firstH3: "الان اكتب لي عنوان h3 ويكون عبارة عن سؤال يستهدف الكلمة المفتاحية",
        firstParagraphForH3: "اكتب لي الاجوبة للسؤال السابق ويجب ان لا يقل عن 400 حرف ويحتوي على عدد اثنين من الكلمات المفتاحية",
        lastSection: "",
    });

    return (
        <Container fluid>
            <div className="header h-100 px-3 py-2 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center text-muted">
                    <span className="me-2">
                        <a href="/">الرئيسية</a>
                    </span>
                    <span>/</span>
                    <span className="mx-2">
                        <span className="fs-6">مقالة جديدة</span>
                    </span>
                    <span>/</span>
                </div>
            </div>
            <Button color="danger" className="mt-2" onClick={handleCleanState}>
                تنظيف كل state
            </Button>
            <div className="header my-3 h-100 px-3 d-flex align-items-center justify-content-between">
                <h1 className="fw-bold fs-4">مقالة جديدة</h1>
            </div>
            <Row className="flex-column flex-lg-row mx-3 mt-3">
                <Col lg="4" className="mb-3">
                    <Form className="shadow p-3">
                        <FormGroup className="mb-2">
                            <Label for="keyword" className="text-success">
                                الكلمة المفتاحية المستهدفة
                            </Label>
                        </FormGroup>
                        <FormGroup className="mb-4">
                            <Input
                                type="text"
                                id="keyword"
                                value={keyword}
                                placeholder="ادخل الكلمة المفتاحية قبل البدء بكتابة المقالة"
                                required
                                onChange={(e) => setKeyword(e.target.value)}
                            />
                        </FormGroup>
                        <Alert color="warning" className="rounded mb-3">
                            <strong className="fw-bold">القسم الاول:</strong> العنوان والوصف واختيار التصنيف
                        </Alert>
                        <FormGroup className="d-flex align-items-center gap-2">
                            <Input
                                type="radio"
                                id="published"
                                name="isPublic"
                                value={true}
                                defaultChecked
                                onChange={(event) => {
                                    setFormData({ ...formData, isPublic: event.target.value });
                                }}
                            />
                            <Label for="published">منشور</Label>
                        </FormGroup>
                        <FormGroup className="d-flex align-items-center gap-2 mb-3">
                            <Input
                                type="radio"
                                id="draft"
                                name="isPublic"
                                value={false}
                                onChange={(event) => {
                                    setFormData({ ...formData, isPublic: event.target.value });
                                }}
                            />
                            <Label for="draft">مسودة</Label>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="category">اختر تصنيف المقالة</Label>
                            <Input
                                type="select"
                                name="category"
                                id="category"
                                value={categorydOption}
                                onChange={(e) => {
                                    setFormData({ ...formData, category: e.target.value });
                                    setCategoryOption(e.target.value);
                                }}
                                invalid={errors.category ? true : false}
                            >
                                <option value="">اختر تصنيف المقالة</option>
                                {options.map((option, index) => (
                                    <option key={index} value={option._id}>
                                        {option.name}
                                    </option>
                                ))}
                            </Input>
                            {errors.category && (
                                <FormFeedback>{errors.category}</FormFeedback>
                            )}
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="title">عنوان المقالة:</Label>
                            <div className="d-flex align-items-center justify-content-between gap-3">
                                {loading ? (
                                    <Spinner size="sm" color="primary" />
                                ) : (
                                    <Button
                                        color="primary"
                                        onClick={() =>
                                            handleSendMessage(
                                                `اقترح لي عنوان لمقالة تتحدث عن الكلمة المفتاحية : ${keyword} ويجب ان يحتوي العنوان على الكلمة المفتاحية , وبسيط للغاية`,
                                                temperature,
                                                setLoading
                                            )
                                        }
                                    >
                                        توليد
                                    </Button>
                                )}
                                <Label for="temperature" className="mb-0">
                                    اكثر ابداعية
                                </Label>
                                <Input
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.01"
                                    value={temperature}
                                    onChange={(e) => setTemperature(Number(e.target.value))}
                                    id="temperature"
                                />
                            </div>
                            <Input
                                type="text"
                                name="title"
                                id="title"
                                value={formData.title}
                                onChange={handleChange}
                                placeholder="ادخل عنوان المقالة"
                                required
                                invalid={
                                    formData.title?.length < 45 || formData.title?.length > 60
                                }
                            />
                            {errors.title && (
                                <FormFeedback>{errors.title}</FormFeedback>
                            )}
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="metaDesc">وصف الميتا للمقالة</Label>
                            <Input
                                type="textarea"
                                name="metaDesc"
                                id="metaDesc"
                                value={formData.metaDesc}
                                onChange={handleChange}
                                placeholder="اتبع لون البوكس"
                                required
                                invalid={
                                    formData.metaDesc?.length < 100 ||
                                    formData.metaDesc?.length > 160
                                }
                                rows={4}
                            />
                            {errors.metaDesc && (
                                <FormFeedback>{errors.metaDesc}</FormFeedback>
                            )}
                            <div className="d-flex align-items-center justify-content-between gap-3 mt-2">
                                {loading ? (
                                    <Spinner size="sm" color="primary" />
                                ) : (
                                    <Button
                                        color="primary"
                                        onClick={() =>
                                            handleSendMessage(
                                                messagesChatGpt.metaDesc,
                                                temperature,
                                                setLoading
                                            )
                                        }
                                    >
                                        توليد
                                    </Button>
                                )}
                                <Label for="temperatureMetaDesc" className="mb-0">
                                    اكثر ابداعية
                                </Label>
                                <Input
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.01"
                                    value={temperature}
                                    onChange={(e) => setTemperature(Number(e.target.value))}
                                    id="temperatureMetaDesc"
                                />
                            </div>
                        </FormGroup>
                        <Alert color="warning" className="rounded mb-3">
                            <strong className="fw-bold">القسم الثاني:</strong> بداية المقالة لا تقل عن 300 كلمة
                        </Alert>
                        <FormGroup className="mb-3">
                            <Label for="firstH2">عنوان H2 الاول:</Label>
                            <div className="d-flex align-items-center justify-content-between gap-3">
                                {loading ? (
                                    <Spinner size="sm" color="primary" />
                                ) : (
                                    <Button
                                        color="primary"
                                        onClick={() =>
                                            handleSendMessage(
                                                messagesChatGpt.firstH2,
                                                temperature,
                                                setLoading
                                            )
                                        }
                                    >
                                        توليد
                                    </Button>
                                )}
                                <Label for="temperatureH2" className="mb-0">
                                    اكثر ابداعية
                                </Label>
                                <Input
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.01"
                                    value={temperature}
                                    onChange={(e) => setTemperature(Number(e.target.value))}
                                    id="temperatureH2"
                                />
                            </div>
                            <Input
                                type="text"
                                name="firstH2"
                                id="firstH2"
                                value={formData.firstH2}
                                onChange={handleChange}
                                placeholder="عنوان H2 الاول , يجب ان يكون مهم"
                                required
                                invalid={
                                    formData.firstH2?.length < 35 || formData.firstH2?.length > 100
                                }
                            />
                            {errors.firstH2 && (
                                <FormFeedback>{errors.firstH2}</FormFeedback>
                            )}
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="firstParagraphForH2">وصف العنوان الخاص ب H2</Label>
                            <Input
                                type="textarea"
                                name="firstParagraphForH2"
                                id="firstParagraphForH2"
                                value={formData.firstParagraphForH2}
                                onChange={handleChange}
                                placeholder="لا يقل عن 1000"
                                required
                                invalid={
                                    formData.firstParagraphForH2?.length < 400 ||
                                    formData.firstParagraphForH2?.length > 1500
                                }
                                rows={4}
                            />
                            {errors.firstParagraphForH2 && (
                                <FormFeedback>{errors.firstParagraphForH2}</FormFeedback>
                            )}
                            <div className="d-flex align-items-center justify-content-between gap-3 mt-2">
                                {loading ? (
                                    <Spinner size="sm" color="primary" />
                                ) : (
                                    <Button
                                        color="primary"
                                        onClick={() =>
                                            handleSendMessage(
                                                messagesChatGpt.firstParagraphForH2,
                                                temperature,
                                                setLoading
                                            )
                                        }
                                    >
                                        توليد
                                    </Button>
                                )}
                                <Label for="temperatureFirstParagraphH2" className="mb-0">
                                    اكثر ابداعية
                                </Label>
                                <Input
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.01"
                                    value={temperature}
                                    onChange={(e) => setTemperature(Number(e.target.value))}
                                    id="temperatureFirstParagraphH2"
                                />
                            </div>
                        </FormGroup>
                        <Alert color="warning" className="rounded mb-3">
                            <strong className="fw-bold">القسم الثالث:</strong> بداية المقالة لا تقل عن 300 كلمة
                        </Alert>
                        <FormGroup className="mb-3">
                            <Label for="firstH3">عنوان H3 الاول:</Label>
                            <div className="d-flex align-items-center justify-content-between gap-3">
                                {loading ? (
                                    <Spinner size="sm" color="primary" />
                                ) : (
                                    <Button
                                        color="primary"
                                        onClick={() =>
                                            handleSendMessage(
                                                messagesChatGpt.firstH3,
                                                temperature,
                                                setLoading
                                            )
                                        }
                                    >
                                        توليد
                                    </Button>
                                )}
                                <Label for="temperatureH3" className="mb-0">
                                    اكثر ابداعية
                                </Label>
                                <Input
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.01"
                                    value={temperature}
                                    onChange={(e) => setTemperature(Number(e.target.value))}
                                    id="temperatureH3"
                                />
                            </div>
                            <Input
                                type="text"
                                name="firstH3"
                                id="firstH3"
                                value={formData.firstH3}
                                onChange={handleChange}
                                placeholder="عنوان H3 الاول , يجب ان يكون مهم"
                                required
                                invalid={
                                    formData.firstH3?.length < 35 || formData.firstH3?.length > 100
                                }
                            />
                            {errors.firstH3 && (
                                <FormFeedback>{errors.firstH3}</FormFeedback>
                            )}
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="firstParagraphForH3">الوصف الثاني الخاص بالعنوان H3</Label>
                            <Input
                                type="textarea"
                                name="firstParagraphForH3"
                                id="firstParagraphForH3"
                                value={formData.firstParagraphForH3}
                                onChange={handleChange}
                                placeholder="لا يقل عن 1000"
                                required
                                invalid={
                                    formData.firstParagraphForH3?.length < 400 ||
                                    formData.firstParagraphForH3?.length > 1500
                                }
                                rows={4}
                            />
                            {errors.firstParagraphForH3 && (
                                <FormFeedback>{errors.firstParagraphForH3}</FormFeedback>
                            )}
                            <div className="d-flex align-items-center justify-content-between gap-3 mt-2">
                                {loading ? (
                                    <Spinner size="sm" color="primary" />
                                ) : (
                                    <Button
                                        color="primary"
                                        onClick={() =>
                                            handleSendMessage(
                                                messagesChatGpt.firstParagraphForH3,
                                                temperature,
                                                setLoading
                                            )
                                        }
                                    >
                                        توليد
                                    </Button>
                                )}
                                <Label for="temperatureFirstParagraphH3" className="mb-0">
                                    اكثر ابداعية
                                </Label>
                                <Input
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.01"
                                    value={temperature}
                                    onChange={(e) => setTemperature(Number(e.target.value))}
                                    id="temperatureFirstParagraphH3"
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="images">اختر صور المقالة</Label>
                            <div className="mb-3">
                                <Label
                                    className="mx-auto cursor-pointer d-flex w-100 flex-column align-items-center justify-content-center rounded border border-dashed border-success p-3 text-center"
                                    for="dropzone-file"
                                >
                                    <h2 className="mt-4 fs-5 text-success">
                                        اختر صور المقالة
                                    </h2>
                                    <p className="mt-2 text-muted">
                                        لا تزيد عن 15 صورة
                                    </p>
                                    <Input
                                        type="file"
                                        multiple
                                        onChange={handleImagesChange}
                                        required
                                        id="dropzone-file"
                                        hidden
                                    />
                                </Label>
                            </div>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Button
                                type="submit"
                                color="success"
                                className="w-100"
                                disabled={loading}
                                onClick={handleSubmit}
                            >
                                نشر
                            </Button>
                        </FormGroup>
                    </Form>
                </Col>
                <Col lg="8" className="mb-3">
                    <div className="shadow-lg p-3 rounded container">
                        <div className="d-flex justify-content-between">
                            {file && <img alt={formData.title} src={file} className="img-fluid" />}
                        </div>
                        <h1 className="fw-bold fs-3 pb-3">
                            <span>العنوان الرئيسي:</span> {formData.title}
                            <span>({formData.title?.length}) حرف</span>
                        </h1>
                        <div>
                            <p>
                                <span>وصف الميتا :</span> {formData?.metaDesc} (
                                {formData.metaDesc?.length}) حرف
                            </p>
                            <ListGroup>
                                <ListGroupItem>
                                    تابع للتصنيف: <span>{/* يمكن إضافة اسم التصنيف هنا إذا لزم الأمر */}</span>
                                </ListGroupItem>
                                <ListGroupItem>
                                    الكلمة المفتاحية : <span>{keyword}</span>
                                </ListGroupItem>
                            </ListGroup>
                            <hr />
                        </div>
                        <hr />
                        <h2 className="fw-bold">{formData.firstH2}</h2>
                        {/* يمكنك استخدام مكون مخصص لتقسيم النص إذا لزم الأمر */}
                        {formData.firstParagraphForH2 && (
                            <p>{formData.firstParagraphForH2}</p>
                        )}
                        <div className="row row-cols-2 g-2">
                            {previewImages.map((image, index) => (
                                <div className="col" key={index}>
                                    <img
                                        className="img-fluid rounded object-cover"
                                        src={image}
                                        alt=""
                                        style={{ height: "160px", objectFit: "cover" }}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="p-3">
                            <div className="mb-2">
                                {messages &&
                                    messages.map((msg, index) => (
                                        <div key={index} className="py-1">
                                            <strong className="text-primary">
                                                {msg.role === "user" ? "السؤال" : "الذكاء الصناعي"}:
                                            </strong>{" "}
                                            {msg.content}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default FormForPost;