import { Col, Container, Row } from "reactstrap";
import SignInForm from "./SignInForm";
import image from "../assets/images/login/login_bg.jpg";

export default function SignIn() {
  return (
    <Container className="p-0" fluid style={{ backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'block' }}>
      <Row className="m-0 ">
        <Col xs={12} className="p-0" >
            <div className="login-card " >
                <div className={` bg-white p-4 rounded`}>
                    <SignInForm/>
                </div>
            </div>
        </Col>
      </Row>
    </Container>
)
}
