// src/components/ListOfImage.jsx

import React, { Fragment, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Button, Input } from 'reactstrap';
import { IMAGE_GALLERY } from '../../../Constant';
import { H5 } from '../../../AbstractElements'; // تأكد من المسار الصحيح
import { useGetGalleryByCategoryQuery, useUpdatePhotoInGalleryMutation } from '../../../redux/apis/apiSlice'; // تأكد من المسار الصحيح
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ListOfImage = () => {
  // حالة التحكم في عرض الصور المفتوحة (إذا كان لديك Lightbox أو ما شابه)
  const [photoIndex, setPhotoIndex] = useState({ index: 0, isOpen: false });

  // حالة تحديد الصورة التي يتم تحريرها
  const [editingImageId, setEditingImageId] = useState(null);

  // حالة تخزين العناوين الجديدة لكل صورة
  const [newTitle, setNewTitle] = useState({});

  // حالة تخزين ملفات الصور الجديدة لكل صورة
  const [newImageFile, setNewImageFile] = useState({});

  // استخدام الاستعلام لجلب بيانات المعرض حسب الفئة
  const { data: galleryData, error: galleryError, isLoading: galleryLoading, refetch } = useGetGalleryByCategoryQuery();

  // استخدام نهاية التحديث من RTK Query
  const [updateImage, { isLoading: isUpdating }] = useUpdatePhotoInGalleryMutation();

  // دالة التعامل مع بدء التحرير
  const handleEditClick = (imageId, currentTitle) => {
    setEditingImageId(imageId);
    setNewTitle((prevTitles) => ({ ...prevTitles, [imageId]: currentTitle || '' }));
  };

  // دالة التعامل مع تغيير العنوان الجديد
  const handleTitleChange = (e, imageId) => {
    const value = e.target.value;
    setNewTitle((prevTitles) => ({ ...prevTitles, [imageId]: value }));
  };

  // دالة التعامل مع تغيير ملف الصورة الجديد
  const handleImageChange = (e, imageId) => {
    const file = e.target.files[0];
    if (file) {
      setNewImageFile((prevFiles) => ({ ...prevFiles, [imageId]: file }));
    }
  };

  // دالة التعامل مع حفظ التغييرات باستخدام RTK Query
  const handleSave = async (galleryId, imageId) => {
    const title = newTitle[imageId];
    const imageFile = newImageFile[imageId];

    // إعداد البيانات للإرسال باستخدام FormData
    const formData = new FormData();
    formData.append('title', title);
    if (imageFile) {
      formData.append('image', imageFile);
    }

    try {
      // استدعاء نهاية التحديث
      await updateImage({ galleryId, imageId, formData }).unwrap();

      // إعادة تعيين حالة التحرير والعناوين وملفات الصور
      setEditingImageId(null);
      setNewTitle((prevTitles) => {
        const updatedTitles = { ...prevTitles };
        delete updatedTitles[imageId];
        return updatedTitles;
      });
      setNewImageFile((prevFiles) => {
        const updatedFiles = { ...prevFiles };
        delete updatedFiles[imageId];
        return updatedFiles;
      });

      // إعادة جلب البيانات المحدثة
      await refetch();
      toast.success('تم تحديث الصورة بنجاح!');
    } catch (error) {
      console.error('Error updating image:', error);
      toast.error('حدث خطأ أثناء تحديث الصورة.');
    }
  };

  // دالة التعامل مع إلغاء التحرير
  const handleCancel = (imageId) => {
    setEditingImageId(null);
    setNewTitle((prevTitles) => {
      const updatedTitles = { ...prevTitles };
      delete updatedTitles[imageId];
      return updatedTitles;
    });
    setNewImageFile((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      delete updatedFiles[imageId];
      return updatedFiles;
    });
  };

  // عرض رسالة تحميل أثناء جلب البيانات
  if (galleryLoading) {
    return <div>جارٍ التحميل...</div>;
  }

  // عرض رسالة خطأ في حالة حدوث خطأ أثناء جلب البيانات
  if (galleryError) {
    return <div>حدث خطأ أثناء جلب البيانات: {galleryError.message}</div>;
  }

  return (
      <Fragment>
        <ToastContainer />
        {galleryData && Array.isArray(galleryData.data) && galleryData.data.length > 0 ? (
            <Col sm="12">
              <Card>
                <CardHeader className='pb-0'>
                  <H5>{IMAGE_GALLERY}</H5>
                </CardHeader>
                <CardBody className="my-gallery row">
                  {galleryData.data.map((galleryItem) => (
                      galleryItem.images && Array.isArray(galleryItem.images) && galleryItem.images.map((image, index) => (
                          <figure className="col-xl-3 col-sm-6 mb-4" key={image._id} style={{ textAlign: 'center' }}>
                            {/* استخدام <img> لعرض الصورة */}
                            <img
                                src={image.path}
                                alt={image.title || 'Gallery Image'}
                                className="img-thumbnail"
                                onClick={() => setPhotoIndex({ index, isOpen: true })}
                                style={{ width: '100%', height: 'auto' }}
                            />
                            {/* عرض حقل تحرير أو العنوان */}
                            {editingImageId === image._id ? (
                                <div className="mt-2">
                                  <Input
                                      type="text"
                                      value={newTitle[image._id] || ''}
                                      onChange={(e) => handleTitleChange(e, image._id)}
                                      className="mb-2"
                                      placeholder="أدخل عنوان جديد"
                                  />
                                  <Input
                                      type="file"
                                      onChange={(e) => handleImageChange(e, image._id)}
                                      className="mb-2"
                                  />
                                  <div>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        onClick={() => handleSave(galleryItem._id, image._id)}
                                        disabled={isUpdating}
                                    >
                                      {isUpdating ? 'جارٍ الحفظ...' : 'حفظ'}
                                    </Button>
                                    <Button
                                        color="secondary"
                                        size="sm"
                                        className="ms-2"
                                        onClick={() => handleCancel(image._id)}
                                    >
                                      إلغاء
                                    </Button>
                                  </div>
                                </div>
                            ) : (
                                <div className="mt-2">
                                  <p className="mb-1">{image.title || 'بدون عنوان'}</p>
                                  {/*<Button*/}
                                  {/*    color="warning"*/}
                                  {/*    size="sm"*/}
                                  {/*    onClick={() => handleEditClick(image._id, image.title)}*/}
                                  {/*>*/}
                                  {/*  تحرير*/}
                                  {/*</Button>*/}
                                </div>
                            )}
                          </figure>
                      ))
                  ))}
                </CardBody>
              </Card>
            </Col>
        ) : (
            <div>لا توجد صور للعرض.</div>
        )}

        {/* يمكنك إضافة Lightbox أو أي مكون آخر لعرض الصور المفتوحة هنا */}
        {/* مثال على Lightbox:
      {photoIndex.isOpen && (
        <Lightbox
          mainSrc={galleryData.data[photoIndex.index].images[photoIndex.index].path}
          onCloseRequest={() => setPhotoIndex({ ...photoIndex, isOpen: false })}
        />
      )}
      */}
      </Fragment>
  );
};

export default ListOfImage;