
import React, { Fragment } from 'react';
import {Card, CardHeader, Col, Container, Row} from "reactstrap";
import {H5} from "../../AbstractElements";
import {
    CustomCheckbox,
    CustomRadio,
    SquareCheckbox
} from "../../Constant";
import CustomCheckboxs from "../../Component/Forms/Form Control/Radio Checkbox/CustomCheckbox";

import TableHeadOptionsClass from "../../Component/Tables/Reactstrap/StylingTable/TableHeadOptions";
import AddCustmoerForm from "../../Component/Customers/FormAddCustomer";
import AddCustomerAdressForm from "../../Component/Customers/AddCustmoerAdressForm";
import CustomersTable from "../../Component/Customers/CustomersTable";

const Customers = () => {
    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <H5>{SquareCheckbox}</H5>
                                <CustomersTable />
                            </CardHeader>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card>
                            <CardHeader className="pb-0">
                                <H5>{CustomRadio}</H5>
                            </CardHeader>
                            <AddCustmoerForm/>
                            {/*<CustomCheckboxs />*/}
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card>
                            <CardHeader className="pb-0">
                                <H5>{CustomCheckbox}</H5>
                            </CardHeader>
                            <AddCustomerAdressForm />

                        </Card>
                    </Col>


                </Row>
            </Container>
        </Fragment>
    );
};
export default Customers;