export const cities= {
    "data": [
    "أبها",
    "الباحة",
    "الجوف",
    "الدمام",
    "الرياض",
    "المدينة المنورة",
    "الطائف",
    "الظهران",
    "العلا",
    "القصيم",
    "القطيف",
    "الهفوف",
    "بريدة",
    "تبوك",
    "جازان",
    "حائل",
    "خميس مشيط",
    "دومة الجندل",
    "رابغ",
    "سكاكا",
    "شرورة",
    "عرعر",
    "مكة المكرمة",
    "نجران",
    "ينبع"
]
}