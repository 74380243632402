// src/redux/apis/paymentsEndpoints.js

export const paymentsEndpoints = (builder) => ({
    // endpoint لجلب الدفعات حسب الموظف مع إمكانية تمرير متغيرات إضافية مثل customerName, startDate, endDate, amount, year
    getPaymentsByEmployee: builder.query({
        query: ({
                    employeeId,
                    page = 1,
                    limit = 10,
                    customerName,
                    startDate,
                    endDate,
                    amount,
                    year, // متغير السنة
                }) => ({
            url: `api/payments/${employeeId}/payments`,
            params: { page, limit, customerName, startDate, endDate, amount, year },
        }),
        providesTags: (result, error, { employeeId }) => [
            { type: 'Payments', id: 'LIST' },
            { type: 'Payments', id: employeeId },
        ],
    }),

    // endpoint لجلب السنوات التي تحتوي على بيانات دفعات
    getYearsWithPayments: builder.query({
        query: (employeeId) => `api/payments/${employeeId}/payments/years`,
        keepUnusedDataFor: 5,
    }),

    // endpoint جديد لجلب إجمالي الدفعات لسنة معينة
    getTotalPaymentsByYear: builder.query({
        query: ({ employeeId, year }) => `api/payments/total/${employeeId}/${year}`,
        // يمكنك ضبط providesTags إذا كنت تحتاج إلى تحديثات تلقائية
        providesTags: (result, error, { employeeId, year }) => [
            { type: 'Payments', id: `${employeeId}-${year}-total` },
        ],
    }),
});