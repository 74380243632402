import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: {
                    'General': 'General',
                    'Dashboards,Widgets': 'Dashboards,Widgets',
                    'Dashboard': 'Dashboard',
                    'Default': 'Default',
                    'Ecommerce': 'Ecommerce',
                    'Widgets': 'Widgets',
                    'Chart': 'Chart',
                    'Applications': 'Applications',
                    'Ready to use Apps': 'Ready to use Apps'
                }
            },
            ae: {
                translations: {
                    'General': 'عام',
                    'Dashboards': 'لوحات القيادة والحاجيات',
                    'Widgets':'لوحات التحكم',
                    'Dashboard': 'لوحة التحكم',
                    'Default': 'إفتراضي',
                    'Ecommerce': 'التجارة الإلكترونية',
                    'Chart': 'مخطط',
                    'Applications': 'التطبيقات',
                    'Ready to use Apps': 'جاهز لاستخدام التطبيقات',
                    'Sign in to account': 'تسجيل الدخول ',
                    'Email': 'الايميلات',
                    'Password': 'كلمة المرور',
                    'Remember me': 'تذكرني',
                    'Forgot Password?': 'هل نسيت كلمة المرور��',
                    'Sign in': 'تسجيل الدخول',
                    'Create Account': 'انشا�� حساب ��ديد',
                    'Sign up': 'انشا حساب',
                    'Welcome to': 'مرحبا بك في',
                    'Sign Out': 'تسجيل الخروج',
                    'Profile': 'الملف الشخصي',
                    'About': 'عنا',
                    'Help': 'مسا��دة',
                    'Contact': 'توا��ل معنا',
                    'Support': 'الدعم',
                    'FAQ': 'أس��لة وتعليمات عامة',
                    'Blog': 'مدونة',
                    'Terms & Conditions': 'الشروط والأحكام',
                    'Privacy Policy': 'سيا��ة الخصوصية',
                    'Settings': 'ال��عدادات',
                    'Help Center': 'مركز المسا��دة',
                    'Help Desk': 'مكتب المسا��دة',
                    'Chat Support': 'دعم الدردشة',
                    'Contact Us': 'توا��ل معنا',
                    'Submit': '��رسال',
                    'Name': 'الا��م',
                    'SEND':'إرسال الرمز'


                }
            },
        },
        fallbackLng: 'en',
        debug: false,

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
