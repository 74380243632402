import { useState } from "react";
import {Alert, Col, Form, FormGroup, Input, Label} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Btn, H4, H6, P } from "../AbstractElements";
import { SignIn } from "../Constant";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import {signInFailure, signInStart, signInSuccess} from "../redux/user/userSlice";

export default function SignInForm() {
  const history = useNavigate();
  const [formData, setFormData] = useState({});
  const { loading, error: errorMessage } = useSelector((state) => state.user);
  const[loading1 , setLoading1] = useState(false)
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value.trim() });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading1(true)
    if (!formData.email || !formData.password) {
      setLoading1(false)
      toast.error('Please fill all the fields');
      // return dispatch(signInFailure('Please fill all the fields'));

    }
    try {
      dispatch(signInStart());
      const res = await fetch(`${process.env.REACT_APP_URL_BACKEND}/api/auth/signin`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      const data = await res.json();
      if (data.success === false) {
        dispatch(signInFailure(data.message));
      }
      if (res.ok) {
        dispatch(signInSuccess(data));
        toast.success("Login Success...!");
        history(`${process.env.PUBLIC_URL}/dashboard/default`);
        localStorage.setItem("login", JSON.stringify(true));
        localStorage.setItem("token", JSON.stringify(data.token));
      }
    } catch (error) {
      toast.error(error?.response?.data.message);
      setLoading1(false)
      dispatch(signInFailure(error.message));
    }
  };
  return (
      <div>
        <div className="login-main">
          <Form className="theme-form" onSubmit={handleSubmit}>
            <H4>{"Sign in to account"}</H4>
            <P>{"Enter your email & password to login"}</P>
            <FormGroup>
              <Col>
                <Label>{"Email address"}</Label>
              </Col>
              <Input
                  type="email"
                  id='email'
                  onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Col>
                <Label>{"Password"}</Label>
              </Col>
              <div className="form-input position-relative">
                <Input
                    type= "password"
                    name="login[password]"
                    id='password'
                    onChange={handleChange}
                />
              </div>
            </FormGroup>
            <div className="mb-0 form-group">
              <div className="checkbox p-0">
                <Input id="checkbox1" type="checkbox" />
                <Label className="text-muted" htmlFor="checkbox1">
                  {"Remember password"}
                </Label>
              </div>
              <Link
                  className="link"
                  to={`${process.env.PUBLIC_URL}/pages/authentication/forget-pwd`}
              >
                {"Forgot password?"}
              </Link>
              <div className="text-end mt-3">
                <Btn color="primary" className="btn-block w-100" disabled={loading1} onClick={handleSubmit}>
                  {SignIn}
                </Btn>
              </div>
            </div>
            {errorMessage && (
                <Alert className='mt-5' color='failure'>
                  {errorMessage}
                </Alert>
            )}
            <P className="mt-4 mb-0 text-center">
              {"Don't have account?"}
              <Link
                  className="ms-2"
                  to={`${process.env.PUBLIC_URL}/pages/authentication/register-simpleimg`}
              >
                {"Create Account"}
              </Link>
            </P>
          </Form>
        </div>
      </div>
  );
}