import React, { Fragment, useContext } from 'react';
import { Container } from 'reactstrap';
import FilterContext from "../../../_helper/ecommerce/filter";
import ProductContain from "../../../Component/Posts/AllPosts";
import SinkList from "../cardForWash/CardForWashList";


const AllPosts = () => {
    const { IsFilter } = useContext(FilterContext);
    return (
        <Fragment>
            <Container fluid={true} className={`product-wrapper ${IsFilter ? 'sidebaron' : ''}`}>
                <div className="product-grid">
                    <SinkList/>
                    <ProductContain/>
                </div>
            </Container>
        </Fragment>
    );
};
export default AllPosts;