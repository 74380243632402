import React, { useState } from 'react';
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Container,
    Row,
    Col,
    FormFeedback,
} from 'reactstrap';
import axios from 'axios';

const InvoiceForm = () => {
    // الحالة العامة للنموذج
    const [formData, setFormData] = useState({
        // API Keys and Headers
        tokenKey: '',
        tokenSecret: '',
        command: '2', // ثابت لمشاركة الفواتير

        // POST Body
        invoiceType: '1', // ثابت لفاتورة ضريبية مبسطة
        mode: '2', // Simulation Mode افتراضيًا
        uuid: '',
        idInvoice: '',
        invoiceDate: '',
        currencyCode: 'SAR', // مثال افتراضي
        previousHash:
            'NWZlY2ViNjZmZmM4NmYzOGQ5NTI3ODZjNmQ2OTZjNzljMmRiYzIzOWRkNGU5MWI0NjcyOWQ3M2EyN2ZiNTdlOQ==',
        prepaidAmount: '0.00',
        total: '',
        discount: '',
        taxAmount: '',

        // Seller Address
        seller: {
            otherSellerID: '',
            streetName: '',
            buildingNumber: '',
            citySubdivisionName: '',
            cityName: '',
            postalZone: '',
            identificationCode: 'SA',
            taxNo: '',
            registrationName: '',
        },

        // Customer Address
        customer: {
            socialID: '',
            socialIDType: '', // لاستخدامه في حال وجود إعفاء ضريبي
            streetName: '',
            buildingNumber: '',
            citySubdivisionName: '',
            cityName: '',
            postalZone: '',
            identificationCode: 'SA',
            taxNo: '',
            registrationName: '',
        },

        // Invoice Lines
        invoiceLines: [
            {
                unitPrice: '',
                itemName: '',
                taxPercent: '15',
                taxAmount: '',
                discount: '0.00',
                taxExemptionReason: '',
                taxExemptionReasonCode: '',
            },
        ],
    });

    // الحالة للتحكم في التحقق من صحة الإدخالات
    const [errors, setErrors] = useState({});

    // دالة لتحديث حالة النموذج
    const handleChange = (e) => {
        const { name, value } = e.target;

        // التعامل مع الحقول المتداخلة مثل البائع والعميل
        if (name.startsWith('seller.')) {
            const field = name.split('.')[1];
            setFormData((prev) => ({
                ...prev,
                seller: {
                    ...prev.seller,
                    [field]: value,
                },
            }));
        } else if (name.startsWith('customer.')) {
            const field = name.split('.')[1];
            setFormData((prev) => ({
                ...prev,
                customer: {
                    ...prev.customer,
                    [field]: value,
                },
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    // دالة لإضافة سطر فاتورة جديد
    const addInvoiceLine = () => {
        setFormData((prev) => ({
            ...prev,
            invoiceLines: [
                ...prev.invoiceLines,
                {
                    unitPrice: '',
                    itemName: '',
                    taxPercent: '15',
                    taxAmount: '',
                    discount: '0.00',
                    taxExemptionReason: '',
                    taxExemptionReasonCode: '',
                },
            ],
        }));
    };

    // دالة لحذف سطر فاتورة
    const removeInvoiceLine = (index) => {
        const lines = [...formData.invoiceLines];
        lines.splice(index, 1);
        setFormData((prev) => ({
            ...prev,
            invoiceLines: lines,
        }));
    };

    // دالة للتحقق من صحة الإدخالات
    const validate = () => {
        const newErrors = {};

        // التحقق من الحقول الإلزامية
        if (!formData.tokenKey) newErrors.tokenKey = 'مطلوب';
        if (!formData.tokenSecret) newErrors.tokenSecret = 'مطلوب';
        if (!formData.uuid) newErrors.uuid = 'مطلوب';
        if (!formData.idInvoice) newErrors.idInvoice = 'مطلوب';
        if (!formData.invoiceDate) newErrors.invoiceDate = 'مطلوب';
        if (!formData.currencyCode) newErrors.currencyCode = 'مطلوب';
        if (!formData.previousHash) newErrors.previousHash = 'مطلوب';
        if (!formData.total) newErrors.total = 'مطلوب';
        if (!formData.discount) newErrors.discount = 'مطلوب';
        if (!formData.taxAmount) newErrors.taxAmount = 'مطلوب';

        // التحقق من بيانات البائع
        const seller = formData.seller;
        if (!seller.otherSellerID) newErrors['seller.otherSellerID'] = 'مطلوب';
        if (!seller.streetName) newErrors['seller.streetName'] = 'مطلوب';
        if (!seller.buildingNumber || !/^\d{4}$/.test(seller.buildingNumber))
            newErrors['seller.buildingNumber'] = 'يجب أن يتكون من أربع أرقام';
        if (!seller.citySubdivisionName)
            newErrors['seller.citySubdivisionName'] = 'مطلوب';
        if (!seller.cityName) newErrors['seller.cityName'] = 'مطلوب';
        if (
            !seller.postalZone ||
            !/^\d{5,6}$/.test(seller.postalZone)
        )
            newErrors['seller.postalZone'] = 'يجب أن يتكون من 6 أرقام';
        if (!seller.taxNo) newErrors['seller.taxNo'] = 'مطلوب';
        if (!seller.registrationName)
            newErrors['seller.registrationName'] = 'مطلوب';

        // التحقق من بيانات العميل
        const customer = formData.customer;
        // إذا كانت الفاتورة ضريبية أو يوجد إعفاء ضريبي، فإن بعض الحقول إلزامية
        const isTaxInvoice = formData.invoiceType === '1';
        const hasTaxExemption = customer.socialID;

        if (isTaxInvoice || hasTaxExemption) {
            if (!customer.socialID) newErrors['customer.socialID'] = 'مطلوب';
            if (!customer.socialIDType)
                newErrors['customer.socialIDType'] = 'مطلوب';

            if (!customer.streetName)
                newErrors['customer.streetName'] = 'مطلوب';
            if (
                !customer.buildingNumber ||
                !/^\d{4}$/.test(customer.buildingNumber)
            )
                newErrors['customer.buildingNumber'] =
                    'يجب أن يتكون من أربع أرقام';
            if (!customer.citySubdivisionName)
                newErrors['customer.citySubdivisionName'] = 'مطلوب';
            if (!customer.cityName)
                newErrors['customer.cityName'] = 'مطلوب';
            if (
                !customer.postalZone ||
                !/^\d{5,6}$/.test(customer.postalZone)
            )
                newErrors['customer.postalZone'] = 'يجب أن يتكون من 6 أرقام';
        }

        if (!customer.identificationCode)
            newErrors['customer.identificationCode'] = 'مطلوب';
        if (isTaxInvoice && !customer.taxNo)
            newErrors['customer.taxNo'] = 'مطلوب';
        if (
            (isTaxInvoice || hasTaxExemption) &&
            !customer.registrationName
        )
            newErrors['customer.registrationName'] = 'مطلوب';

        // التحقق من خطوط الفاتورة
        formData.invoiceLines.forEach((line, index) => {
            if (!line.unitPrice) {
                newErrors[`invoiceLines.${index}.unitPrice`] = 'مطلوب';
            } else if (!/^\d+(\.\d{1,2})?$/.test(line.unitPrice)) {
                newErrors[`invoiceLines.${index}.unitPrice`] =
                    'يجب أن يكون رقم حقيقي بحد أقصى خانتين عشريتين';
            }

            if (!line.itemName)
                newErrors[`invoiceLines.${index}.itemName`] = 'مطلوب';

            if (!line.taxPercent)
                newErrors[`invoiceLines.${index}.taxPercent`] = 'مطلوب';

            if (!line.taxAmount) {
                newErrors[`invoiceLines.${index}.taxAmount`] = 'مطلوب';
            } else if (!/^\d+(\.\d{1,2})?$/.test(line.taxAmount)) {
                newErrors[`invoiceLines.${index}.taxAmount`] =
                    'يجب أن يكون رقم حقيقي بحد أقصى خانتين عشريتين';
            }

            if (line.discount === '') {
                newErrors[`invoiceLines.${index}.discount`] = 'مطلوب';
            } else if (!/^\d+(\.\d{1,2})?$/.test(line.discount)) {
                newErrors[`invoiceLines.${index}.discount`] =
                    'يجب أن يكون رقم حقيقي بحد أقصى خانتين عشريتين';
            }

            if (hasTaxExemption) {
                if (!line.taxExemptionReason)
                    newErrors[`invoiceLines.${index}.taxExemptionReason`] =
                        'مطلوب';
                if (!line.taxExemptionReasonCode)
                    newErrors[`invoiceLines.${index}.taxExemptionReasonCode`] =
                        'مطلوب';
            }
        });

        setErrors(newErrors);

        // إرجاع ما إذا كان النموذج صالحًا أم لا
        return Object.keys(newErrors).length === 0;
    };

    // دالة لمعالجة إرسال النموذج
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) {
            alert('يرجى تصحيح الأخطاء في النموذج.');
            return;
        }

        // إعداد رؤوس الطلب
        const headers = {
            'Content-Type': 'application/json',
            'TokenKey': formData.tokenKey,
            'TokenSecret': formData.tokenSecret,
            'Command': formData.command,
        };

        // إعداد جسم الطلب
        const body = {
            InvoiceType: parseInt(formData.invoiceType),
            Mode: parseInt(formData.mode),
            UUID: formData.uuid,
            ID_Invoice: formData.idInvoice,
            InvoiceDate: formData.invoiceDate,
            CurrencyCode: formData.currencyCode,
            PreviousHash: formData.previousHash,
            PrepaidAmount: parseFloat(formData.prepaidAmount),
            Total: parseFloat(formData.total),
            Discount: parseFloat(formData.discount),
            TaxAmount: parseFloat(formData.taxAmount),
            SellerAddress: {
                OtherSellerID: formData.seller.otherSellerID,
                StreetName: formData.seller.streetName,
                BuildingNumber: formData.seller.buildingNumber,
                CitySubdivisionName: formData.seller.citySubdivisionName,
                CityName: formData.seller.cityName,
                PostalZone: formData.seller.postalZone,
                IdentificationCode: formData.seller.identificationCode,
                TaxNo: formData.seller.taxNo,
                RegistrationName: formData.seller.registrationName,
            },
            CustomerAddress: {
                SocialID: formData.customer.socialID,
                SocialIDType: formData.customer.socialIDType,
                StreetName: formData.customer.streetName,
                BuildingNumber: formData.customer.buildingNumber,
                CitySubdivisionName: formData.customer.citySubdivisionName,
                CityName: formData.customer.cityName,
                PostalZone: formData.customer.postalZone,
                IdentificationCode: formData.customer.identificationCode,
                TaxNo: formData.customer.taxNo,
                RegistrationName: formData.customer.registrationName,
            },
            InvoiceLines: formData.invoiceLines.map((line) => ({
                UnitPrice: parseFloat(line.unitPrice),
                ItemName: line.itemName,
                TaxPercent: parseInt(line.taxPercent),
                TaxAmount: parseFloat(line.taxAmount),
                Discount: parseFloat(line.discount),
                ...(line.taxExemptionReason && {
                    TaxExemptionReason: line.taxExemptionReason,
                }),
                ...(line.taxExemptionReasonCode && {
                    TaxExemptionReasonCode: line.taxExemptionReasonCode,
                }),
            })),
        };

        try {
            // إرسال الطلب باستخدام axios
            const response = await axios.post('URL_API_HERE', body, { headers });

            // التعامل مع الاستجابة الناجحة
            if (response.data.Reported_Clearnced) {
                alert('تم مشاركة الفاتورة بنجاح!');
            } else {
                alert('فشل مشاركة الفاتورة. تحقق من التفاصيل.');
            }

            console.log(response.data);
        } catch (error) {
            console.error('خطأ في إرسال الفاتورة:', error);
            alert('حدث خطأ أثناء إرسال الفاتورة.');
        }
    };

    return (
        <Container>
            <h1 className="my-4">نموذج الفاتورة</h1>
            <Form onSubmit={handleSubmit}>
                {/* API Keys and Headers */}
                <h4>مصادقة الاتصال</h4>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="tokenKey">Token Key</Label>
                            <Input
                                type="text"
                                name="tokenKey"
                                id="tokenKey"
                                value={formData.tokenKey}
                                onChange={handleChange}
                                invalid={!!errors.tokenKey}
                            />
                            {errors.tokenKey && (
                                <FormFeedback>{errors.tokenKey}</FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="tokenSecret">Token Secret</Label>
                            <Input
                                type="text"
                                name="tokenSecret"
                                id="tokenSecret"
                                value={formData.tokenSecret}
                                onChange={handleChange}
                                invalid={!!errors.tokenSecret}
                            />
                            {errors.tokenSecret && (
                                <FormFeedback>{errors.tokenSecret}</FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                </Row>

                {/* POST Body */}
                <h4>بيانات الفاتورة</h4>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="uuid">UUID</Label>
                            <Input
                                type="text"
                                name="uuid"
                                id="uuid"
                                value={formData.uuid}
                                onChange={handleChange}
                                invalid={!!errors.uuid}
                            />
                            {errors.uuid && (
                                <FormFeedback>{errors.uuid}</FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="idInvoice">رقم الفاتورة لدى المورد</Label>
                            <Input
                                type="text"
                                name="idInvoice"
                                id="idInvoice"
                                value={formData.idInvoice}
                                onChange={handleChange}
                                invalid={!!errors.idInvoice}
                            />
                            {errors.idInvoice && (
                                <FormFeedback>{errors.idInvoice}</FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                </Row>

                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="invoiceDate">تاريخ الفاتورة</Label>
                            <Input
                                type="datetime-local"
                                name="invoiceDate"
                                id="invoiceDate"
                                value={formData.invoiceDate}
                                onChange={handleChange}
                                invalid={!!errors.invoiceDate}
                            />
                            {errors.invoiceDate && (
                                <FormFeedback>{errors.invoiceDate}</FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="currencyCode">رمز العملة</Label>
                            <Input
                                type="text"
                                name="currencyCode"
                                id="currencyCode"
                                value={formData.currencyCode}
                                onChange={handleChange}
                                invalid={!!errors.currencyCode}
                            />
                            {errors.currencyCode && (
                                <FormFeedback>{errors.currencyCode}</FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                </Row>

                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="previousHash">Previous Hash</Label>
                            <Input
                                type="text"
                                name="previousHash"
                                id="previousHash"
                                value={formData.previousHash}
                                onChange={handleChange}
                                invalid={!!errors.previousHash}
                            />
                            {errors.previousHash && (
                                <FormFeedback>{errors.previousHash}</FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="prepaidAmount">Prepaid Amount</Label>
                            <Input
                                type="number"
                                step="0.01"
                                name="prepaidAmount"
                                id="prepaidAmount"
                                value={formData.prepaidAmount}
                                onChange={handleChange}
                                invalid={!!errors.prepaidAmount}
                            />
                            {errors.prepaidAmount && (
                                <FormFeedback>{errors.prepaidAmount}</FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                </Row>

                <Row form>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="total">Total</Label>
                            <Input
                                type="number"
                                step="0.01"
                                name="total"
                                id="total"
                                value={formData.total}
                                onChange={handleChange}
                                invalid={!!errors.total}
                            />
                            {errors.total && (
                                <FormFeedback>{errors.total}</FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="discount">Discount</Label>
                            <Input
                                type="number"
                                step="0.01"
                                name="discount"
                                id="discount"
                                value={formData.discount}
                                onChange={handleChange}
                                invalid={!!errors.discount}
                            />
                            {errors.discount && (
                                <FormFeedback>{errors.discount}</FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="taxAmount">Tax Amount</Label>
                            <Input
                                type="number"
                                step="0.01"
                                name="taxAmount"
                                id="taxAmount"
                                value={formData.taxAmount}
                                onChange={handleChange}
                                invalid={!!errors.taxAmount}
                            />
                            {errors.taxAmount && (
                                <FormFeedback>{errors.taxAmount}</FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                </Row>

                {/* Seller Address */}
                <h4>بيانات البائع</h4>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="seller.otherSellerID">Other Seller ID</Label>
                            <Input
                                type="select"
                                name="seller.otherSellerID"
                                id="seller.otherSellerID"
                                value={formData.seller.otherSellerID}
                                onChange={handleChange}
                                invalid={!!errors['seller.otherSellerID']}
                            >
                                <option value="">اختر</option>
                                <option value="CRN">CRN - رقم السجل التجاري</option>
                                <option value="MOM">MOM - ترخيص Momra</option>
                                <option value="MLS">MLS - ترخيص MLSD</option>
                                <option value="SAG">SAG - ترخيص Sagia</option>
                                <option value="OTH">OTH - معرفات أخرى</option>
                            </Input>
                            {errors['seller.otherSellerID'] && (
                                <FormFeedback>
                                    {errors['seller.otherSellerID']}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="seller.taxNo">Tax No</Label>
                            <Input
                                type="text"
                                name="seller.taxNo"
                                id="seller.taxNo"
                                value={formData.seller.taxNo}
                                onChange={handleChange}
                                invalid={!!errors['seller.taxNo']}
                            />
                            {errors['seller.taxNo'] && (
                                <FormFeedback>{errors['seller.taxNo']}</FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                </Row>

                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="seller.streetName">Street Name</Label>
                            <Input
                                type="text"
                                name="seller.streetName"
                                id="seller.streetName"
                                value={formData.seller.streetName}
                                onChange={handleChange}
                                invalid={!!errors['seller.streetName']}
                            />
                            {errors['seller.streetName'] && (
                                <FormFeedback>
                                    {errors['seller.streetName']}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="seller.buildingNumber">Building Number</Label>
                            <Input
                                type="text"
                                name="seller.buildingNumber"
                                id="seller.buildingNumber"
                                value={formData.seller.buildingNumber}
                                onChange={handleChange}
                                invalid={!!errors['seller.buildingNumber']}
                            />
                            {errors['seller.buildingNumber'] && (
                                <FormFeedback>
                                    {errors['seller.buildingNumber']}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                </Row>

                <Row form>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="seller.citySubdivisionName">
                                City Subdivision Name
                            </Label>
                            <Input
                                type="text"
                                name="seller.citySubdivisionName"
                                id="seller.citySubdivisionName"
                                value={formData.seller.citySubdivisionName}
                                onChange={handleChange}
                                invalid={!!errors['seller.citySubdivisionName']}
                            />
                            {errors['seller.citySubdivisionName'] && (
                                <FormFeedback>
                                    {errors['seller.citySubdivisionName']}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="seller.cityName">City Name</Label>
                            <Input
                                type="text"
                                name="seller.cityName"
                                id="seller.cityName"
                                value={formData.seller.cityName}
                                onChange={handleChange}
                                invalid={!!errors['seller.cityName']}
                            />
                            {errors['seller.cityName'] && (
                                <FormFeedback>
                                    {errors['seller.cityName']}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="seller.postalZone">Postal Zone</Label>
                            <Input
                                type="text"
                                name="seller.postalZone"
                                id="seller.postalZone"
                                value={formData.seller.postalZone}
                                onChange={handleChange}
                                invalid={!!errors['seller.postalZone']}
                            />
                            {errors['seller.postalZone'] && (
                                <FormFeedback>
                                    {errors['seller.postalZone']}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                </Row>

                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="seller.identificationCode">
                                Identification Code
                            </Label>
                            <Input
                                type="text"
                                name="seller.identificationCode"
                                id="seller.identificationCode"
                                value={formData.seller.identificationCode}
                                onChange={handleChange}
                                invalid={!!errors['seller.identificationCode']}
                            />
                            {errors['seller.identificationCode'] && (
                                <FormFeedback>
                                    {errors['seller.identificationCode']}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="seller.registrationName">Registration Name</Label>
                            <Input
                                type="text"
                                name="seller.registrationName"
                                id="seller.registrationName"
                                value={formData.seller.registrationName}
                                onChange={handleChange}
                                invalid={!!errors['seller.registrationName']}
                            />
                            {errors['seller.registrationName'] && (
                                <FormFeedback>
                                    {errors['seller.registrationName']}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                </Row>

                {/* Customer Address */}
                <h4>بيانات العميل</h4>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="customer.socialID">Social ID</Label>
                            <Input
                                type="select"
                                name="customer.socialIDType"
                                id="customer.socialIDType"
                                value={formData.customer.socialIDType}
                                onChange={handleChange}
                                invalid={!!errors['customer.socialID']}
                            >
                                <option value="">اختر نوع Social ID</option>
                                <option value="TIN">TIN - رقم التعريف الضريبي</option>
                                <option value="CRN">
                                    CRN - رقم السجل التجاري
                                </option>
                                <option value="MOM">MOM - ترخيص Momra</option>
                                <option value="MLS">MLS - ترخيص MLSD</option>
                                <option value="700">700 - رقم 700</option>
                                <option value="SAG">SAG - ترخيص Sagia</option>
                                <option value="NAT">NAT - الهوية الوطنية</option>
                                <option value="GCC">GCC - معرف GCC</option>
                                <option value="IQA">IQA - رقم الإقامة</option>
                                <option value="PAS">PAS - معرف جواز السفر</option>
                                <option value="OTH">OTH - معرفات أخرى</option>
                            </Input>
                            {errors['customer.socialID'] && (
                                <FormFeedback>
                                    {errors['customer.socialID']}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="customer.socialID">Social ID Value</Label>
                            <Input
                                type="text"
                                name="customer.socialID"
                                id="customer.socialID"
                                value={formData.customer.socialID}
                                onChange={handleChange}
                                invalid={!!errors['customer.socialID']}
                            />
                            {errors['customer.socialID'] && (
                                <FormFeedback>
                                    {errors['customer.socialID']}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                </Row>

                {/* إذا كانت الفاتورة ضريبية أو يوجد إعفاء ضريبي، تظهر بعض الحقول */}
                {(formData.invoiceType === '1' ||
                    formData.customer.socialID) && (
                    <>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="customer.streetName">Street Name</Label>
                                    <Input
                                        type="text"
                                        name="customer.streetName"
                                        id="customer.streetName"
                                        value={formData.customer.streetName}
                                        onChange={handleChange}
                                        invalid={!!errors['customer.streetName']}
                                    />
                                    {errors['customer.streetName'] && (
                                        <FormFeedback>
                                            {errors['customer.streetName']}
                                        </FormFeedback>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="customer.buildingNumber">
                                        Building Number
                                    </Label>
                                    <Input
                                        type="text"
                                        name="customer.buildingNumber"
                                        id="customer.buildingNumber"
                                        value={formData.customer.buildingNumber}
                                        onChange={handleChange}
                                        invalid={!!errors['customer.buildingNumber']}
                                    />
                                    {errors['customer.buildingNumber'] && (
                                        <FormFeedback>
                                            {errors['customer.buildingNumber']}
                                        </FormFeedback>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="customer.citySubdivisionName">
                                        City Subdivision Name
                                    </Label>
                                    <Input
                                        type="text"
                                        name="customer.citySubdivisionName"
                                        id="customer.citySubdivisionName"
                                        value={formData.customer.citySubdivisionName}
                                        onChange={handleChange}
                                        invalid={!!errors['customer.citySubdivisionName']}
                                    />
                                    {errors['customer.citySubdivisionName'] && (
                                        <FormFeedback>
                                            {errors['customer.citySubdivisionName']}
                                        </FormFeedback>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="customer.cityName">City Name</Label>
                                    <Input
                                        type="text"
                                        name="customer.cityName"
                                        id="customer.cityName"
                                        value={formData.customer.cityName}
                                        onChange={handleChange}
                                        invalid={!!errors['customer.cityName']}
                                    />
                                    {errors['customer.cityName'] && (
                                        <FormFeedback>
                                            {errors['customer.cityName']}
                                        </FormFeedback>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="customer.postalZone">Postal Zone</Label>
                                    <Input
                                        type="text"
                                        name="customer.postalZone"
                                        id="customer.postalZone"
                                        value={formData.customer.postalZone}
                                        onChange={handleChange}
                                        invalid={!!errors['customer.postalZone']}
                                    />
                                    {errors['customer.postalZone'] && (
                                        <FormFeedback>
                                            {errors['customer.postalZone']}
                                        </FormFeedback>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </>
                )}

                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="customer.identificationCode">
                                Identification Code
                            </Label>
                            <Input
                                type="text"
                                name="customer.identificationCode"
                                id="customer.identificationCode"
                                value={formData.customer.identificationCode}
                                onChange={handleChange}
                                invalid={!!errors['customer.identificationCode']}
                            />
                            {errors['customer.identificationCode'] && (
                                <FormFeedback>
                                    {errors['customer.identificationCode']}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                    {formData.invoiceType === '1' && (
                        <Col md={6}>
                            <FormGroup>
                                <Label for="customer.taxNo">Tax No</Label>
                                <Input
                                    type="text"
                                    name="customer.taxNo"
                                    id="customer.taxNo"
                                    value={formData.customer.taxNo}
                                    onChange={handleChange}
                                    invalid={!!errors['customer.taxNo']}
                                />
                                {errors['customer.taxNo'] && (
                                    <FormFeedback>
                                        {errors['customer.taxNo']}
                                    </FormFeedback>
                                )}
                            </FormGroup>
                        </Col>
                    )}
                    {(formData.invoiceType === '1' ||
                        formData.customer.socialID) && (
                        <Col md={6}>
                            <FormGroup>
                                <Label for="customer.registrationName">
                                    Registration Name
                                </Label>
                                <Input
                                    type="text"
                                    name="customer.registrationName"
                                    id="customer.registrationName"
                                    value={formData.customer.registrationName}
                                    onChange={handleChange}
                                    invalid={!!errors['customer.registrationName']}
                                />
                                {errors['customer.registrationName'] && (
                                    <FormFeedback>
                                        {errors['customer.registrationName']}
                                    </FormFeedback>
                                )}
                            </FormGroup>
                        </Col>
                    )}
                </Row>

                {/* Invoice Lines */}
                <h4>خطوط الفاتورة</h4>
                {formData.invoiceLines.map((line, index) => (
                    <div key={index} className="mb-4 p-3 border">
                        <h5>سطر {index + 1}</h5>
                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for={`unitPrice-${index}`}>Unit Price</Label>
                                    <Input
                                        type="number"
                                        step="0.01"
                                        name={`unitPrice-${index}`}
                                        id={`unitPrice-${index}`}
                                        value={line.unitPrice}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setFormData((prev) => {
                                                const lines = [...prev.invoiceLines];
                                                lines[index].unitPrice = value;
                                                return { ...prev, invoiceLines: lines };
                                            });
                                        }}
                                        invalid={
                                            !!errors[`invoiceLines.${index}.unitPrice`]
                                        }
                                    />
                                    {errors[`invoiceLines.${index}.unitPrice`] && (
                                        <FormFeedback>
                                            {errors[`invoiceLines.${index}.unitPrice`]}
                                        </FormFeedback>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for={`itemName-${index}`}>Item Name</Label>
                                    <Input
                                        type="text"
                                        name={`itemName-${index}`}
                                        id={`itemName-${index}`}
                                        value={line.itemName}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setFormData((prev) => {
                                                const lines = [...prev.invoiceLines];
                                                lines[index].itemName = value;
                                                return { ...prev, invoiceLines: lines };
                                            });
                                        }}
                                        invalid={
                                            !!errors[`invoiceLines.${index}.itemName`]
                                        }
                                    />
                                    {errors[`invoiceLines.${index}.itemName`] && (
                                        <FormFeedback>
                                            {errors[`invoiceLines.${index}.itemName`]}
                                        </FormFeedback>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for={`taxPercent-${index}`}>
                                        Tax Percent
                                    </Label>
                                    <Input
                                        type="select"
                                        name={`taxPercent-${index}`}
                                        id={`taxPercent-${index}`}
                                        value={line.taxPercent}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setFormData((prev) => {
                                                const lines = [...prev.invoiceLines];
                                                lines[index].taxPercent = value;
                                                return { ...prev, invoiceLines: lines };
                                            });
                                        }}
                                        invalid={
                                            !!errors[`invoiceLines.${index}.taxPercent`]
                                        }
                                    >
                                        <option value="15">15%</option>
                                        <option value="0">0%</option>
                                    </Input>
                                    {errors[`invoiceLines.${index}.taxPercent`] && (
                                        <FormFeedback>
                                            {errors[`invoiceLines.${index}.taxPercent`]}
                                        </FormFeedback>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for={`taxAmount-${index}`}>
                                        Tax Amount
                                    </Label>
                                    <Input
                                        type="number"
                                        step="0.01"
                                        name={`taxAmount-${index}`}
                                        id={`taxAmount-${index}`}
                                        value={line.taxAmount}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setFormData((prev) => {
                                                const lines = [...prev.invoiceLines];
                                                lines[index].taxAmount = value;
                                                return { ...prev, invoiceLines: lines };
                                            });
                                        }}
                                        invalid={
                                            !!errors[`invoiceLines.${index}.taxAmount`]
                                        }
                                    />
                                    {errors[`invoiceLines.${index}.taxAmount`] && (
                                        <FormFeedback>
                                            {errors[`invoiceLines.${index}.taxAmount`]}
                                        </FormFeedback>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for={`discount-${index}`}>Discount</Label>
                                    <Input
                                        type="number"
                                        step="0.01"
                                        name={`discount-${index}`}
                                        id={`discount-${index}`}
                                        value={line.discount}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setFormData((prev) => {
                                                const lines = [...prev.invoiceLines];
                                                lines[index].discount = value;
                                                return { ...prev, invoiceLines: lines };
                                            });
                                        }}
                                        invalid={
                                            !!errors[`invoiceLines.${index}.discount`]
                                        }
                                    />
                                    {errors[`invoiceLines.${index}.discount`] && (
                                        <FormFeedback>
                                            {errors[`invoiceLines.${index}.discount`]}
                                        </FormFeedback>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                {formData.customer.socialID && (
                                    <>
                                        <FormGroup>
                                            <Label for={`taxExemptionReason-${index}`}>
                                                Tax Exemption Reason
                                            </Label>
                                            <Input
                                                type="text"
                                                name={`taxExemptionReason-${index}`}
                                                id={`taxExemptionReason-${index}`}
                                                value={line.taxExemptionReason}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setFormData((prev) => {
                                                        const lines = [...prev.invoiceLines];
                                                        lines[index].taxExemptionReason = value;
                                                        return { ...prev, invoiceLines: lines };
                                                    });
                                                }}
                                                invalid={
                                                    !!errors[
                                                        `invoiceLines.${index}.taxExemptionReason`
                                                        ]
                                                }
                                            />
                                            {errors[
                                                `invoiceLines.${index}.taxExemptionReason`
                                                ] && (
                                                <FormFeedback>
                                                    {
                                                        errors[
                                                            `invoiceLines.${index}.taxExemptionReason`
                                                            ]
                                                    }
                                                </FormFeedback>
                                            )}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for={`taxExemptionReasonCode-${index}`}>
                                                Tax Exemption Reason Code
                                            </Label>
                                            <Input
                                                type="select"
                                                name={`taxExemptionReasonCode-${index}`}
                                                id={`taxExemptionReasonCode-${index}`}
                                                value={line.taxExemptionReasonCode}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setFormData((prev) => {
                                                        const lines = [...prev.invoiceLines];
                                                        lines[index].taxExemptionReasonCode = value;
                                                        return { ...prev, invoiceLines: lines };
                                                    });
                                                }}
                                                invalid={
                                                    !!errors[
                                                        `invoiceLines.${index}.taxExemptionReasonCode`
                                                        ]
                                                }
                                            >
                                                <option value="">اختر</option>
                                                <option value="VATEX-SA-32">
                                                    VATEX-SA-32 - صادرات السلع من المملكة
                                                </option>
                                                <option value="VATEX-SA-33">
                                                    VATEX-SA-33 - صادرات الخدمات من المملكة
                                                </option>
                                                <option value="VATEX-SA-34-1">
                                                    VATEX-SA-34-1 - النقل الدولي للسلع
                                                </option>
                                                <option value="VATEX-SA-34-2">
                                                    VATEX-SA-34-2 - النقل الدولي للركاب
                                                </option>
                                                <option value="VATEX-SA-34-3">
                                                    VATEX-SA-34-3 - الخدمات المرتبطة بتوريد النقل الدولي للركاب
                                                </option>
                                                <option value="VATEX-SA-34-4">
                                                    VATEX-SA-34-4 - توريد وسائل النقل المؤهلة
                                                </option>
                                                <option value="VATEX-SA-34-5">
                                                    VATEX-SA-34-5 - الخدمات ذات الصلة بنقل السلع أو الركاب
                                                </option>
                                                <option value="VATEX-SA-35">
                                                    VATEX-SA-35 - الأدوية والمعدات المؤهلة
                                                </option>
                                                <option value="VATEX-SA-36">
                                                    VATEX-SA-36 - المعادن المؤهلة
                                                </option>
                                                <option value="VATEX-SA-EDU">
                                                    VATEX-SA-EDU - الخدمات التعليمية الخاصة للمواطنين
                                                </option>
                                                <option value="VATEX-SA-HEA">
                                                    VATEX-SA-HEA - الخدمات الصحية الخاصة للمواطنين
                                                </option>
                                                <option value="VATEX-SA-MLTRY">
                                                    VATEX-SA-MLTRY - توريد السلع العسكرية المؤهلة
                                                </option>
                                                <option value="VATEX-SA-DUTYFREE">
                                                    VATEX-SA-DUTYFREE - التوريد المؤهل للسلع في منطقة معفاة من الرسوم الجمركية
                                                </option>
                                            </Input>
                                            {errors[
                                                `invoiceLines.${index}.taxExemptionReasonCode`
                                                ] && (
                                                <FormFeedback>
                                                    {
                                                        errors[
                                                            `invoiceLines.${index}.taxExemptionReasonCode`
                                                            ]
                                                    }
                                                </FormFeedback>
                                            )}
                                        </FormGroup>
                                    </>
                                )}
                            </Col>
                        </Row>

                        <Button
                            color="danger"
                            onClick={() => removeInvoiceLine(index)}
                            disabled={formData.invoiceLines.length === 1}
                        >
                            حذف السطر
                        </Button>
                    </div>
                ))}
                <Button color="primary" onClick={addInvoiceLine}>
                    إضافة سطر جديد
                </Button>

                {/* زر الإرسال */}
                <Button color="success" type="submit" className="mt-4">
                    إرسال الفاتورة
                </Button>
            </Form>
        </Container>
    );
};

export default InvoiceForm;