// redux/apis/endpoints/uploadGalleryImages.js

export const uploadGalleryImagesEndpoint = (builder) => ({
    uploadGalleryImages: builder.mutation({
        query: (formData) => ({
            url: '/api/gallery/add-photos', // تأكد من توافق المسار مع السيرفر الخاص بك
            method: 'POST',
            body: formData,
        }),
    }),
});