
// store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import themeReducer from './theme/themeSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import addressReducer from './address/addressSlice';

// استيراد apiSlice
import { apiSlice } from './apis/apiSlice';

// الجمع بين المخفضات، بما في ذلك apiSlice.reducer
const rootReducer = combineReducers({
  user: userReducer,
  address: addressReducer,
  theme: themeReducer,
  [apiSlice.reducerPath]: apiSlice.reducer, // إضافة apiSlice.reducer
});

// تكوين redux-persist
const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  blacklist: [apiSlice.reducerPath], // استثناء apiSlice.reducer من التخزين المؤقت
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(apiSlice.middleware), // إضافة apiSlice.middleware
});

export const persistor = persistStore(store);



