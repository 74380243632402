// redux/apis/endpoints/getIncomingInvoices.js
export const getIncomingInvoicesEndpoint = (builder) => ({
  getIncomingInvoices: builder.query({
    query: ({ page = 1, limit = 10 }) =>
      `api/incomingInvoices/get-incomingInvoice?page=${page}&limit=${limit}&sortBy=invoiceAmount&order=desc`,
    transformResponse: (response) => response, // التأكد من معالجة الاستجابة كاملة
    providesTags: (result) =>
      result && result.data && Array.isArray(result.data)
        ? [
            ...result.data.map(({ _id }) => ({
              type: "IncomingInvoices",
              id: _id,
            })),
            { type: "IncomingInvoices", id: "LIST" },
          ]
        : [{ type: "IncomingInvoices", id: "LIST" }],
  }),
});
