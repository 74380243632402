// endpoints/getIncomingInvoicesByYearEndpoint.js

export const getIncomingInvoicesByYearEndpoint = (builder) => ({
    getIncomingInvoicesByYear: builder.query({
        query: ({ year, page = 1, limit = 10, dateFrom, dateTo, sortBy, order }) => {
            const params = new URLSearchParams({
                year: year.toString(),
                page: page.toString(),
                limit: limit.toString(),
            });

            if (dateFrom) params.append("dateFrom", dateFrom);
            if (dateTo) params.append("dateTo", dateTo);
            if (sortBy) params.append("sortBy", sortBy);
            if (order) params.append("order", order);

            return {
                url: `api/incomingInvoice/year?${params.toString()}`,
            };
        },
        providesTags: (result, error, arg) => [
            { type: "IncomingInvoiceYear", id: arg.year },
        ],
    }),
});