import PrintComponent from './Print';
import React, { Fragment } from 'react';

const InvoiceContain = () => {
    return (
      <Fragment>
        <PrintComponent />
      </Fragment >
    );
};
export default InvoiceContain;